import { useState } from "react";
import { errorHandler } from "../../../helpers/errorHandler";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { useRouteMatch } from "react-router-dom";
import { Loader } from "./../../../UI/Loaders/Loaders";
import useAggregatorOfferLetterAndSLA from "./../../../custom-hooks/useAggregatorOfferLetterAndSLA";
import useAggOferLetterAndSlaHistory from "./../../../custom-hooks/useAggOferLetterAndSlaHistory";
import GetStartedViewHistory from "./GetStartedViewHistory";
import GetStartedResendOfferLetterCards from "./GetStartedResendOfferLetterCards";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { useQueryCache } from "react-query";
import {
  postDataWithDotNet,
  putDataWithDotNet,
} from "../../../newApis/dotNetApiMethods";
import Alert from "../../../NewComponents/Alert/Alert.component";
import useAggregatorEnvironment from "../../../custom-hooks/useAggregatorEnvironment";
import { appInsights } from "../../../config/appInsights";

import styles from "../AggregatorOnboardingReview.module.scss";

export default function AggregatorOnboardingReviewSLAUpload() {
  const [updating, setUpdating] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
  const [reuploadDocument, setReuploadDocument] = useState(false);
  const match = useRouteMatch();
  const {
    data,
    status,
    error: dataError,
    refetch: refetchOfferSla,
  } = useAggregatorOfferLetterAndSLA(match.params.id);

  const {
    data: dataHistory,
    error: historyError,
    status: historyStatus,
  } = useAggOferLetterAndSlaHistory({
    id: match.params.id,
    viewHistory,
  });
  const { data: onboardingData, refetch: refetchEnvironmentData } =
    useAggregatorEnvironment(Number(match.params.id));
  const queryCache = useQueryCache();
  const removeFile = (id) => {
    const newFile = {
      filename_with_extension: "",
      base64_file_string: "",
      document_field_id: "",
      id: "",
    };
    let newFiles = [...filesToBeUploaded];
    newFiles[id] = newFile;
    setFilesToBeUploaded(newFiles);
  };

  const fileToSend = filesToBeUploaded.filter(
    (allFile) =>
      allFile.document_field_id &&
      allFile.filename_with_extension &&
      allFile.base64_file_string &&
      allFile.id
  );

  const onSubmit = async ({ message }) => {
    setUpdating(true);
    setError(null);
    setSuccess(null);
    const reqBody = {
      comment: message,
      aggregator_documents: fileToSend,
    };
    try {
      const res = await putDataWithDotNet(
        `${ajaxEndpoints.AGGREGATOR_OFFERLETER_SLA}${Number(match.params.id)}`,
        reqBody,
        true
      );
      queryCache.invalidateQueries("getAggregatorOfferLetterAndSla");

      // I intentionally added a setTimeout here so the offer letter can be refetched before users see success response
      setTimeout(() => {
        setUpdating(false);
        setFilesToBeUploaded([]);
        setReuploadDocument(false);
        setSuccess(res.message);
      }, 1500);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingReviewSLAUpload.jsx",
        },
      });
      setUpdating(false);
      setError(errorHandler(error));
    }
  };
  const handleCompleteOffer = async (e) => {
    e.preventDefault();
    setUpdating(true);
    setError(null);
    setSuccess(null);
    try {
      const res = await postDataWithDotNet(
        `${ajaxEndpoints.ACCEPT_AGGREGATOR_OFFERLETER_SLA}${match.params.id}`
      );
      queryCache.invalidateQueries("getAggregatorOfferLetterAndSla");
      queryCache.invalidateQueries("getAggregatorEnvironment");
      refetchEnvironmentData();
      setSuccess(res.message);
      setUpdating(false);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingReviewSLAUpload.jsx",
        },
      });
      setUpdating(false);
      setError(errorHandler(error));
    }
  };

  const onFileChange = async (
    e,
    id,
    document_field_id,
    offer_letter_sla_document_id
  ) => {
    if (!e.target.files[0]) return;
    setError(null);

    const fileName = e.target.files[0].name;

    const isValidFile =
      fileName.endsWith(".pdf") ||
      fileName.endsWith(".doc") ||
      fileName.endsWith(".docx");

    if (!isValidFile) {
      return setError("Invalid File type.");
    }
    const fileBase64 = await convertToBase64(e.target.files[0]);
    // Remove file
    document.getElementById(`file-selector${id}`).value = "";
    const newFile = {
      filename_with_extension: fileName,
      base64_file_string: fileBase64,
      document_field_id: document_field_id,
      id: offer_letter_sla_document_id,
    };
    let newFiles = [...filesToBeUploaded];
    newFiles[id] = newFile;
    setFilesToBeUploaded(newFiles);
  };

  if (status === "loading" || historyStatus === "loading") {
    return <Loader centered={true} text="Loading uploaded documents..." />;
  }

  if (dataError || historyError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> {errorHandler(dataError)}
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className="onboardingOuterLayout">
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className={`onboardingInnerLayout ${styles.innerLayout}`}>
        <div className="w-100">
          <div className="row">
            <div className="col-10"></div>
            <div className="col-2 mb-2 pr-0 mr-0">
              {viewHistory && dataHistory ? (
                <div onClick={(e) => setViewHistory(false)}>
                  <h3 className={`viewText mb-0 pl-3 ml-1`}>Hide History</h3>
                </div>
              ) : (
                <div onClick={(e) => setViewHistory(true)}>
                  <h3 className={`viewText mb-0 pl-3 ml-1`}>View History</h3>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            {viewHistory && dataHistory && dataHistory?.length ? (
              dataHistory?.map((data, _id) => {
                return (
                  <>
                    <GetStartedViewHistory data={data} />
                  </>
                );
              })
            ) : !viewHistory && data && data.length ? (
              <GetStartedResendOfferLetterCards
                data={data}
                refetchOfferSla={refetchOfferSla}
                filesToBeUploaded={filesToBeUploaded}
                removeFile={removeFile}
                onFileChange={onFileChange}
                viewHistory={viewHistory}
                onSubmit={onSubmit}
                updating={updating}
                dataHistory={false}
                handleCompleteOffer={handleCompleteOffer}
                onboardingData={onboardingData}
                reuploadDocument={reuploadDocument}
                setReuploadDocument={setReuploadDocument}
              />
            ) : (
              <div className="grid__padding animated fadeInRight">
                <h4 className="center-txt py-5">
                  <i className="fas fa-bell" /> No available document
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
