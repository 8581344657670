import { CSSObjectWithLabel, OptionsOrGroups, PropsValue } from "react-select";
import AsyncSelect from "react-select/async";
import Select from "react-select/dist/declarations/src/Select";

import styles from "../CustomSelect.module.scss";
import "./customSearchDropdown.css";
interface ICustomSearchDropdown {
  label?: string;
  onChange: any;
  iconType?: string;
  loadOptions?: (arg: string) => Promise<any>;
  register?:
    | React.Ref<
        Select<
          | string
          | {
              label: string;
              value: string;
            },
          boolean,
          any
        >
      >
    | undefined;
  name?: string;
  isShowLabel?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  defaultOptions?: boolean | OptionsOrGroups<string, any> | undefined;
  labelClass?: string;
  defaultValue?:
    | PropsValue<
        | string
        | {
            label: string;
            value: string;
          }
      >
    | undefined;
  disabled?: boolean;
  placeholder?: string;
  inputHeight?: number;
  labelStyle?: object;
  inputWidth?: number;
}
const CustomSearchDropDown = ({
  label,
  onChange,
  iconType,
  loadOptions,
  register,
  name,
  isShowLabel,
  isMulti,
  isSearchable,
  defaultOptions,
  labelClass,
  defaultValue,
  disabled,
  placeholder,
  inputHeight,
  labelStyle,
  inputWidth,
}: ICustomSearchDropdown) => {
  const customStyles: any = {
    control: (base: CSSObjectWithLabel, state: any) => ({
      ...base,
      minHeight: inputHeight,
      minWidth: inputWidth,
      borderColor: state?.isFocused ? "#713fff" : "hsl(0, 0%, 80%)",
      borderRadius: "10px",
    }),
  };

  //
  /*@ts-ignore*/
  return (
    <div className={`form-group ${styles.customSelect}`}>
      {isShowLabel && (
        <label className={labelClass} style={{ ...labelStyle }}>
          {iconType ? <i className={iconType} /> : ""}
          {label}
        </label>
      )}
      <AsyncSelect
        placeholder={placeholder}
        defaultValue={defaultValue}
        isClearable
        isSearchable={isSearchable}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        onChange={onChange}
        ref={register}
        name={name}
        isMulti={isMulti}
        isDisabled={disabled}
        styles={customStyles}
      />
    </div>
  );
};

export default CustomSearchDropDown;
