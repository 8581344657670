// Sprint1: abd87011-e756-4c7a-9d27-2352e2d0ae8b
// Sprint2: b143ef2c-64fd-4d36-a1b9-f79a2cc49d9b
// Sprint3: 3d018b1d-95a5-4080-a0f5-df6609693b37
// Master: bd22fdf1-d26b-49d5-ba1a-8123ae60e07d
// Staging: 913ab8a5-79bb-4e58-883a-a35870f70c37
export const getAppInsightKey = () => {
  // @ts-ignore
  switch (window.env.baseUrl2) {
    case "https://advancly-api-sprint.test.vggdev.com/api/v1":
      return "abd87011-e756-4c7a-9d27-2352e2d0ae8b";
    case "https://advanclyapispnt2test.azurewebsites.net/api/v1":
      return "b143ef2c-64fd-4d36-a1b9-f79a2cc49d9b";
    case "https://advancly-api-sprint3.test.vggdev.com/api/v1":
      return "3d018b1d-95a5-4080-a0f5-df6609693b37";
    case "https://advancly-api-master.test.vggdev.com/api/v1":
      return "bd22fdf1-d26b-49d5-ba1a-8123ae60e07d";
    case "https://advancly-api-master.staging.vggdev.com/api/v1":
      return "913ab8a5-79bb-4e58-883a-a35870f70c37";
    case "https://api.advancly.com/api/v1":
      return "41ed885b-529e-42a9-bb6f-20ce215182c0";
    default:
      return "bd22fdf1-d26b-49d5-ba1a-8123ae60e07d";
  }
};
export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://advancly-api-master.test.vggdev.com/api/v1"
    : // "https://advancly-api-sprint2.test.vggdev.com/api/v1"
      // "https://advancly-api-sprint4.test.vggdev.com/api/v1"
      // "https://advancly-api-master.staging.vggdev.com/api/v1"

      window.env.baseUrl2;
export const BASE_URL2 =
  process.env.NODE_ENV === "development"
    ? // "https://advancly-api-master.staging.vggdev.com/api/v1"
      "https://advancly-api-sprint4.test.vggdev.com/api/v1"
    : window.env.baseUrl2;
export const base_URL_SSO =
  process.env.NODE_ENV === "development"
    ? "https://sso.test.vggdev.com/identity"
    : window.env.ssobaseUrl;
export const REGULATORY_REPORT_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://advanclyregulatoryreporttest.azurewebsites.net/api/v1"
    : window.env.regulatoryReportBaseUrl;

export const BANK_STATEMENT_EXTRACTION_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://bankstatementextractor-test.azurewebsites.net"
    : // window.env.bankStatementExtractionBaseUrl
      "https://bankstatementextractor-test.azurewebsites.net";

export const POWER_REPORT_EMBED_URL =
  process.env.NODE_ENV === "development"
    ? "https://app.powerbi.com/reportEmbed?reportId=6e8953a3-d7e3-4a5f-994c-8b629135321a&groupId=968461ce-6158-4f31-a524-b4fac19706c5&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlfX0%3d"
    : window.env.powerBiUrl;
export const POWER_REPORT_REPORTID =
  process.env.NODE_ENV === "development"
    ? "6e8953a3-d7e3-4a5f-994c-8b629135321a"
    : window.env.powerBiReportId;
export const GENERAL_POWER_REPORT_EMBED_URL =
  process.env.NODE_ENV === "development"
    ? "https://app.powerbi.com/reportEmbed?reportId=0f5fb337-9c7f-49eb-8f16-5f328f53e7ff&groupId=968461ce-6158-4f31-a524-b4fac19706c5&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUItUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlfX0%3d"
    : window.env.generalPowerBiUrl;
export const GENERAL_POWER_REPORT_REPORTID =
  process.env.NODE_ENV === "development"
    ? "0f5fb337-9c7f-49eb-8f16-5f328f53e7ff"
    : window.env.generalPowerBiReportId;
export const POWERBI_STANDARD_LOAN_REPORT_TOKEN =
  process.env.NODE_ENV === "development"
    ? "eyJrIjoiZmNhMTFiN2EtYWNlZS00ZDFlLTg4NGItNzUzMTJlMDBiOWVjIiwidCI6IjFmYjYzOWYzLTQ0YWItNGEwNC04MDNiLWNmZDJiYjNiOTY2ZCIsImMiOjh9"
    : window.env.standardLoanReportToken;
export const POWERBI_DASHBOARD_REPORT_TOKEN =
  process.env.NODE_ENV === "development"
    ? "eyJrIjoiMzNiZjM2NjgtZTllOS00MDkzLTlmOTMtMGY1OWRjNGQyNjk2IiwidCI6IjFmYjYzOWYzLTQ0YWItNGEwNC04MDNiLWNmZDJiYjNiOTY2ZCIsImMiOjh9"
    : window.env.dashboardReportToken;
export const ENCRYPTION_KEY =
  process.env.NODE_ENV === "development"
    ? "this_is_the_most_secure_P@55w0rd01_in_the_W0RLD"
    : window.env.encryptionKey;

export const WORK_FLOW_CODE =
  process.env.NODE_ENV === "development"
    ? "adv_dis_staging"
    : window.env.workflowCode;
