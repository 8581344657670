import React, { Component } from "react";
import "../../Issues/IssuesModal.css";

// UI Components
import Tabs from "../../../../components/Tabs/Tabs";
import ajaxMethods from "../../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

//Table Helper Controllers
// import Dropzone from 'react-dropzone';
import { appInsights } from "../../../../config/appInsights";
import { LoanDetails } from "./components/LoanDetails";
import { LoanSchedule } from "./components/LoanSchedule";
import LoanStatement from "./components/LoanStatement";

export default class ViewMifosLoans extends Component {
  state = {
    scene: "form",
    formScene: "loan_details",
    tabs: [
      {
        name: "Loan Details",
        id: "loan_details",
        icon: "far fa-building",
        identifier: "mifoInfoTab",
      },
      {
        name: "Repayment Schedule",
        id: "loan_schedule",
        icon: "fas fa-chart-bar",
        identifier: "mifoInfoTab",
      },
      {
        name: "Loan Statement",
        id: "loan_statement",
        icon: "fas fa-file-invoice-dollar",
        identifier: "mifoInfoTab",
      },
    ],
    mifosDetails: [],
    mifosAmountDetails: [],
    mifosRepaymentSchedule: [],
    loanDates: [],
    error: false,
    loader: false,
  };

  componentDidMount = () => {
    this.getMifosDetails();
  };

  getMifosDetails = () => {
    this.setState({ loader: true });
    ajaxMethods
      .get(
        ajaxEndpoints.GET_MIFOS_LOAN_DETAILS +
          "?externalId=" +
          this.props.dataRecord.loan_ref
      )
      // ajaxMethods
      //   .get(ajaxEndpoints.GET_MIFOS_LOAN_DETAILS + "?externalId=ref_0009")
      .then((response) => {
        this.setState({ loader: false });
        let res = response.data.responseData;

        if (res) {
          this.setState({
            mifosDetails: res,
            mifosAmountDetails: res.summary,
            loanDates: res.timeline,
            mifosRepaymentSchedule: res.repaymentSchedule.periods,
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ViewMifosLoans.js",
          },
        });
        this.setState({ error: true });
      });
  };

  printHandler = () => {};

  cancelHandler = () => {};

  // Toggle Tab & Tab content for Data Table
  toggleTabContent = (content) => {
    this.setState({
      formScene: content,
    });
  };

  render() {
    const {
      scene,
      tabs,
      formScene,
      mifosDetails,
      mifosAmountDetails,
      loanDates,
      loader,
      mifosRepaymentSchedule,
      error,
    } = this.state;

    const dataRecord = this.props.dataRecord;

    const { borrower_id } = dataRecord;

    return (
      <div
        className="Modal__dialog lg-modal animated slideInDown"
        role="document"
      >
        <div
          className="IssueModal Modal__content"
          style={{ overflow: "hidden" }}
        >
          <div className="Modal__body">
            <h3 className="Modal__title p-b-2">
              Borrower id: <span>{borrower_id}</span>
            </h3>
            {(() => {
              switch (scene) {
                case "form":
                  return (
                    <form>
                      <div className="row m-b-1">
                        <Tabs
                          onContentToggle={this.toggleTabContent}
                          tabs={tabs}
                        />
                      </div>
                      {(() => {
                        switch (formScene) {
                          case "loan_details":
                            return (
                              <LoanDetails
                                details={mifosDetails}
                                mifosAmountDetails={mifosAmountDetails}
                                loanDates={loanDates}
                                loader={loader}
                              />
                            );

                          case "loan_schedule":
                            return (
                              <LoanSchedule
                                mifosRepayment={mifosRepaymentSchedule}
                                error={error}
                                loader={loader}
                              />
                            );
                          case "loan_statement":
                            return (
                              <LoanStatement loanRef={dataRecord?.loan_ref} />
                            );
                          default:
                            return null;
                        }
                      })()}
                    </form>
                  );

                case "loader":
                  return (
                    <div>
                      {/* 2. login Form Loader */}
                      <div
                        id="adv-login-loader"
                        className="loading-overlay speed-1x fadeInRight"
                      >
                        <div className="form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading" id="adv-loader-alt" />
                            <div className="center-txt webapp-ripple-text">
                              <i className="notifier fas fa-user-circle" />
                              &nbsp; Processing your Details
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                case "success":
                  return (
                    <div>
                      {/* 3. Login Response */}
                      <div
                        id="adv-login-response"
                        className="loading-overlay speed-1x fadeInRight"
                      >
                        <div className="form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading success-loader">
                              <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                            </div>
                            <div className="center-txt webapp-ripple-text">
                              Logged In Successfully
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                case "fail":
                  return (
                    <div>
                      {/* 3. Login Response */}
                      <div
                        id="adv-login-response"
                        className="loading-overlay speed-1x fadeInRight"
                      >
                        <div className="form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading success-loader">
                              <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                            </div>
                            <div className="center-txt webapp-ripple-text">
                              Login Failed, try again later
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                default:
                  return null;
              }
            })()}

            <div className="Form__action-buttons">
              {/* <button type="button"
                                className="btn zoom-element btn login-btn m-0 mr-auto"
                                onClick={this.proceedHandler}>Print</button> */}
              <button
                type="button"
                className="btn zoom-element btn login-btn"
                onClick={this.props.onCloseModal}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
