import { Fragment, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import CustomTextArea from "../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import Alert from "../../../../NewComponents/Alert/Alert.component";
import { putData } from "../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { documentApprovalStatus } from "../../util";

const schema = yup.object().shape({
  comment: yup.string().trim().required("Please enter your comment"),
});

export default function OfferLetterSLAActionModal({ data, refetchData }) {
  const [isTakingDocumentAction, setIsTakingDocumentAction] = useState(false);

  const match = useRouteMatch();
  const { id } = match.params;
  const dismissModalRef = useRef(null);

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState(null);

  const [success, setSuccess] = useState(null);

  const handleApprove = async (comment) => {
    setIsTakingDocumentAction(true);
    try {
      const payload = {
        offerLetterSlaApprovalStatusDtos: [
          {
            offerletter_sla_id: data?.data?.id,
            status: documentApprovalStatus.APPROVED,
            comment: comment,
          },
        ],
      };
      const response = await putData(
        `${ajaxEndpoints.OFFER_LETTER_SLA_STATUS}/${id}`,
        payload
      );

      if (response?.data?.status_code === 200) {
        setSuccess(response?.data?.message);
        refetchData();
        dismissModalRef.current.click();
        handleCloseModal();
      } else {
        setError(response?.data?.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
    }
    setIsTakingDocumentAction(false);
  };

  const handleDecline = async (comment) => {
    setIsTakingDocumentAction(true);
    try {
      const payload = {
        offerLetterSlaApprovalStatusDtos: [
          {
            offerletter_sla_id: data?.data?.id,
            status: documentApprovalStatus.DECLINED,
            comment: comment,
          },
        ],
      };
      const response = await putData(
        `${ajaxEndpoints.OFFER_LETTER_SLA_STATUS}/${id}`,
        payload
      );
      if (response?.data?.status_code === 200) {
        setSuccess(response?.data?.message);
        refetchData();
        dismissModalRef.current.click();
        handleCloseModal();
      } else {
        setError(response?.data?.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
    }
    setIsTakingDocumentAction(false);
  };

  const onSubmit = (formData, actionType) => {
    if (actionType === "approve") {
      handleApprove(formData?.comment);
    } else if (actionType === "decline") {
      handleDecline(formData?.comment);
    }
  };

  const handleCloseModal = () => {
    const modalClassList = document.getElementById(
      "offerslaDocumentActionModal"
    ).classList;

    if (!modalClassList.contains("show")) {
      setError(null);
      setSuccess(null);
      reset();
    }
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div
        className="modal fade"
        id="offerslaDocumentActionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="offerslaDocumentActionModal"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog ${
            data?.actionType === "cancel" && "modal-sm"
          } modal-dialog-centered`}
          role="document"
        >
          <div className="modal-content">
            {(() => {
              switch (data?.actionType) {
                case "approve":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="page-subtitle">Approve Document</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={handleCloseModal}
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Fragment>
                          <p className="text-justify">
                            Please add a comment to complete this process. A
                            mail will be sent to inform this Aggregator once you
                            have approved this document
                          </p>
                          <form
                            onSubmit={handleSubmit((data) =>
                              onSubmit(data, "approve")
                            )}
                          >
                            <div className="row">
                              <div className="col-12">
                                <CustomTextArea
                                  maxLength="256"
                                  reference={register}
                                  placeholder="Enter comment here..."
                                  label="Admin Comment"
                                  name="comment"
                                  showRequiredIcon={true}
                                  errors={errors?.comment}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 d-flex justify-content-end flex-wrap">
                                <button
                                  className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                  type="button"
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                  type="submit"
                                >
                                  {isTakingDocumentAction
                                    ? "Approving..."
                                    : "Confirm Approval"}
                                </button>
                              </div>
                            </div>
                          </form>
                        </Fragment>
                      </div>
                    </Fragment>
                  );
                case "decline":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="page-subtitle">Decline Document</h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={handleCloseModal}
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="text-justify">
                          Please add a comment to complete this process. A mail
                          will be sent to inform this Aggregator once you have
                          declined this document
                        </p>
                        <form
                          onSubmit={handleSubmit((data) =>
                            onSubmit(data, "decline")
                          )}
                        >
                          <div className="row">
                            <div className="col-12">
                              <CustomTextArea
                                maxLength="256"
                                reference={register}
                                placeholder="Enter comment here..."
                                label="Admin Comment"
                                name="comment"
                                showRequiredIcon={true}
                                errors={errors?.comment}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                              >
                                {isTakingDocumentAction
                                  ? "Declining..."
                                  : "Confirm Decline"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
