import React from "react";
import EligibilityTestField from "./EligibilityTestField";

export default function EligibilityTestStepTwo({
  setCurrentStep,
  currentStep,
  eligibilityInfo,
}) {
  const onSubmit = async () => {
    setCurrentStep(currentStep - 1);
  };
  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="row">
          {eligibilityInfo && eligibilityInfo.length ? (
            eligibilityInfo
              .sort((a, b) => a.position - b.position)
              .map((obj, _id) => {
                const {
                  questionCategoryId,
                  isMandatory,
                  question,
                  questionAnswerType,
                  questionAnswers,
                  selectedAnswer,
                } = obj;
                return (
                  <div className="col-12" key={_id}>
                    {questionCategoryId === 2 ? (
                      <EligibilityTestField
                        isMandatory={isMandatory}
                        question={question}
                        questionAnswerType={questionAnswerType}
                        questionAnswers={questionAnswers}
                        selectedAnswer={selectedAnswer}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
          ) : (
            <div className="d-flex align-items-center justify-content-center py-3">
              <h3 className="font-weight-bold">
                Aggregator is yet to answer any question
              </h3>
            </div>
          )}
        </div>
        <div className="w-100 d-flex align-items-center justify-content-between flex-wrap mt-5">
          <div className="step">Section {currentStep} of 2</div>
          <div>
            <button className="btn advancly-white-btn btn-md " type="submit">
              Previous Page
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
