import { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

// Router
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import useManageProductsData from "../../../custom-hooks/useManageProductsData";
import { showModal } from "../../../redux/actions/modal";

// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";
import { errorHandler } from "../../../helpers/errorHandler";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";

import { useQueryCache } from "react-query";
import { formatMoney } from "../../../helpers/formatter";
import { appInsights } from "../../../config/appInsights";
import { RootState } from "../../../redux/store";

const ManageProducts = () => {
  const [loading, setLoading] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);

  const history = useHistory();
  const dispatch = useDispatch();

  const { loggedInUser, isAuthenticated } = useSelector(
    (state: RootState) => state?.authReducer
  );

  const queryCache = useQueryCache();
  //  Methods
  const modalHandler = (modalData: any) => {
    dispatch(
      showModal({
        modalIdentifier: "manageproduct",
        dataRecord: modalData,
        action: "customrange",
      })
    );
  };
  const dialogHandler = (modalData: any) => {
    dispatch(
      showModal({
        modalIdentifier: "manageproductprompt",
        dataRecord: modalData,
        action: "customrange",
      })
    );
  };

  const approveProduct = async (reference: string) => {
    setLoading("Approving Product...");
    setError("");
    const reqBody = {
      reference,
      comment: "Okay",
    };
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.APPROVE_PRODUCT,
        reqBody
      );
      setLoading("");
      setSuccess(response.message);
      queryCache.invalidateQueries([
        { email: loggedInUser?.email },
        "getProductsToManage",
      ]); // setSuccess("Product has been approved");
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageProducts.js",
        },
      });
      setLoading("");
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const declineProduct = async (reference: string) => {
    setLoading("Declining Product...");
    setError("");
    const reqBody = {
      reference,
      comment: "Okay",
    };
    try {
      await postDataWithDotNet(ajaxEndpoints.DECLINE_PRODUCT, reqBody);
      setLoading("");
      setSuccess("Product has been declined");
      queryCache.invalidateQueries([
        { email: loggedInUser?.email },
        "getProductsToManage",
      ]);
      setTimeout(() => {
        setSuccess("");
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ManageProducts.js",
        },
      });
      setLoading("");
      setError(errorHandler(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  //  Life Cycle Hooks
  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated, loggedInUser]);

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error: dataError } = useManageProductsData();

  if (data) {
    if (count === null) {
      setCount(data?.data?.length);
    }
  }

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Products
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading products..." />;
  } else if (loading) {
    RenderedComponent = <Loader centered={true} text={loading} />;
  } else {
    if (dataError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue fetching
              Products, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {error}
            </h4>
          </div>
        </div>
      );
    } else if (success) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> {success}
            </h4>
          </div>
        </div>
      );
    } else if (data?.data?.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no products to
              manage
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Product Name</th>
                <th>Product Category </th>
                <th>Currency </th>
                <th>Amount</th>
                <th>Aggregator Name</th>
                <th>Account Name</th>
                <th>Account Number</th>
                <th>Bank</th>
                <th>Interest Rate (%)</th>
                <th>Loan Tenue</th>
                <th>Repayment Type</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data?.data
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((product: any, _id: number) => {
                  const {
                    product_name,
                    productcategory,
                    maximum_amount,
                    currency,
                    aggregator,
                    bank_account_name,
                    account_number,
                    bank,
                    interest_rate,
                    maximum_tenor,
                    repayment_type,
                  } = product;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{product_name}</td>
                      <td>{productcategory}</td>
                      <td>{currency}</td>
                      <td>{formatMoney(maximum_amount, currency)}</td>
                      <td>{aggregator}</td>
                      <td>{bank_account_name}</td>
                      <td>{account_number}</td>
                      <td>{bank}</td>
                      <td>{interest_rate}</td>
                      <td>{maximum_tenor}</td>
                      <td>
                        {repayment_type
                          ? capitalizeFirstLetter(repayment_type)
                          : ""}
                      </td>
                      <td className="d-flex flex-row">
                        <span
                          id="0"
                          data-table="Notifications"
                          data-action="view"
                          className="Notifications view btn-manage zoom-element btn-slim"
                          onClick={() => {
                            modalHandler(product);
                          }}
                        >
                          View
                        </span>
                        <span
                          className="view btn-manage btn-success zoom-element btn-slim"
                          onClick={() =>
                            dialogHandler({
                              type: "approve",
                              product,
                              action: approveProduct,
                            })
                          }
                        >
                          Approve
                        </span>
                        <span
                          className="view btn-manage btn-danger zoom-element btn-slim"
                          onClick={() =>
                            dialogHandler({
                              type: "decline",
                              product,
                              action: declineProduct,
                            })
                          }
                        >
                          Decline
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={size}
            page={pageNumber}
            count={count}
            changeCurrentPage={(pageNumber: number) =>
              setPageNumber(pageNumber)
            }
          />
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Manage Products Header*/}
        <div className="block-header">
          <div className="row">
            <div className="col-lg-9 col-md-6 col-sm-7 dashboard-title flex__row">
              <h3 className="m-l-1">
                <aside className="rounder">1</aside>
                &nbsp; Manage Products
              </h3>
            </div>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
          <label htmlFor="table-fetch" className="form-group">
            Number of results:{" "}
            <select
              onChange={(e) => {
                setSize(Number(e.target.value));
              }}
              id="table-fetch"
              className="table-fetch-select m-l-1"
              value={size}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </label>
        </div>
        {RenderedComponent}
      </div>
    </div>
  );
};

export default ManageProducts;
