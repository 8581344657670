import { ReactComponent as SuccessIcon } from "../../../assets/svg/icons/success.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/icons/error.svg";

import Styles from "./feedBack.module.scss";

interface FeedBackProps {
  dataRecord: { type: "success" | "failure" | "warning"; message: string };
  onCloseModal: () => void;
}

const FeedBack = ({ dataRecord, onCloseModal }: FeedBackProps) => {
  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div
        className="Modal__content"
        style={{ overflow: "hidden", width: "30rem" }}
      >
        <div className="Modal__body">
          <div className={Styles.FeedBackBody}>
            {dataRecord?.type === "success" && <SuccessIcon />}
            {dataRecord?.type === "failure" && <ErrorIcon />}
            <p className={`text-center ${Styles.Title}`}>
              {dataRecord?.message}
            </p>
            <div className={`m-t-1 ${Styles.DoneButtonContainer}`}>
              <button
                type="submit"
                className={`btn advancly-btn btn-md m-0 ${Styles.DoneButton}`}
                onClick={onCloseModal}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedBack;
