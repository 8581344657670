import { Fragment, useState, useRef, useEffect } from "react";

import { useSelector } from "react-redux";

import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "./../../NewComponents/ToggleTab/ToggleTab.component";
import AggregatorOnboardingReviewBusinesInfo from "./AggregatorOnboardingReviewBusinesInfo/AggregatorOnboardingReviewBusinesInfo";
import AggregatorOnboardingReviewEligibilityTest from "./AggregatorOnboardingReviewEligibilityTest/AggregatorOnboardingReviewEligibilityTest";
import AggregatorOnboardingReviewDownloadDocument from "./AggregatorOnboardingReviewDownloadDocument/AggregatorOnboardingReviewDownloadDocument";
import AggregatorOnboardingReviewModal from "./AggregatorOnboardingReviewModal.component";
import useAnAggregatorOnboardingParameters from "./../../custom-hooks/useAnAggregatorOnboardingParameters";
import { Loader } from "./../../UI/Loaders/Loaders";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { postData } from "../../newApis/apiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { errorHandler } from "./../../helpers/errorHandler";
import Alert from "./../../NewComponents/Alert/Alert.component";
import AggregatorOnboardingReviewSLAUpload from "./AggregatorOnboardingReviewSLAUpload/AggregatorOnboardingReviewSLAUpload";
import { ReactComponent as ErrorIcon } from "../../assets/svg/icons/error-icon.svg";
import Toggler from "../../NewComponents/Toggler/Toggler.component";
import useAggregatorEnvironment from "../../custom-hooks/useAggregatorEnvironment";
import AggregatorOnboardingReviewAdminComment from "./AggregatorOnboardingReviewAdminComment/AggregatorOnboardingReviewAdminComment";
import { useFilter } from "../AggregatorOnboardingSettings/EligibilityQuestions/useFilter";
import { appInsights } from "../../config/appInsights";
import { onBoardingStatusCode } from "./util";
import { isProceedDisabled } from "./AggregatorOnboardingReviewDownloadDocument/helper";
import { ROLE_TYPES } from "../../helpers/roleTypes";

import styles from "./AggregatorOnboardingReview.module.scss";

export default function AggregatorOnboardingReview() {
  const [activeTab, setActiveTab] = useState("business-information");
  const [modalActionType, setModalActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [filterOptions, handleChange, filterParam] = useFilter();
  const [documentOwnerType, setDocumentOwnerType] = useState(1);

  const dismissModalRef = useRef();
  const match = useRouteMatch();
  const { id } = match.params;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const approved = searchParams.get("approved");
  const declined = searchParams.get("declined");
  const history = useHistory();

  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Review Onboarding Request  - (AggregatorOnboardingReview.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const {
    data,
    status,
    error: onboardingParametersError,
    refetch,
  } = useAnAggregatorOnboardingParameters({
    aggregator_id: Number(id),
    productType: filterParam,
    documentOwnerType: Number(documentOwnerType),
  });
  const {
    data: environmentData,
    status: environmentStatus,
    error: environmentError,
  } = useAggregatorEnvironment(Number(id));

  useEffect(() => {
    if (activeTab === "legal-compliance") {
      setDocumentOwnerType(1);
    } else if (activeTab === "credit-risk") {
      setDocumentOwnerType(2);
    }
  }, [activeTab]);

  const declineRequest = async ({ message }) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    const reqBody = {
      aggregator_id: Number(id),
      comment: message,
    };
    try {
      await postData(
        ajaxEndpoints.DECLINE_AGGREGATOR_ONBOARDING_REQUEST,
        reqBody
      );
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccess("Onboarding request has been declined.");

      setTimeout(() => {
        history.push("/aggregator-onboarding");
      }, 2000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "AggregatorOnboardingReview.jsx" },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setError(errorHandler(error));
    }
  };

  if (status === "loading" || environmentStatus === "loading") {
    return <Loader centered={true} text="Loading onboarding information..." />;
  }
  if (onboardingParametersError || environmentError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while loading the
            aggregator's onboarding information, please try again.
          </h4>
        </div>
      </div>
    );
  }

  const enableBApprovalOrDecline = (
    initialOnboardingCode,
    subsequentOnboardingCode
  ) => {
    if (
      ((initialOnboardingCode === onBoardingStatusCode?.AGGREGATOR_ACTIVATED &&
        subsequentOnboardingCode ===
          onBoardingStatusCode?.CREDIT_IN_PROGRESS) ||
        initialOnboardingCode === onBoardingStatusCode?.CREDIT_IN_PROGRESS) &&
      loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_ONBOARDING_APPROVER)
    ) {
      // Additional check to see if all credit documents have been approved
      // if all credit documents have been approved return a false and allow the admin to proceed
      // else return true and disable the buttons.
      if (isProceedDisabled(data.documents)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Review Request
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          {declined && declined === "true" ? (
            <button className="btn advancly-red-btn btn-md" type="button">
              <span className="d-flex align-items-center">
                Declined{" "}
                <span className="color-red ml-2">
                  <ErrorIcon color="red" />
                </span>
              </span>
            </button>
          ) : approved && approved === "true" ? (
            <Toggler aggregatorId={id} />
          ) : (
            <div>
              {activeTab === "credit-risk" && (
                <>
                  <button
                    className="btn advancly-red-btn btn-md mr-2"
                    data-toggle="modal"
                    data-target="#addOnboardingReviewModal"
                    onClick={() => setModalActionType("decline")}
                    disabled={
                      loading ||
                      enableBApprovalOrDecline(
                        environmentData?.statusCode,
                        environmentData?.subsequentStatusCode
                      )
                    }
                  >
                    {loading && modalActionType === "decline"
                      ? "Declining..."
                      : "Decline"}
                  </button>
                  <button
                    className="btn advancly-btn btn-md"
                    data-toggle="modal"
                    data-target="#addOnboardingReviewModal"
                    onClick={() => setModalActionType("approve")}
                    disabled={
                      loading ||
                      enableBApprovalOrDecline(
                        environmentData?.statusCode,
                        environmentData?.subsequentStatusCode
                      )
                    }
                  >
                    {loading && modalActionType === "approve"
                      ? "Approving..."
                      : "Approve Request"}
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Aggregator:</div>
          <div className={styles.content}>
            {data?.basicinformation?.biz_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Contact Person:</div>
          <div className={styles.content}>
            {data?.basicinformation?.first_name}{" "}
            {data?.basicinformation?.last_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Contact Email:</div>
          <div className={styles.content}>
            {data?.basicinformation?.personal_email}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Start Date</div>
          <div className={styles.content}>
            {environmentData?.onboardingStartDate?.substring(0, 10)}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>End Date</div>
          <div className={styles.content}>
            {environmentData?.onboardingEndDate?.substring(0, 10)}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Onboarding Status</div>
          <div className={styles.content}>
            {data?.basicinformation?.onboarding_status}
          </div>
        </div>
        {environmentData?.subsequentStatusCode > 0 && (
          <div className={styles.profileAreaCard}>
            <div className={styles.title}>Subsequent Onboarding Status</div>
            <div className={styles.content}>
              {environmentData?.subsequentStatusName}
            </div>
          </div>
        )}
      </div>

      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Business Information"
          id="business-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Eligibility Test"
          id="eligibility-test"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Legal & Compliance"
          id="legal-compliance"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Credit & Risk"
          id="credit-risk"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {approved && approved === "true" && (
          <ToggleTab
            text="Offer Letters and SLAs"
            id="offer-letters-sla"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {declined && declined === "true" && (
          <ToggleTab
            text="Admin Comment"
            id="admin-comment"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </div>

      <div>
        {(() => {
          switch (activeTab) {
            case "business-information":
              return (
                <AggregatorOnboardingReviewBusinesInfo
                  businessInfo={data && data?.basicinformation}
                />
              );
            case "eligibility-test":
              return (
                <AggregatorOnboardingReviewEligibilityTest
                  eligibilityInfo={data && data.questionaire?.questions}
                  aggregatorId={data?.basicinformation?.aggregator_id}
                  weightedScore={data?.questionaire?.score}
                  maximumPilotAmount={
                    data?.questionaire?.aggregatorExposure?.maximumPilotAmount
                  }
                  handleChange={handleChange}
                  filterOptions={filterOptions}
                />
              );
            case "legal-compliance":
              return (
                <AggregatorOnboardingReviewDownloadDocument
                  uploadedDocuments={data && data?.documents}
                  handleChange={handleChange}
                  filterOptions={filterOptions}
                  activeTab={activeTab}
                  refetch={refetch}
                  environmentData={environmentData}
                />
              );

            case "credit-risk":
              return (
                <AggregatorOnboardingReviewDownloadDocument
                  uploadedDocuments={data && data?.documents}
                  handleChange={handleChange}
                  filterOptions={filterOptions}
                  activeTab={activeTab}
                  refetch={refetch}
                  environmentData={environmentData}
                />
              );
            case "offer-letters-sla":
              return <AggregatorOnboardingReviewSLAUpload />;
            case "admin-comment":
              return (
                <AggregatorOnboardingReviewAdminComment
                  basicInfo={data?.basicinformation}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
      <AggregatorOnboardingReviewModal
        dismissModalRef={dismissModalRef}
        declineRequest={declineRequest}
        modalActionType={modalActionType}
        loading={loading}
        initialOnboardingCode={environmentData?.statusCode}
      />
    </Fragment>
  );
}
