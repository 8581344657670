import React, { Fragment, useState } from "react";
import { useWalletDetailsByInvestmentProduct } from "../../../../custom-hooks/useWallet";
import { useWalletDetailsByWalletId } from "../../../../custom-hooks/useWallet";
import { formatMoney } from "../../../../helpers/formatter";

const OfflineRequest = ({ properties, onCloseModal }) => {
  const {
    reference,
    handleSubmit,
    loading,
    handleReject,
    amount_to_credit,
    wallet_id,
    funder_id,
    currency,
    investment_product_id,
  } = properties;

  const submitHandleApprove = (e) => {
    e.preventDefault();
    handleSubmit({ reference: reference, comment: comment });
  };
  const submitHandleReject = (e) => {
    e.preventDefault();
    handleReject({ reference: reference, comment: comment });
  };
  const [comment, setComment] = useState("");

  const { data, status, isLoading, error } =
    useWalletDetailsByInvestmentProduct(
      investment_product_id,
      funder_id,
      currency
    );

  return (
    <div
      className="AuditTrailModal Modal__content"
      style={{ overflow: "hidden" }}
    >
      <div className="Modal__body">
        <Fragment>
          <h3 className="Modal__title p-b-1">Offline Request</h3>
          <hr />
          <form onSubmit={submitHandleApprove}>
            <div className="row">
              {status !== "loading" && !data && (
                <p className="alert alert-danger col-md-6">
                  Error fetching wallet balance.. Please reload
                </p>
              )}
              <div className="col-12 mb-1">
                <label>Wallet Balance</label>
                {status === "loading" ? (
                  <p className="text-primary font-weight-bold">
                    Loading Wallet Details...
                  </p>
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    name="walletBalance"
                    defaultValue={formatMoney(
                      data?.availableBalance || 0,
                      data?.currencyCode || ""
                    )}
                    readOnly
                  ></input>
                )}
              </div>
              <div className="col-12 mb-1">
                <label>Top-up Amount</label>
                <input
                  type="text"
                  className="form-control"
                  name="amount_to_credit"
                  defaultValue={formatMoney(
                    amount_to_credit || 0,
                    currency || ""
                  )}
                  disabled
                ></input>
              </div>
              <div className="col-12 mb-1">
                <label>Reference</label>
                <input
                  type="text"
                  className="form-control"
                  name="reference"
                  defaultValue={reference}
                  disabled
                ></input>
              </div>
              <div className="col-12 mb-1">
                <label>Comment</label>
                <input
                  type="text"
                  className="form-control"
                  name="comment"
                  defaultValue={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></input>
              </div>
              <div className="col-12 d-flex justify-content-end flex-wrap">
                <button
                  className="btn advancly-btn btn-sm transition-3d-hover mt-2 mr-2"
                  type="button"
                  disabled={loading || !comment || !reference}
                  onClick={submitHandleApprove}
                >
                  Approve
                </button>
                <button
                  className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                  type="submit"
                  disabled={loading || !comment || !reference}
                  onClick={submitHandleReject}
                >
                  Reject
                </button>
              </div>
            </div>
          </form>
        </Fragment>
      </div>
    </div>
  );
};
export default OfflineRequest;
