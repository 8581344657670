import React from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as BackButtonIcon } from "../../assets/svg/icons/back-button.svg";
import styles from "./BackButton.module.scss";

const BackButton = ({ history }) => {
  return (
    <span className={styles.backButton} onClick={() => history.goBack()}>
      <BackButtonIcon />
    </span>
  );
};
export default withRouter(BackButton);
