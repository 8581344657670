import { useState } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// Redux
import { connect } from "react-redux";
import { showModal } from "./../../../redux/actions/modal";

// UI Components
import ButtonLoader, { Loader } from "../../../UI/Loaders/Loaders";
import { getTokenFromLocalStorage } from "./../../../helpers/localStorage";
import Paginator from "./../../Pagination/Pagination.component";
import { attachClassNameToTag } from "./../../../helpers/attachClassNameToTag";
import { formatMoney } from "../../../helpers/formatter";
import useAllRepayment from "../../../custom-hooks/useAllRepayment";
import { BASE_URL } from "../../../appConstants";

const Repayments = (props) => {
  const [preState, setPreState] = useState({
    page: 1,
    size: 10,
  });
  const [state, setState] = useState({
    page: 1,
    size: 10,
    startDate: "",
    endDate: "",
    loanRef: "",
  });
  const [count, setCount] = useState(null);
  const [buttonDownload, setButtonDownload] = useState(false);

  const { data: TableData, status, error } = useAllRepayment(preState);
  if (TableData) {
    if (count === null) {
      setCount(TableData.totalCount);
    }
  }
  const dialogHandler = ({ action, data, identifier }) => {
    props.showModal({
      action,
      dataRecord: data,
      modalIdentifier: identifier,
    });
  };
  const filterData = (e) => {
    e.preventDefault();
    setCount(null);
    const diffTime = new Date(state.endDate) - new Date(state.startDate);

    if (
      diffTime < 0 ||
      (state.startDate && !state.endDate) ||
      (!state.startDate && state.endDate)
    ) {
      props.showModal({
        modalIdentifier: "audittrail",
        dataRecord: "",
        action: "customrange",
        type: "alert",
      });
    } else {
      setPreState(state);
    }
  };
  const downloadCSV = () => {
    setButtonDownload(true);

    let token = getTokenFromLocalStorage();

    fetch(
      BASE_URL +
        `/eco/repayments/csv?RepaymentStartDate=${state.startDate}&RepaymentToDate=${state.endDate}&LoanRef=${state.loanRef}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
        // body: JSON.stringify(data)
      }
    ).then((response) => {
      setButtonDownload(false);
      if (response.status === 200 || response.status === 201) {
        setPreState(state);
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `repayment.csv`);
          link.click();
          // link.parentNode.removeChild(link);
        });
      }
    });
  };

  let RenderedComponent = null;

  //  If issues object is null e.g. Initial component render before API fetch
  if (error || (TableData === undefined && status !== "loading")) {
    RenderedComponent = (
      <div className="centerLoader">
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Exist Issues
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader text="Loading available repayments data ..." />;
  } else if (TableData) {
    // Verify that a issues object exists
    if (TableData === undefined || TableData === null) {
      RenderedComponent = (
        <div centerLoader>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> Problem Fetching Existing Issues,
              Please Reload the Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (TableData?.data?.length > 0) {
      RenderedComponent = (
        <div className="grid__padding animated fadeInRight">
          <MDBTable responsive minimal="true" bordered striped hover>
            <MDBTableHead>
              <tr>
                <th>ID</th>
                <th>Repayment Ref</th>
                <th>Borrower Name</th>
                <th>Aggregator</th>
                <th>Loan Ref</th>
                <th>Repayment Amount</th>
                <th>Repayment Date</th>
                <th>Repayment Status</th>
                <th>Repayment Channel Status</th>
                <th>Approved By</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {TableData.data.map((data, _id) => {
                const {
                  currency,
                  repayment_id,
                  borrower_full_name,
                  aggregator_name,
                  repayment_amount,
                  repayment_date,
                  repayment_channel_status,
                  loan_ref,
                  repayment_ref,
                  corebanking_status,
                } = data;
                return (
                  <tr key={_id}>
                    <td>{repayment_id}</td>
                    <td>{repayment_ref}</td>
                    <td>{borrower_full_name}</td>
                    <td>{aggregator_name}</td>
                    <td>{loan_ref}</td>
                    <td>{formatMoney(Number(repayment_amount), currency)}</td>
                    <td>{repayment_date.substring(0, 10)}</td>
                    <td>
                      <span
                        className={attachClassNameToTag(corebanking_status)}
                      >
                        {attachClassNameToTag(corebanking_status)}
                      </span>
                    </td>
                    <td>
                      <span
                        className={attachClassNameToTag(
                          repayment_channel_status
                        )}
                      >
                        {attachClassNameToTag(repayment_channel_status)}
                      </span>
                    </td>

                    <td>
                      {" "}
                      <span
                        className="Repayments view btn-manage zoom-element btn-slim"
                        onClick={() => {
                          dialogHandler({
                            action: "View",
                            data,
                            identifier: "repaymentapprovaltrail",
                          });
                        }}
                      >
                        View
                      </span>
                    </td>
                    <td>
                      <span
                        className="Repayments view btn-manage zoom-element btn-slim"
                        onClick={() => {
                          dialogHandler({
                            action: "View",
                            data,
                            identifier: "Repayments",
                          });
                        }}
                      >
                        View
                      </span>
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={preState.size}
            page={preState.page}
            count={count}
            changeCurrentPage={(pageNumber) =>
              setPreState({ ...state, page: pageNumber })
            }
          />
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated speed-1x fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are Currently No Repayment
            </h4>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="dataTables_wrapper">
      <div
        id="finance"
        className="table-view animated speed-1x fadeInRight m-b-1 m-t-1"
      >
        {/*Welcome Block*/}
        <div className="block-header">
          <div className="flex-row ">
            <div className="col-lg-6 col-md-6 col-sm-7 dashboard-title">
              <h3>
                <aside className="rounder">2</aside>
                &nbsp; Repayments
              </h3>
            </div>
            <div className="m-b-1">
              <button
                id="create"
                data-id="create-role"
                data-action="create"
                className="advancly-btn btn login-btn"
                onClick={downloadCSV}
              >
                <i className="fas fa-download m-r-1" />
                Download {buttonDownload && <ButtonLoader />}
              </button>
            </div>
          </div>
          <div className="flex-row mb-1">
            <form onSubmit={filterData}>
              <div
                className="d-flex flex-row flex-wrap justify-content-start"
                style={{ alignItems: "flex-end" }}
              >
                <div
                  className="input-group mb-3 ml-3"
                  style={{ maxWidth: "200px" }}
                >
                  <input
                    className="form-control"
                    placeholder="search by loan ref"
                    value={state.loanRef}
                    name="loanRef"
                    onChange={(e) =>
                      setState({ ...state, loanRef: e.target.value })
                    }
                  />
                </div>
                <div className="m-l-1 m-r-1 form-group">
                  <label htmlFor="table-fetch">Start Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) =>
                      setState({ ...state, startDate: e.target.value })
                    }
                    value={state.startDate}
                  />
                </div>

                <div className="m-l-1 m-r-1  form-group">
                  <label htmlFor="table-fetch">End Date:</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) =>
                      setState({ ...state, endDate: e.target.value })
                    }
                    value={state.endDate}
                  />
                </div>
                <button
                  type="submit"
                  className="btn advancly-btn btn-md mb-3"
                  value="submit"
                  disabled={status === "loading"}
                >
                  Search{status === "loading" && <ButtonLoader />}
                </button>
              </div>
            </form>
          </div>
          <div>{RenderedComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { showModal })(Repayments);
