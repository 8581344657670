import { yupResolver } from "@hookform/resolvers/yup";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import moment from "moment";
import * as yup from "yup";

import { DownloadOfferLetterDocument } from "../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import { DeleteUploadedDocument } from "../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";

import CustomTextArea from "../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { ReactComponent as Upload } from "../../../assets/svg/icons/fileUpload.svg";
import ButtonLoader from "../../../UI/Loaders/Loaders";
import { ReactComponent as BackButtonIcon } from "../../../assets/svg/icons/back-button.svg";
import { documentApprovalStatus, onBoardingStatusCode } from "../util";
import OfferLetterSLAActionModal from "./Components/OfferLetterSLAApprovalModal";
import { ROLE_TYPES, auditRoleList, isAudit } from "../../../helpers/roleTypes";

import styles from "../AggregatorOnboardingReview.module.scss";

const schema = yup.object().shape({
  message: yup
    .string()
    .trim()
    .required("Please enter your message")
    .min(1, "Message must have at least 1 character")
    .max(256, "Message cannot be longer than 256 characters"),
});
export default function AggregatorOnboardingReviewSLAUpload({
  data,
  filesToBeUploaded,
  removeFile,
  onSubmit,
  viewHistory,
  updating,
  onFileChange,
  handleCompleteOffer,
  onboardingData,
  reuploadDocument,
  setReuploadDocument,
  refetchOfferSla,
}) {
  const [actionData, setActionData] = useState({});

  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const isDisplayReuploadDocument = () => {
    // Here we check to be sure that aggregator has uploaded all documents.
    // if so, we remove the ability for the admin to change documents to be uploaded by the aggregator.
    let testArray = [];

    data.forEach((item) => {
      const result = item.aggregator_documents?.every(
        (aggreDoc) => !aggreDoc.aggregator_upload_path
      );

      if (result) {
        testArray.push(true);
      } else {
        testArray.push(false);
      }
    });

    const result = testArray.every((item) => item === true);
    if (result === true) {
      return false;
    } else {
      return true;
    }
  };

  const isEnableCompleteOfferButton = () => {
    let testArray = [];

    data.forEach((item) => {
      const result = item.aggregator_documents?.every(
        (aggreDoc) =>
          aggreDoc.approval_status === documentApprovalStatus.APPROVED
      );

      if (result) {
        testArray.push(true);
      } else {
        testArray.push(false);
      }
    });

    const result = testArray.every((item) => item === true);
    if (result === true) {
      return false;
    } else {
      return true;
    }
  };

  const getAggregatorComments = (resource) => {
    let commentsList = [];

    if (resource && resource.length) {
      resource[0]?.aggregator_documents.forEach((doc) => {
        doc.comments &&
          doc.comments
            .filter((item) => item?.user_type === "aggregator")
            .map((comment) => {
              return commentsList.push(comment);
            });
      });
    }

    return commentsList;
  };

  const handleDisableOfferSLaDocumentAction = () => {
    if (loggedInUser?.roles?.includes(ROLE_TYPES.LEGAL_ONBOARDING_APPROVER)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    getAggregatorComments(data);
  }, [data]);

  return (
    <Fragment>
      <div className={`card w-100 mb-4 ${styles.offerLetterCardContainer}`}>
        <div className="card-body p-4">
          {!reuploadDocument && (
            <p className="text-justify color-card-border-color font-weight-bold">
              Date:{" "}
              {data[0]?.aggregator_documents[0]?.admin_upload_date?.substring(
                0,
                10
              )}
            </p>
          )}
          {!reuploadDocument && (
            <>
              <p className="text-justify color-card-border-color">
                Aggregator has attached an offer letter and relevant documents
                for your perusal.
              </p>
              <p className="text-justify color-card-border-color">
                Kindly review and complete offer or reupload documents as
                applicable.
              </p>
            </>
          )}
          {reuploadDocument && (
            <div>
              <div className={styles.backButton}>
                <BackButtonIcon onClick={() => setReuploadDocument(false)} />
              </div>

              <p className="text-justify color-card-border-color">
                Kindly upload documents as applicable.
              </p>
            </div>
          )}
          <div className="row">
            <p className="col-6 font-weight-bold">
              Admin's Uploads{" "}
              {!reuploadDocument && (
                <span className="dateText">
                  {moment(
                    data[0]?.aggregator_documents[0]?.admin_upload_date
                  ).format("lll")}
                </span>
              )}
            </p>
            {!reuploadDocument && (
              <p className="col-6 font-weight-bold">
                Aggregator's Uploads{" "}
                <span className="dateText">
                  {moment(
                    data[0]?.aggregator_documents[0]?.aggregator_upload_date
                  ).format("lll")}
                </span>
              </p>
            )}
          </div>

          <div className="row">
            {data && data.length
              ? data.map((obj, _id) => {
                  const {
                    aggregator_documents,
                    document_field_Name,
                    document_field_id,
                  } = obj;
                  const aggregatorUploaded =
                    aggregator_documents[0] &&
                    aggregator_documents[0]?.aggregator_file_name &&
                    aggregator_documents[0]?.aggregator_upload_path;
                  return (
                    <>
                      <div className="col-4" key={document_field_id}>
                        {filesToBeUploaded[_id] &&
                        filesToBeUploaded[_id]?.filename_with_extension &&
                        filesToBeUploaded[_id]?.base64_file_string ? (
                          <DeleteUploadedDocument
                            name={
                              filesToBeUploaded[_id].filename_with_extension
                            }
                            deleteFile={() => removeFile(_id)}
                            offerLetter={true}
                          />
                        ) : (
                          <Fragment>
                            {/* List contains admins initial uploads and then subsequent uploads */}
                            {!reuploadDocument &&
                              aggregator_documents.map((item, index) => (
                                <>
                                  {/* Checking to be sure subsequent document has been uploaded */}
                                  {item?.admin_filename && (
                                    <DownloadOfferLetterDocument
                                      key={index}
                                      name={item.admin_filename}
                                      pathname={item.admin_upload_path}
                                      documentName={document_field_Name}
                                      viewHistory={viewHistory ? true : false}
                                    />
                                  )}
                                </>
                              ))}
                            {reuploadDocument && (
                              <div
                                className="color-mid-blue cursor-pointer mt-4"
                                onClick={() => {
                                  document
                                    .getElementById(`file-selector${_id}`)
                                    .click();
                                }}
                              >
                                <span className="mr-2">
                                  <Upload />
                                </span>
                                <span className="text-underline">
                                  Upload {document_field_Name}
                                </span>
                              </div>
                            )}
                          </Fragment>
                        )}
                      </div>
                      <div className={`col-8 ${styles.uploadedDocsContainer}`}>
                        {/* List contains aggregator's initial uploads and then
                        final uploads. */}
                        {aggregatorUploaded &&
                          !reuploadDocument &&
                          aggregator_documents.map((item, index) => (
                            <div className="d-flex" key={index}>
                              {/* Checking to be sure subsequent document has been uploaded */}
                              <div
                                className={styles.offerLetterUploadContainer}
                              >
                                {item?.aggregator_file_name && (
                                  <DownloadOfferLetterDocument
                                    name={item?.aggregator_file_name}
                                    pathname={item?.aggregator_upload_path}
                                    documentName={document_field_Name}
                                  />
                                )}
                              </div>

                              <div className="d-flex ml-5">
                                <div className="d-flex">
                                  {item.approval_status ===
                                    documentApprovalStatus.DECLINED && (
                                    <button
                                      className="btn advancly-red-btn btn-md"
                                      disabled
                                    >
                                      Declined
                                    </button>
                                  )}
                                  {item.approval_status ===
                                    documentApprovalStatus.APPROVED && (
                                    <button
                                      className="btn advancly-green-btn btn-md"
                                      disabled
                                    >
                                      Approved
                                    </button>
                                  )}
                                  {/* {(item.approval_status ===
                                    documentApprovalStatus.APPROVED ||
                                    item.approval_status ===
                                      documentApprovalStatus.DECLINED) && (
                                    <div className={styles.commentsAction}>
                                      <MessageIcon
                                        className={styles.messageIcon}
                                      />
                                      <span className={styles.commentText}>
                                        Comment
                                      </span>
                                    </div>
                                  )} */}
                                </div>

                                {item.approval_status ===
                                  documentApprovalStatus.PENDING &&
                                  !(
                                    loggedInUser?.roles.includes(
                                      ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER
                                    ) &&
                                    loggedInUser?.roles.includes(
                                      ROLE_TYPES.INVESTMENT_VIEWER
                                    ) &&
                                    loggedInUser?.roles.includes(
                                      ROLE_TYPES.CREDIT_VIEWER
                                    ) &&
                                    loggedInUser?.roles.includes(
                                      ROLE_TYPES.AGGREGATOR_VIEWER
                                    )
                                  ) && (
                                    <>
                                      <button
                                        className="btn advancly-btn btn-md"
                                        data-toggle="modal"
                                        data-target="#offerslaDocumentActionModal"
                                        disabled={handleDisableOfferSLaDocumentAction()}
                                        onClick={() =>
                                          setActionData({
                                            actionType: "approve",
                                            data: item,
                                          })
                                        }
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn advancly-off-2-btn btn-md ml-3"
                                        data-toggle="modal"
                                        data-target="#offerslaDocumentActionModal"
                                        disabled={handleDisableOfferSLaDocumentAction()}
                                        onClick={() =>
                                          setActionData({
                                            actionType: "decline",
                                            data: item,
                                          })
                                        }
                                      >
                                        Decline
                                      </button>
                                    </>
                                  )}
                              </div>
                            </div>
                          ))}
                      </div>
                      <input
                        type="file"
                        accept="application/pdf, application/msword"
                        onChange={(e) =>
                          onFileChange(
                            e,
                            _id,
                            document_field_id,
                            aggregator_documents[0].id
                          )
                        }
                        className="d-none"
                        id={`file-selector${_id}`}
                      />
                    </>
                  );
                })
              : null}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {!reuploadDocument && (
              <div className={`row mt-3 ${styles.aggregatorsComments}`}>
                <div className="col-8">
                  {getAggregatorComments(data).map((comment, index) => (
                    <CustomTextArea
                      key={index}
                      label="Aggregator's Comments"
                      otherLabel={comment.datetime_created.substring(0, 10)}
                      readOnly
                      defaultValue={comment?.comment}
                    />
                  ))}
                </div>
              </div>
            )}
            {reuploadDocument && (
              <div className="row mt-3">
                <div className="col-8">
                  <CustomTextArea
                    maxLength="256"
                    reference={register}
                    label={`Your comment`}
                    name="message"
                    errors={errors.message}
                    rows="4"
                  />
                </div>
              </div>
            )}
            {!reuploadDocument &&
              !isAudit([...loggedInUser?.roles], [...auditRoleList]) && (
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn advancly-white-btn btn-sm transition-3d-hover mt-3 w-100"
                      disabled={isDisplayReuploadDocument() || updating}
                      onClick={(e) => setReuploadDocument(true)}
                    >
                      Reupload Document
                    </button>
                  </div>

                  {(onboardingData.statusCode ===
                    onBoardingStatusCode?.SLA_OFFER_SUBMITTED ||
                    onboardingData?.subsequentStatusCode ===
                      onBoardingStatusCode?.SLA_OFFER_SUBMITTED) &&
                    !isAudit([...loggedInUser?.roles], [...auditRoleList]) && (
                      <div className="col-6">
                        <button
                          className="btn advancly-btn btn-sm transition-3d-hover mt-3 w-100"
                          disabled={isEnableCompleteOfferButton() || updating}
                          onClick={(e) => handleCompleteOffer(e)}
                        >
                          Complete Offer {updating && <ButtonLoader />}
                        </button>
                      </div>
                    )}
                </div>
              )}
            {reuploadDocument &&
              onboardingData.statusCode ===
                onBoardingStatusCode.SLA_OFFER_SUBMITTED && (
                <button
                  className="btn advancly-btn btn-sm transition-3d-hover mt-3 w-100"
                  type="submit"
                  disabled={updating}
                >
                  Resend Document {updating && <ButtonLoader />}
                </button>
              )}
          </form>
        </div>
      </div>

      <OfferLetterSLAActionModal
        data={actionData}
        refetchData={refetchOfferSla}
      />
    </Fragment>
  );
}
