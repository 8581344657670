import React, { Component } from "react";
// Ajax Methods
import ajaxMethods from "../../../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../../config/appInsights";

class ProductForm extends Component {
  state = {
    selectedProductId: "",
    categoryEdit: "",
    editProductCategoryCode: "",
    editproductCategoryName: "",
  };
  handleOptionSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  cancelEdit = (e) => {
    this.setState({
      categoryEdit: false,
    });
  };
  editHandler = (e) => {
    // e.preventDefault();
    this.setState({
      categoryEdit: true,
    });
    this.getProductCategories();
  };
  handleEditChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // fetch product category list
  getProductCategories = () => {
    ajaxMethods
      .get(
        ajaxEndpoints.PRODUCT_CATEGORY_LIST +
          `?product_category_id=${this.state.selectedProductId}`
      )
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];

          this.setState({
            editProductCategoryCode: res.code,
            editproductCategoryName: res.productCategoryName,
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductForm.js",
          },
        });
      });
  };

  updateEdit = () => {
    const {
      editproductCategoryName,
      editProductCategoryCode,
      selectedProductId,
    } = this.state;
    const productCategoryName = {
      productCategoryName: editproductCategoryName,
      code: editProductCategoryCode,
      product_category_id: selectedProductId,
    };
    ajaxMethods
      .post(ajaxEndpoints.EDIT_PRODUCT_CATEGORY_SECTOR, productCategoryName)
      .then((response) => {
        let message = "";

        if (response.status_code === 200 || response.status === 200) {
          message = response.data.message;
          this.props.setToast("success", message);
          // this.populateCategories();
          this.setState({ categoryEdit: false });
        } else if (response.status === 400) {
          message = response.data.message;
          this.props.setToast("error", message);
        } else {
          this.props.setToast(
            "warning",
            "Unable to connect to Advancly services"
          );
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductForm.js",
          },
        });
        //
        this.props.setToast(
          "warning",
          "Unable to connect to Advancly services"
        );
      });
  };

  render() {
    const { productCategoryList, productCategoryName, productCategoryCode } =
      this.props.inputs;
    const { systemEdit } = this.props;
    const productCategoryOptions = productCategoryList.map(
      (ecosystem, index) => {
        const { code, productCategoryName, id } = ecosystem;
        return (
          <option key={index} id={code} value={id}>
            {productCategoryName}
          </option>
        );
      }
    );

    const {
      selectedProductId,
      categoryEdit,
      editproductCategoryName,
      editProductCategoryCode,
    } = this.state;
    return (
      <div className="card">
        <div className="body text-center">
          <h3 className="m-t-0">
            <i className="adv-icon fas fa-cogs" /> Products Category{" "}
          </h3>
          <p className="m-t-2 m-l-2 text-left">
            {!categoryEdit && (
              <button
                type="submit"
                className="zoom-element btn login-btn"
                data-form="system"
                onClick={(e) => this.props.onEditMode(e.currentTarget)}
              >
                <i className="fas fa-plus" />
                &nbsp; Add New Category
              </button>
            )}
            <button
              type="submit"
              className="zoom-element btn m-l-1"
              data-form="system"
              onClick={(e) => this.editHandler(e.currentTarget)}
            >
              <i className="fas fa-pencil-square-o" />
            </button>
          </p>

          {!categoryEdit && (
            <div className="grid__full m-b-1">
              <div className="p-l-0 grid__padding">
                <label className="label-txt mb-1">
                  List of Product Categories
                </label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="selectedProductId"
                    placeholder="Select Product category"
                    title="Select a Product"
                    id="selectedProductId"
                    value={selectedProductId}
                    onChange={(e) => this.handleOptionSelect(e)}
                  >
                    <option selected>list of categories</option>
                    {productCategoryOptions}
                  </select>
                  <i className="adv-aggregator-icon fas fa-building"></i>
                </div>
                <div className="errorHandler form-group text-left grid__full">
                  <span id="email-msg" className="hidden error-element"></span>
                </div>
              </div>
            </div>
          )}

          {categoryEdit && (
            <div className="grid__full settings-block">
              <label className="label-txt">Edit a Product Category</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  placeholder="Add a Category"
                  autoComplete="off"
                  data-form="system"
                  name="editproductCategoryName"
                  readOnly={!categoryEdit}
                  value={editproductCategoryName}
                  onChange={this.handleEditChange}
                  required
                />
                <i className="fas fa-plus-circle" aria-hidden="true" />
              </div>
              <label className="label-txt">Add Category Code</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  placeholder="Add a Category code"
                  autoComplete="off"
                  data-form="system"
                  name="editProductCategoryCode"
                  readOnly={!categoryEdit}
                  value={editProductCategoryCode}
                  onChange={this.handleEditChange}
                  required
                />
                <i className="fas fa-plus-circle" aria-hidden="true" />
              </div>
            </div>
          )}

          <div className="grid__full">
            {categoryEdit && (
              <div className="Form__action-buttons">
                <button
                  type="button"
                  className="btn zoom-element login-btn m-r-1"
                  data-form="system"
                  onClick={(e) => this.cancelEdit(e.currentTarget)}
                >
                  <i className="fas fa-times-circle" />
                  &nbsp; Cancel
                </button>
                <button
                  type="button"
                  data-form="system"
                  className="btn zoom-element login-btn m-l-1"
                  onClick={(e) => this.updateEdit(e.currentTarget)}
                >
                  <i className="fas fa-save" />
                  &nbsp; Update
                </button>
              </div>
            )}
          </div>

          {systemEdit && !categoryEdit && (
            <div className="grid__full settings-block">
              <label className="label-txt">Add a new Product Category</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  placeholder="Add a Category"
                  autoComplete="off"
                  data-form="system"
                  name="productCategoryName"
                  readOnly={!systemEdit}
                  value={productCategoryName}
                  onChange={(e) => this.props.onInputChange(e.currentTarget)}
                  required
                />
                <i className="fas fa-plus-circle" aria-hidden="true" />
              </div>
              <label className="label-txt">Add Category Code</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="user__form form-control"
                  placeholder="Add a Category code"
                  autoComplete="off"
                  data-form="system"
                  name="productCategoryCode"
                  readOnly={!systemEdit}
                  value={productCategoryCode}
                  onChange={(e) => this.props.onInputChange(e.currentTarget)}
                  required
                />
                <i className="fas fa-plus-circle" aria-hidden="true" />
              </div>
            </div>
          )}

          <div className="grid__full">
            {systemEdit && !categoryEdit && (
              <div className="Form__action-buttons">
                <button
                  type="button"
                  className="btn zoom-element login-btn m-r-1"
                  data-form="system"
                  onClick={(e) => this.props.onCancelUpdate(e.currentTarget)}
                >
                  <i className="fas fa-times-circle" />
                  &nbsp; Cancel
                </button>
                <button
                  type="button"
                  data-form="system"
                  className="btn zoom-element login-btn m-l-1"
                  onClick={(e) => this.props.onAddCategory(e.currentTarget)}
                >
                  <i className="fas fa-save" />
                  &nbsp; Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductForm;
