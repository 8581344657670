import { Fragment } from "react";
import { DownloadOfferLetterDocument } from "../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import CustomTextArea from "../../../NewComponents/CustomHTMLElements/CustomTextArea";
export default function GetStartedViewHistory({ data }) {
  return (
    <Fragment>
      <div className="card w-100 mb-4">
        <div className="card-body p-4">
          <p className="text-justify color-card-border-color font-weight-bold">
            Date: {data?.adminUploadDate?.substring(0, 10)}
          </p>
          <p className="text-justify color-card-border-color">
            Aggregator has attached an offer letter and relevant documents for
            your perusal.
          </p>
          <p className="text-justify color-card-border-color">
            Kindly review and complete offer or reupload documents as
            applicable.
          </p>
          <div className="row">
            <p className="col-6 font-weight-bold">
              Admin's Upload{" "}
              <span className="dateText">
                {data?.adminUploadDate?.substring(0, 10)}
              </span>
            </p>
            <p className="col-6 font-weight-bold">
              Aggregator's Uploads
              <span className="dateText">
                {data?.aggregatorUploadDate?.substring(0, 10)}
              </span>
            </p>
          </div>

          <div className="row">
            <div className="col-6">
              <DownloadOfferLetterDocument
                name={data?.adminFileName}
                pathname={data?.adminUploadPath}
                documentName={data?.documentName}
              />
            </div>
            <div className="col-6">
              <DownloadOfferLetterDocument
                name={data?.aggregatorFileName}
                pathname={data?.aggregator_upload_path}
                documentName={data?.documentName}
              />
            </div>
          </div>
          <form>
            <div className="row mt-3">
              <div className="col-12">
                <CustomTextArea
                  label={`Admin's comment on `}
                  otherLabel={`${data?.adminComment}`}
                  disabled
                  defaultValue={data ? data?.adminComment : ""}
                />
              </div>
              <div className="col-12">
                <CustomTextArea
                  label={`Aggregator's comment on`}
                  otherLabel={`${data?.aggregatorUploadDate}`}
                  disabled
                  defaultValue={data ? data?.aggregatorComment : ""}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
