import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { renderBadge } from "./helpers";

interface ICustomInputField {
  type: string;
  name: string;
  placeholder: string;
  reference: React.LegacyRef<HTMLInputElement> | undefined;
  label: string;
  errors: object;
  maxLength: number;
  max: number;
  defaultValue?: string | null | number;
  minLength: number;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  iconType: string;
  readOnly: boolean;
  isShowBadge: boolean;
  badgeText: string;
  customInputStyle: React.CSSProperties;
  labelStyle: React.CSSProperties;
  iconStyle: React.CSSProperties;
  iconClick: React.MouseEventHandler<HTMLElement> | undefined;
}

const CustomInputField = ({
  type,
  name,
  placeholder,
  reference,
  label,
  errors,
  maxLength,
  max,
  defaultValue,
  minLength,
  onChange,
  iconType,
  readOnly,
  isShowBadge = false,
  badgeText = "",
  customInputStyle,
  labelStyle,
  iconStyle,
  iconClick,
}: Partial<ICustomInputField>) => {
  return (
    <div className="mb-5">
      {!isShowBadge && (
        <label className="label-txt" style={{ ...labelStyle }}>
          {label}
        </label>
      )}
      {isShowBadge && (
        <label className="label-txt d-flex justify-content-between">
          <span className="d-block">{label}</span>
          {badgeText && renderBadge(badgeText)}
        </label>
      )}
      <div className="form-group inputWithIcon">
        <input
          className="user__form form-control"
          type={type}
          name={name}
          max={max}
          placeholder={placeholder}
          ref={reference}
          onKeyPress={enforceMaxLength}
          maxLength={maxLength}
          minLength={minLength}
          defaultValue={defaultValue ?? ""}
          onChange={onChange}
          readOnly={readOnly}
          style={{ ...customInputStyle }}
        />
        {iconType && (
          <i
            className={iconType}
            style={{ ...iconStyle }}
            onClick={iconClick}
          />
        )}
      </div>
      <div className="text-left mb-2 ml-3" style={{ marginTop: "-10px" }}>
        <ErrorHandler errors={errors} />
      </div>
    </div>
  );
};

export default CustomInputField;
