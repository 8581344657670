import React from "react";
import styles from "./OnboardingSettings.module.scss";
import { Link } from "react-router-dom";

export default function OnboardingSettings() {
  return (
    <div className="mt-1 w-100">
      <div className={styles.settingsCard}>
        <div className={styles.title}>Eligibility Test Questions</div>
        <div>
          <Link
            to="/aggregator-onboarding/eligibility/admin"
            className="btn advancly-btn btn-md"
          >
            View
          </Link>
        </div>
      </div>
      <div className={styles.settingsCard}>
        <div className={styles.title}>Required Documents</div>
        <div>
          <Link
            to="/aggregator-onboarding/documents/admin"
            className="btn advancly-btn btn-md"
          >
            View
          </Link>
        </div>
      </div>
    </div>
  );
}
