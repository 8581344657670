import { Fragment } from "react";
import { onboardingAnswerTypes } from "../../../helpers/onboardingAnswerTypes";
import CustomBadge from "../../Badge/badge";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";

export default function EligibilityTestField({
  isMandatory,
  question,
  questionAnswerType,
  questionAnswers,
  selectedAnswer,
  productTypes,
}) {
  const selectDropdownDefaultValue = questionAnswers.find((questionAnswer) => {
    return questionAnswer.id === selectedAnswer?.questionAnswerId[0];
  });

  const isChecked = (id) => {
    return selectedAnswer?.questionAnswerId.includes(id);
  };

  return (
    <Fragment>
      {questionAnswerType === onboardingAnswerTypes.SINGLE_CHOICE ? (
        <div className="col-12">
          <CustomSelectDropdown
            isShowBadge={true}
            label={question}
            showRequiredIcon={isMandatory}
            readOnly={true}
            defaultvalue={selectDropdownDefaultValue?.value}
            value={selectDropdownDefaultValue?.value}
            badgeText={
              productTypes &&
              productTypes.length > 0 &&
              productTypes.map((productType) => productType?.product)
            }
          >
            <option value=""></option>
            {questionAnswers &&
              questionAnswers.length &&
              questionAnswers.map((answers, _id) => {
                const { id, value } = answers;
                return (
                  <option key={id} value={value}>
                    {value}
                  </option>
                );
              })}
          </CustomSelectDropdown>
        </div>
      ) : questionAnswerType === onboardingAnswerTypes.TEXT_ANSWER ? (
        <div className="col-12">
          <CustomInputField
            isShowBadge
            label={question}
            showRequiredIcon={isMandatory}
            readOnly={true}
            defaultValue={selectedAnswer?.questionAnswerText}
            value={selectedAnswer?.questionAnswerText}
            badgeText={
              productTypes &&
              productTypes.length > 0 &&
              productTypes.map((productType) => productType?.product)
            }
          />
        </div>
      ) : questionAnswerType === onboardingAnswerTypes.MULTIPLE_CHOICE ? (
        <div className="col-12 mb-4">
          <label className="form-label d-flex justify-content-between">
            <span>
              {question}
              {isMandatory && <sup className="ml-1 crimson">*</sup>}
            </span>

            {productTypes &&
              productTypes.length &&
              productTypes.map((prdType, index) => (
                <CustomBadge key={index} text={prdType?.product} />
              ))}
          </label>

          {/* Asset Finance  */}
          {questionAnswers &&
            questionAnswers.length &&
            questionAnswers.map((answers, _id) => {
              return (
                <div className="form-check" key={_id}>
                  <input
                    className="form-check-input multichoice"
                    type="checkbox"
                    value={answers.value}
                    disabled={true}
                    defaultChecked={isChecked(answers.id)}
                  />
                  <label
                    className="form-check-label ml-3"
                    htmlFor={answers.value}
                  >
                    {answers.value}
                  </label>
                </div>
              );
            })}
        </div>
      ) : null}
    </Fragment>
  );
}
