import React from 'react';

// Component Styles
import './FunderModal.css';

const FunderModal = ({
    properties : {
        dataRecord
    }, 
    onCloseModal 
}) => {
    const printHandler = () =>{}

    const {
        id, 
        first_name,
        last_name,
        phone,
        email
    } = dataRecord;
        
        return (
            <div className="Modal__dialog animated slideInDown" role="document">
                <div className="TransactionModal Modal__content">
                    <div className="Modal__body" style={{overflow: 'hidden'}}>
                        <h3 className="Modal__title p-b-2">Funder ID: <span>{id}</span></h3>
                        {/* Transaction Form */}
                        <form>
                            <label className="label-txt">Full Name</label>
                            <div className="form-group inputWithIcon">
                                <input                                    
                                    type="text"
                                    className="user__form form-control"
                                    readOnly
                                    value={`${first_name} ${last_name}`}/>
                                <i className="fas fa-user-circle"/>
                            </div>

                            <label className="label-txt">Email</label>
                            <div className="form-group inputWithIcon">
                                <input                                    
                                    type="text"
                                    readOnly
                                    className="user__form form-control"
                                    value={email}/>
                                <i className="far fa-envelope"></i>
                            </div>

                            <label className="label-txt">Phone</label>
                            <div className="form-group inputWithIcon">
                                <input                                    
                                    type="text"
                                    readOnly
                                    className="user__form form-control"
                                    value={phone}/>
                                <i className="far fa-envelope"></i>
                            </div>
                            
                            <div className="Form__action-buttons m-t-1">
                                    <button type="button" 
                                            className="btn zoom-element btn login-btn m-0 mr-auto" 
                                            onClick={printHandler}>
                                        <i className="fas fa-print"></i>
                                        &nbsp; Print
                                    </button>
                                    <button type="button" 
                                            className="btn zoom-element btn login-btn m-0 ml-auto" 
                                            onClick={onCloseModal} 
                                            data-dismiss="modal">
                                        <i className="fas fa-times-circle"></i>
                                        &nbsp; Go Back
                                    </button>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        )
}

export default FunderModal;