import React, { Component } from "react";
// Ajax Methods
import ajaxMethods from "../../../../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../../../api/ajax-endpoints";

import { connect } from "react-redux";

import { ProductSector } from "./ProductSector";
import { showModal } from "../../../../../../../redux/actions/modal";
import { appInsights } from "../../../../../../../config/appInsights";

class ProductCategoryInterface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      showCatergory: true,
      //product details
      selectedProduct: "",
      productCategoryOptions: [],
      // sector details
      selctedSector: "",
      sectorCategoryOptions: [],
    };
  }

  componentDidMount() {
    this.getProductCategories();
    this.getSectorCategories();
  }

  // fetch product category list

  getProductCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.PRODUCT_CATEGORY_LIST)
      .then((response) => {
        let productCategoryList = [];

        if (response.data.length > 0) {
          productCategoryList = [...response.data];
        } else {
          productCategoryList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const productCategoryOptions = productCategoryList.map(
          (ecosystem, index) => {
            const { code, productCategoryName } = ecosystem;
            return (
              <option key={index} id={code} value={code}>
                {productCategoryName}
              </option>
            );
          }
        );

        this.setState({
          productCategoryOptions: productCategoryOptions,
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductCategoryInterface.js",
          },
        });
      });
  };
  getSectorCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.CATEGORY_LIST)
      .then((response) => {
        let categoryList = [];

        if (response.data.length > 0) {
          categoryList = [...response.data];
        } else {
          categoryList = [
            {
              category_id: 0,
              category_name: "No Categories Yet",
              category_slug: "No Categories Yet",
            },
          ];
        }
        const sectorCategoryOptions = categoryList.map((ecosystem, index) => {
          const { category_id, category_name, category_slug } = ecosystem;
          return (
            <option key={index} id={category_id} value={category_slug}>
              {category_name}
            </option>
          );
        });
        this.setState({
          sectorCategoryOptions: sectorCategoryOptions,
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductCategoryInterface.js",
          },
        });
      });
  };

  toggle = () => {
    this.setState({ showForm: true, showCatergory: false });
  };
  /**Handles Changes in all Dropdown fields*/
  handleOptionSelect = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  // Open modal
  modalHandler = (modalData) => {
    this.props.showModal(modalData);
  };
  render() {
    return (
      <div className="card">
        <div className="body mid-right ">
          <h3 className="m-t-0">Product Category Sector</h3>
          <p className="settings-block text-right">
            <button
              type="submit"
              className="zoom-element btn login-btn"
              data-form="system"
              onClick={() => this.modalHandler({ modalIdentifier: `product` })}
            >
              <i className="fas fa-plus" />
              &nbsp; Add Product Category To Sector
            </button>
          </p>

          <hr />
          {/*show product category sector table */}

          <ProductSector modalHandler={this.modalHandler} />
        </div>
      </div>
    );
  }
}
function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, { showModal })(
  ProductCategoryInterface
);
