import React from "react";
import PreviewLoan from "./PreviewLoan/PreviewLoan";
import LoanSuccessModal from "./PreviewLoan/LoanSuccessModal";
import BatchRepayLoan from "./PreviewLoan/BatchRepayLoan";
import InitiateBatchRepayment from "./PreviewLoan/InitiateBatchRepayment";
import EditBatchRepayment from "./PreviewLoan/EditBatchRepayment";
// Select Action
const BatchLoanRepayment = ({ properties, onCloseModal }) => {
  const { action } = properties;
  return (
    <div
      className="Modal__dialog animated lg-modal slideInDown"
      role="document"
    >
      {(() => {
        switch (action) {
          case "previewloan":
            return (
              <PreviewLoan
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "loansuccess":
            return (
              <LoanSuccessModal
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "loanrepayment":
            return (
              <BatchRepayLoan
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "initiatebatchrepayment":
            return (
              <InitiateBatchRepayment
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );
          case "editbatchrepayment":
            return (
              <EditBatchRepayment
                properties={properties}
                onCloseModal={onCloseModal}
              />
            );

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default BatchLoanRepayment;
