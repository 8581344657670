import React, { Component } from "react";
// Ajax Methods
import ajaxMethods from "../../../../api/ajax-methods";
// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
// Redux
import { connect } from "react-redux";
// UI Components
import Tabs from "../../../../components/Tabs/Tabs";
import AllLoansTiedToABorrower from "./AllLoansTiedToABorrower";
import ViewRolledOverLoans from "../ViewRolledOverLoans/ViewRolledOverLoans";
import AllBorrowerLoansRepayments from "./AllBorrowerLoansRepayments";
import { accurateQuantifier } from "./../../../../helpers/accurateQuantifier";
import {
  BalanceDetails,
  IdentityDetails,
  IncomeDetails,
  StatementDetails,
} from "../View/viewOkraDetails";
import CustomerWallet from "../CustomerWallet/CustomerWallet";
import { appInsights } from "../../../../config/appInsights";
import CustomerBankStatementData from "../../../../components/Settings/components/CustomerBankStatementsData/CustomerBankStatementData";
import CustomerStockData from "../../../../components/Settings/components/CustomerStockData/CustomerStockData";
import CustomerOrderData from "../../../../components/Settings/components/CustomerOrdersData/CustomerOrderData";

class Details extends Component {
  state = {
    scene: "form",
    formScene: "account_details",
    borrower_identity: {
      name: "",
      address: "",
      gender: "",
      bvn: "",
    },
    borrower_bank_balance: {
      available_balance: "",
      currency: "",
      account_name: "",
      ledger_balance: "",
      last_updated: "",
    },
    borrower_full_statement: [],
    borrower_income: {
      last_updated: "",
      max_income_streams: "",
      last_year_income: "",
      number_of_income_streams: "",
      projected_yearly_income: "",
      avg_monthly_income: "",
      income_type: "",
      confidence: "",
    },
    tabs: [
      {
        name: "Account",
        id: "account_details",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: "Aggregator",
        id: "aggregator_details",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: "Borrower",
        id: "borrower_details",
        icon: "fas fa-hourglass-start",
        identifier: "loanInfoTab",
      },
      {
        name: "Loan",
        id: "loan_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "All Loans",
        id: "all_loans_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Customer Wallet",
        id: "customer_wallet_details",
        icon: "fas fa-wallet",
        identifier: "loanInfoTab",
      },
      {
        name: "Roll Over Loans",
        id: "rolled_over_loans",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "All Repayments",
        id: "all_loans_repayment_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Bank Statements",
        id: "bank_statement_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Stock Data",
        id: "stock_data_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Orders",
        id: "orders_data_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },

      {
        name: "Okra KYC Identity",
        id: "identity_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Okra KYC  Balance",
        id: "balance_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Okra KYC Statement",
        id: "statement_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Okra KYC Income",
        id: "income_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
    ],
  };

  componentDidMount = () => {
    this.getAllOkraDetails();
  };

  getAllOkraDetails = () => {
    let customer_id = "";

    this.props.records.find((record) => {
      if (
        parseInt(record.loan_id) === parseInt(this.props.dataRecord.loan_id)
      ) {
        if (record.okra_details) {
          return (customer_id = record.okra_details.okra_customer_id);
        }
      }
    });
    this.getBorrowerIdentity(`${customer_id}`);
    this.getBorrowerIncome(`${customer_id}`);
    this.getBorrowerBalance(`${customer_id}`);
    this.getBorrowerTransactions(`${customer_id}`);
  };

  getBorrowerIdentity = (queryString) => {
    ajaxMethods
      .get(ajaxEndpoints.B0RR0WER_IDENTITY + queryString)
      .then((response) => {
        if (response) {
          let identity_res = response.data.data.identity;
          this.setState({
            borrower_identity: {
              ...this.state.borrower_identity,
              address: identity_res[0].address[0],
              bvn: identity_res[0].bvn,
              name: identity_res[0].fullname,
              gender: identity_res[0].gender,
            },
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Details.js",
          },
        });
      });
  };

  getBorrowerIncome = (queryString) => {
    ajaxMethods
      .get(ajaxEndpoints.B0RR0WER_INCOME + queryString)
      .then((res) => {
        if (res) {
          let response = res.data.data;
          let streams = response.income[0].streams[0];
          this.setState({
            borrower_income: {
              ...this.state.borrower_income,
              last_updated: response.income[0].last_updated,
              max_number_of_overlapping_income_streams:
                response.income[0].max_number_of_overlapping_income_streams,
              last_year_income: response.income[0].last_year_income,
              number_of_income_streams:
                response.income[0].number_of_income_streams,
              projected_yearly_income:
                response.income[0].projected_yearly_income,
              avg_monthly_income: streams.avg_monthly_income,
              income_type: streams.income_type,
              confidence: response.income[0].confidence,
            },
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Details.js",
          },
        });
      });
  };

  getBorrowerBalance = (queryString) => {
    ajaxMethods
      .get(ajaxEndpoints.B0RR0WER_BALANCE + queryString)
      .then((response) => {
        if (response) {
          let balance = response.data.data.balance[0];
          this.setState({
            borrower_bank_balance: {
              ...this.state.borrower_bank_balance,
              available_balance: balance.available_balance,
              currency: balance.currency,
              account_name: balance.customer.name,
              ledger_balance: balance.ledger_balance,
              last_updated: balance.last_updated,
            },
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Details.js",
          },
        });
      });
  };

  getBorrowerTransactions = (queryString) => {
    ajaxMethods
      .get(ajaxEndpoints.B0RR0WER_TRANSACTIONS + queryString)
      .then((response) => {
        if (response) {
          this.setState({
            borrower_full_statement: response.data.data.transaction,
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Details.js",
          },
        });
      });
  };

  // Toggle Tab & Tab content for Data Table
  toggleTabContent = (content) => {
    this.setState({
      formScene: content,
    });
  };

  render() {
    // Get data from component state
    const {
      scene,
      tabs,
      formScene,
      borrower_identity,
      borrower_bank_balance,
      borrower_full_statement,
      borrower_income,
    } = this.state;
    // Get data from component props
    const { dataRecord } = this.props;
    const {
      aggregator_bank_details,
      aggregator_details,
      // is_active,
      loan_amount,
      loan_amount_repay,
      loan_id,
      // loan_interest,
      loan_ref,
      // loan_repayment_balance,
      loan_tenure,
      // modified_date,
      // okra_details,
      pub_date,
      settlement_day,
      credit_bank_details,
      debit_bank_details,
      is_rollover,
      rollover_loan_reference,
      parent_rollover_loan_reference,
    } = this.props.records.find((record) => {
      return parseInt(record.loan_id) === parseInt(dataRecord.loan_id);
    });
    return (
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1">
            Loan ID: <span>{loan_id}</span>
          </h3>
          {/* Partner Form */}
          {(() => {
            switch (scene) {
              case "form":
                return (
                  <form>
                    <div className="row m-b-1">
                      <Tabs
                        onContentToggle={this.toggleTabContent}
                        tabs={tabs}
                      />
                    </div>
                    {(() => {
                      switch (formScene) {
                        case "account_details":
                          return (
                            <AccountDetails
                              details={{
                                debit_bank_account_num:
                                  debit_bank_details?.debit_bank_account_num,
                                debit_bank_account_name:
                                  debit_bank_details?.debit_bank_account_name,
                                debit_bank_name:
                                  debit_bank_details?.debit_bank_name,
                                debit_bank_code:
                                  debit_bank_details?.debit_bank_code,
                                credit_bank_account_num:
                                  credit_bank_details?.credit_bank_account_num,
                                credit_bank_account_name:
                                  credit_bank_details?.credit_bank_account_name,
                                credit_bank_name:
                                  credit_bank_details?.credit_bank_name,
                                credit_bank_code:
                                  credit_bank_details?.credit_bank_code,
                              }}
                            />
                          );
                        case "aggregator_details":
                          return (
                            <AggregatorDetails
                              details={{
                                aggregator_bank_details,
                                aggregator_details,
                              }}
                            />
                          );

                        case "borrower_details":
                          return <BorrowerDetails details={dataRecord} />;

                        case "loan_details":
                          return (
                            <LoanDetails
                              details={{
                                loan_amount,
                                loan_amount_repay,
                                loan_ref,
                                loan_tenure,
                                pub_date,
                                settlement_day,
                                is_rollover,
                                rollover_loan_reference,
                                parent_rollover_loan_reference,
                              }}
                            />
                          );

                        case "all_loans_details":
                          return (
                            <AllLoansTiedToABorrower
                              dataRecord={dataRecord.borrower_details}
                            />
                          );
                        case "customer_wallet_details":
                          return (
                            <CustomerWallet
                              dataRecord={dataRecord.borrower_details}
                            />
                          );
                        case "rolled_over_loans":
                          return (
                            <ViewRolledOverLoans dataRecord={dataRecord} />
                          );

                        case "all_loans_repayment_details":
                          return (
                            <AllBorrowerLoansRepayments
                              dataRecord={dataRecord.borrower_details}
                            />
                          );

                        case "bank_statement_details":
                          return (
                            <CustomerBankStatementData
                              customerId={
                                dataRecord?.borrower_details?.customer_id
                              }
                            />
                          );
                        case "stock_data_details":
                          return (
                            <CustomerStockData
                              customerId={
                                dataRecord?.borrower_details?.customer_id
                              }
                            />
                          );
                        case "orders_data_details":
                          return (
                            <CustomerOrderData
                              customerId={
                                dataRecord?.borrower_details?.customer_id
                              }
                            />
                          );

                        case "identity_details":
                          return (
                            <IdentityDetails details={{ borrower_identity }} />
                          );

                        case "balance_details":
                          return (
                            <BalanceDetails
                              details={{ borrower_bank_balance }}
                            />
                          );

                        case "statement_details":
                          return (
                            <StatementDetails
                              details={borrower_full_statement}
                            />
                          );

                        case "income_details":
                          return (
                            <IncomeDetails details={{ borrower_income }} />
                          );
                        default:
                          return null;
                      }
                    })()}
                    <div className="Form__action-buttons m-t-2">
                      {/* <button type="button"
                                                    className="btn zoom-element btn login-btn m-0 mr-auto"
                                                    onClick={this.printHandler}>
                                                    <i className="fas fa-print"></i>
                                                    &nbsp; Print
                                                </button> */}
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn"
                        onClick={this.props.onCloseModal}
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i>
                        &nbsp; Close
                      </button>
                    </div>
                  </form>
                );

              case "loader":
                return (
                  <div>
                    {/* 2. login Form Loader */}
                    <div
                      id="adv-login-loader"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-circle" />
                            &nbsp; Processing your Partner
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "success":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Logged In Successfully
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "fail":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Login Failed, try again later
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  }
}

// Aggregator Details
const AggregatorDetails = (props) => {
  const {
    aggregator_bank_details,
    aggregator_details: {
      // biz_email,
      biz_name,
      // biz_phone
    },
  } = props.details;

  return (
    <div className="m-b-2">
      <label className="label-txt">Name</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={biz_name || "Unavailable"}
        />
        <i className="far fa-building"></i>
      </div>
      <label className="label-txt">Bank Name</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          className="user__form form-control"
          readOnly
          value={
            aggregator_bank_details?.length > 0
              ? aggregator_bank_details[0]?.bank_name
              : "Unavailable"
          }
        />
        <i className="fas fa-university" />
      </div>
      <label className="label-txt">Account Name</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={
            aggregator_bank_details?.length > 0
              ? aggregator_bank_details[0]?.bank_account_name
              : "Unavailable"
          }
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Account Number</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={
            aggregator_bank_details?.length > 0
              ? aggregator_bank_details[0]?.bank_account_num
              : "Unavailable"
          }
        />
        <i className="far fa-money-bill-alt" />
      </div>
    </div>
  );
};

// Account Details
const AccountDetails = (props) => {
  const {
    debit_bank_account_num,
    debit_bank_account_name,
    debit_bank_name,
    debit_bank_code,
    credit_bank_account_num,
    credit_bank_account_name,
    credit_bank_name,
    credit_bank_code,
  } = props.details;

  return (
    <div className="m-b-2">
      <h5>DEBIT ACCOUNT</h5>
      <label className="label-txt m-t-1">Bank Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_name || "Unavailable"}
        />
        <i className="fa fa-bank" />
      </div>
      <label className="label-txt">Account Name To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_account_name || "Unavailable"}
        />
        <i className="fas fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_account_num || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Bank Code To Be Debited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={debit_bank_code || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <br />
      <h5>CREDIT ACCOUNT</h5>

      <label className="label-txt m-t-1">Bank Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={credit_bank_name || "Unavailable"}
        />
        <i className="fa fa-bank " />
      </div>
      <label className="label-txt">Account Name To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={credit_bank_account_name || "Unavailable"}
        />
        <i className="fa fa-user " />
      </div>
      <label className="label-txt">Account Number To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={credit_bank_account_num || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
      <label className="label-txt">Bank Code To Be Credited</label>
      <div className="form-group inputWithIcon">
        <input
          type="text"
          readOnly
          className="user__form form-control"
          value={credit_bank_code || "Unavailable"}
        />
        <i className="far fa-envelope" />
      </div>
    </div>
  );
};

// Borrower Details
const BorrowerDetails = (props) => {
  const {
    crc_score,
    crc_status,
    xds_score,
    xds_status,
    sf_score,
    sf_status,
    disbursement_decision,
    borrower_details,
  } = props.details;
  const {
    borrower_id,
    first_name,
    last_name,
    personal_phone,
    gender,
    request_dob,
    request_firstname,
    request_lastname,
    request_phone,
    request_gender,
    kyc_score,
    my_biz_name,
    my_biz_rc_num,
    customer_category,
  } = borrower_details;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Borrower ID</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={borrower_id || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Full Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={`${first_name} ${last_name}` || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Phone Number</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={personal_phone || "Unavailable"}
            />
            <i className="fas fa-phone" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Gender</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={gender}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Customer Category</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={customer_category || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Company Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={my_biz_name || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">RC Number</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={my_biz_rc_num || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">KYC Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={kyc_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">KYC Request Full Name</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={
                request_firstname || request_lastname
                  ? `${request_firstname} ${request_lastname}`
                  : "Unavailable"
              }
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">KYC Request DOB</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_dob ? request_dob.substring(0, 10) : "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">KYC Request Gender</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_gender || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">KYC Request Phone Number</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_phone || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">CRC Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={crc_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">CRC Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={crc_status || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">First Central Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={xds_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">First Central Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={xds_status || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Superfluid Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={sf_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Superfluid Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={sf_status || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Consolidated Score Decision</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={disbursement_decision || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Loan Details
const LoanDetails = (props) => {
  const {
    loan_amount,
    loan_amount_repay,
    loan_ref,
    loan_tenure,
    pub_date,
    settlement_day,
    is_rollover,
    rollover_loan_reference,
    parent_rollover_loan_reference,
  } = props.details;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Loan Reference</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={loan_ref || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Loan Tenure</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={accurateQuantifier(loan_tenure, "day")}
            />
            <i className="fas fa-hourglass-start" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Loan Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={loan_amount || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Repayment Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={loan_amount_repay || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Date Created</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={new Date(pub_date)}
            />
            <i className="far fa-calendar-alt" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Settlement Date</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={settlement_day}
            />
            <i className="fas fa-calendar-alt" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-4">
          <label className="label-txt">Rollover Status</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={is_rollover}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <label className="label-txt">Previous Loan Ref</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={parent_rollover_loan_reference}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <label className="label-txt">Rollover Loan Ref</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={rollover_loan_reference}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    disbursements: { records },
  } = state.loansReducer;
  return {
    records,
  };
};

export default connect(mapStateToProps)(Details);
