import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getAggregatorOfferLetterAndSla = async (aggregator_id) => {
  const { data } = await getData(
    `${ajaxEndpoints.AGGREGATOR_OFFERLETER_SLA}${aggregator_id}`
  );
  return data;
};

export default function useAggregatorOfferLetterAndSLA(aggregator_id) {
  return useQuery(
    [aggregator_id, "getAggregatorOfferLetterAndSla"],
    getAggregatorOfferLetterAndSla
  );
}
