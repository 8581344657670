import React from "react";
import { enforceMaxLength } from "./../../helpers/enforceMaxLength";
import ErrorHandler from "./../../components/ErrorHandler/ErrorHandler.component";

export default function CustomTextArea({
  name = "",
  placeholder = "",
  reference = null,
  label = "",
  errors = "",
  maxLength = "",
  max = "",
  defaultValue = "",
  minLength = "",
  onChange,
  showRequiredIcon = false,
  extraLabel = false,
  rows = "",
  bottomLabel = "",
  readOnly = false,
  otherLabel = "",
}) {
  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <label className="form-label">{label}</label>
        {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
        {extraLabel && extraLabel.length && (
          <label className="form-label ml-2">
            <small className="crimson">{extraLabel}</small>
          </label>
        )}
        {otherLabel && otherLabel.length && (
          <label className="form-label ml-2">
            <small className="dateText">{otherLabel}</small>
          </label>
        )}
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          name={name}
          max={max}
          placeholder={placeholder}
          ref={reference}
          onKeyPress={enforceMaxLength}
          maxLength={maxLength}
          minLength={minLength}
          defaultValue={defaultValue}
          onChange={onChange}
          rows={rows ? rows : 3}
          readOnly={readOnly}
        />
        {bottomLabel && bottomLabel.length && !errors && (
          <small>{bottomLabel}</small>
        )}
        <ErrorHandler errors={errors} />
      </div>
    </div>
  );
}
