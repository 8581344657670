import { Fragment, useState } from "react";
import Paginator from "../../Pagination/Pagination.component";
import { Loader } from "../../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../../../NewComponents/TypedComponents/CustomHTMLElements/CustomSelectDropdown";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { useQueryCache } from "react-query";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../../redux/actions/modal";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import usePendingOfflineRequests from "../../../custom-hooks/usePendingOfflineRequests";
import { Link } from "react-router-dom";
import { formatMoney } from "../../../helpers/formatter";
import { appInsights } from "../../../config/appInsights";

const OfflineRequests = (props: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [submitError, setSubmitError] = useState("");

  const queryCache = useQueryCache();
  const { data, status, error } = usePendingOfflineRequests();

  const handleSubmit = async ({
    comment,
    reference,
  }: {
    comment: string;
    reference: string;
  }) => {
    setLoading(true);
    props.hideModal();
    const reqBoday = {
      reference,
      comment,
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.APPROVE_OFFLINE_REQUEST}`,
        reqBoday
      );
      setLoading(false);
      setSuccessMessage(response.message);
      queryCache.invalidateQueries(["getPendingOfflineRequests"]);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "OfflineRequests.tsx",
        },
      });
      setLoading(false);
      setSubmitError(errorHandler(error));
      queryCache.invalidateQueries(["getPendingOfflineRequests"]);
    }
  };
  const handleReject = async ({
    comment,
    reference,
  }: {
    comment: string;
    reference: string;
  }) => {
    setLoading(true);
    props.hideModal();
    const reqBoday = {
      reference,
      comment,
    };
    try {
      const response = await postDataWithDotNet(
        `${ajaxEndpoints.REJECT_REQUEST}`,
        reqBoday
      );
      setLoading(false);
      setSuccessMessage(response.message);
      queryCache.invalidateQueries(["getPendingOfflineRequests"]);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "OfflineRequests.tsx",
        },
      });
      setLoading(false);
      setSubmitError(errorHandler(error));
      queryCache.invalidateQueries(["getPendingOfflineRequests"]);
    }
  };
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Offline Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading offline requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              pending offline requests, Please Reload The Page
            </h4>
          </div>
        </div>
      );
    } else if ((data && data.length === 0) || !data) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              offline requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Investment Fund</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Bank Name</th>
                  <th>Account Name</th>
                  <th>Account Number</th>
                  <th>Amount</th>
                  <th>Tenor</th>
                  <th>Phone Number</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data
                    .filter(
                      (eachData: any) =>
                        (eachData.firstname &&
                          eachData.firstname
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.investment_product_name &&
                          eachData.investment_product_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.lastname &&
                          eachData.lastname
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.bank_account_name &&
                          eachData.bank_account_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.bank_account_name &&
                          eachData.bank_account_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.bank_name &&
                          eachData.bank_name
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.phone_number &&
                          eachData.phone_number
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())) ||
                        (eachData.amount &&
                          eachData.amount
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()))
                    )
                    .slice(
                      (pageNumber - 1) * size,
                      size * (pageNumber - 1) + size
                    )
                    .map((request: any, _id: number) => {
                      const {
                        reference,
                        firstname,
                        lastname,
                        email,
                        bank_name,
                        bank_account_name,
                        bank_account_number,
                        phone_number,
                        funder_id,
                        investment_product_name,
                        principal_amount,
                        currency,
                        tenure,
                        cba_wallet_account_id,
                        amount_to_credit,
                      } = request;
                      return (
                        <tr key={_id}>
                          <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                          <td>{investment_product_name}</td>
                          <td>
                            {firstname} {lastname}
                          </td>
                          {/* <td>{pubDate?.substring(0, 10)}</td> */}
                          <td>{email}</td>
                          <td>{bank_name}</td>
                          <td>{bank_account_name}</td>
                          <td>{bank_account_number}</td>
                          <td>{formatMoney(principal_amount, currency)}</td>
                          <td>{tenure} days</td>
                          <td>{phone_number}</td>
                          <td className="flex-row">
                            <button
                              className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                              type="button"
                              data-toggle="modal"
                              data-target="#requestModal"
                              onClick={() => {
                                console.log({
                                  request,
                                });
                                props.showModal({
                                  modalIdentifier: "requests",
                                  action: "offlinerequest",
                                  reference: reference,
                                  wallet_id: cba_wallet_account_id,
                                  funder_id,
                                  loading,
                                  amount_to_credit,
                                  handleSubmit,
                                  handleReject,
                                  currency,
                                });
                              }}
                            >
                              Request
                            </button>
                            <button className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2">
                              <Link
                                to={{
                                  pathname: `/requests/${funder_id}`,
                                  state: {
                                    data: request,
                                  },
                                }}
                                className=""
                                style={{ color: "#713fff" }}
                              >
                                View Details
                              </Link>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  if (loading) {
    RenderedComponent = (
      <Loader centered={true} text="Loading pending offline requests..." />
    );
  }
  return (
    <Fragment>
      <div>
        {successMessage && <Alert message={successMessage} type="success" />}
        {submitError && <Alert message={submitError} />}
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            {status === "success" && (
              <span>
                You have{" "}
                <span className="font-weight-bold">
                  {data?.length ? data?.length : "No"} pending
                </span>{" "}
                requests
              </span>
            )}
          </div>
          <div className="d-flex flex-wrap justify-content-end align-items-center">
            <div
              className="input-group mr-3 mt-3"
              style={{ maxWidth: "200px" }}
            >
              <input
                type="search"
                className="form-control"
                placeholder="Search..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div style={{ width: "150px", marginTop: "10px" }}>
              <CustomSelectDropdown
                onChange={(e: any) => {
                  setSize(e.target.value);
                }}
                value={size}
              >
                <option value={10000}>All</option>
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
        </div>
        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={size}
          page={pageNumber}
          count={data ? data.length : 0}
          changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
        />
      </div>
    </Fragment>
  );
};
export default connect(null, { showModal, hideModal })(OfflineRequests);
