import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { DownloadUploadedDocument } from "./../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import CustomBadge from "../../Badge/badge";
import OnBoardingDocumentsActionModal from "./Components/DocumentsApprovalModal";
import { handleDisableDocumentAction, isProceedDisabled } from "./helper";
import { documentApprovalStatus, onBoardingStatusCode } from "../util";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { putData } from "../../../newApis/dotNetApiMethods";
import { showModal } from "../../../redux/actions/modal";
import { ReactComponent as MessageIcon } from "../../../assets/svg/icons/message-icon-2.svg";
import { ROLE_TYPES } from "../../../helpers/roleTypes";
import useDocumentViewer from "../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";

import styles from "../AggregatorOnboardingReview.module.scss";

export default function AggregatorOnboardingReviewDownloadDocument({
  uploadedDocuments,
  filterOptions,
  handleChange,
  activeTab,
  refetch,
  environmentData,
}) {
  const [actionData, setActionData] = useState({});
  const [proceedToCreditLoader, setProceedToCreditLoader] = useState(false);

  const { handleViewDocument } = useDocumentViewer();

  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = match.params;

  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);

  const handleViewComments = (e, file) => {
    dispatch(
      showModal({
        modalIdentifier: "uploadeddoccomments",
        dataRecord: {
          comments: file?.comments,
          document_file_id: file?.aggregator_document_id,
        },
      })
    );
  };

  const handleEnableProceedToCredit = (uploadedDocuments) => {
    // This method (isProceedDisabled) checks that all documents have been approved before
    // allowing admin to proceed to credit
    let result = isProceedDisabled(uploadedDocuments);
    if (
      result === false &&
      loggedInUser?.roles?.includes(ROLE_TYPES.LEGAL_ONBOARDING_APPROVER)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleProceed = async () => {
    setProceedToCreditLoader(true);
    try {
      const response = await putData(
        `${ajaxEndpoints.AGGREGATOR_DOCUMENT_UPLOAD}${id}/submit`
      );
      if (response?.data?.status_code === 200) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        setTimeout(() => {
          history.goBack();
        }, 3000);
      } else {
        dispatch(
          showModal({
            modalIndentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          modalIndentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setProceedToCreditLoader(false);
  };

  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <div className="w-100">
          <div className={styles.filterContainer}>
            <div className={`form-group ${styles.filterItem}`}>
              <select
                className={styles.FilterInput}
                onChange={handleChange}
                name="productType"
              >
                {filterOptions?.map((type, index) => (
                  <option key={index} value={type?.value}>
                    {type?.name}
                  </option>
                ))}
              </select>
              <i className={`fas fa-solid fa-angle-down ${styles.Icon}`}></i>
            </div>
          </div>
          <div className={styles.uploadedDocsContainer}>
            {uploadedDocuments && uploadedDocuments.length ? (
              <div>
                {uploadedDocuments.map((uploadedDocument, _id) => (
                  <div className="row mb-5" key={_id}>
                    <div className="col-12 d-flex justify-content-between">
                      <label className="form-label">
                        {_id + 1}. {uploadedDocument.document_field_Name}
                      </label>
                    </div>
                    <div className="col-12 d-flex">
                      <div>
                        {uploadedDocument.files &&
                          uploadedDocument.files.map((item) => (
                            <div className="d-flex" key={uuidv4()}>
                              <div
                                className={styles.downloadUploadDocContainer}
                              >
                                <DownloadUploadedDocument
                                  name={item.file_name}
                                  pathname={item.upload_path}
                                  handleView={() =>
                                    handleViewDocument(false, item?.upload_path)
                                  }
                                />
                              </div>

                              <div className="d-flex ml-5">
                                <div className="d-flex">
                                  {item.approval_status ===
                                    documentApprovalStatus.DECLINED && (
                                    <button
                                      className="btn advancly-red-btn btn-md"
                                      disabled
                                    >
                                      Declined
                                    </button>
                                  )}
                                  {item.approval_status ===
                                    documentApprovalStatus.APPROVED && (
                                    <button
                                      className="btn advancly-green-btn btn-md"
                                      disabled
                                    >
                                      Approved
                                    </button>
                                  )}
                                  {(item.approval_status ===
                                    documentApprovalStatus.APPROVED ||
                                    item.approval_status ===
                                      documentApprovalStatus.DECLINED) && (
                                    <div
                                      className={styles.commentsAction}
                                      onClick={(e) =>
                                        handleViewComments(e, item)
                                      }
                                    >
                                      <MessageIcon
                                        className={styles.messageIcon}
                                      />
                                      <span className={styles.commentText}>
                                        Comment
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {item.approval_status ===
                                  documentApprovalStatus.PENDING &&
                                  !(
                                    loggedInUser?.roles.includes(
                                      ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER
                                    ) &&
                                    loggedInUser?.roles.includes(
                                      ROLE_TYPES.INVESTMENT_VIEWER
                                    ) &&
                                    loggedInUser?.roles.includes(
                                      ROLE_TYPES.CREDIT_VIEWER
                                    ) &&
                                    loggedInUser?.roles.includes(
                                      ROLE_TYPES.AGGREGATOR_VIEWER
                                    )
                                  ) && (
                                    <>
                                      <button
                                        className="btn advancly-btn btn-md"
                                        data-toggle="modal"
                                        data-target="#onBoardingDocumentsActionModal"
                                        disabled={handleDisableDocumentAction(
                                          activeTab,
                                          loggedInUser,
                                          environmentData
                                        )}
                                        onClick={() =>
                                          setActionData({
                                            actionType: "approve",
                                            data: item,
                                          })
                                        }
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn advancly-off-2-btn btn-md ml-3"
                                        data-toggle="modal"
                                        data-target="#onBoardingDocumentsActionModal"
                                        disabled={handleDisableDocumentAction(
                                          activeTab,
                                          loggedInUser,
                                          environmentData
                                        )}
                                        onClick={() =>
                                          setActionData({
                                            actionType: "decline",
                                            data: item,
                                          })
                                        }
                                      >
                                        Decline
                                      </button>
                                    </>
                                  )}
                                <span className={styles.documentTimeStamp}>
                                  {moment(item?.uploaded_at).format("lll")}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div>
                      {uploadedDocument?.productTypes &&
                        uploadedDocument.productTypes.map((item, index) => (
                          <CustomBadge key={index} text={item?.product} />
                        ))}
                    </div>
                  </div>
                ))}
                <div className="d-flex">
                  {activeTab === "legal-compliance" &&
                    (environmentData?.statusCode ===
                      onBoardingStatusCode.LEGAL_IN_PROGRESS ||
                      environmentData?.subsequentStatusCode ===
                        onBoardingStatusCode.LEGAL_IN_PROGRESS) && (
                      <button
                        className="btn advancly-btn btn-md"
                        disabled={handleEnableProceedToCredit(
                          uploadedDocuments
                        )}
                        onClick={handleProceed}
                      >
                        {proceedToCreditLoader
                          ? "Loading..."
                          : "Proceed To Credit"}
                      </button>
                    )}
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex align-items-center justify-content-center py-3">
                <h3 className="font-weight-bold">
                  Aggregator is yet to upload any document
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <OnBoardingDocumentsActionModal data={actionData} refetchData={refetch} />
    </div>
  );
}
