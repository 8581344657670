import React, { useState, Fragment } from "react";
import EligibilityTestStepOne from "./EligibilityTestStepOne";
import EligibilityTestStepTwo from "./EligibilityTestStepTwo";
import styles from "../AggregatorOnboardingReview.module.scss";
import { ReactComponent as ValidCircle } from "../../../assets/svg/icons/checkbox-circle-fill.svg";
import { ReactComponent as InvalidCircle } from "../../../assets/svg/icons/close-circle-line.svg";

export default function AggregatorOnboardingReviewEligibilityTest({
  eligibilityInfo,
  weightedScore,
  maximumPilotAmount,
  handleChange,
  filterOptions,
}) {
  const [currentStep, setCurrentStep] = useState(1);
  const validWeightedScore = weightedScore >= 50;

  return (
    <div className="onboardingOuterLayout">
      <div className="d-flex justify-content-between">
        <div className={`${styles.profileAreaCard} py-3`}>
          <div className={styles.title}>Weighted Score:</div>
          <div className="d-flex align-items-center mt-2">
            <h2 className="font-weight-bold pr-4">{weightedScore}%</h2>
            <span
              className={`${styles.checkbox} ${
                !validWeightedScore && styles.fail
              }`}
            >
              {validWeightedScore ? <ValidCircle /> : <InvalidCircle />}
            </span>
          </div>
        </div>
        <div className={`${styles.profileAreaCard} py-3`}>
          <div className={styles.title}>Maximum Pilot Amount:</div>
          <h2 className="font-weight-bold pr-4">{maximumPilotAmount}</h2>
        </div>
      </div>
      <hr />
      <div className={styles.filterContainer}>
        <div className={`form-group ${styles.filterItem}`}>
          <select
            className={styles.FilterInput}
            onChange={handleChange}
            name="productType"
          >
            {filterOptions?.map((type, index) => (
              <option key={index} value={type?.value}>
                {type?.name}
              </option>
            ))}
          </select>
          <i className={`fas fa-solid fa-angle-down ${styles.Icon}`}></i>
        </div>
      </div>
      <div className="onboardingInnerLayout tiny">
        <div className="w-100">
          <Fragment>
            {(() => {
              switch (currentStep) {
                case 1:
                  return (
                    <EligibilityTestStepOne
                      setCurrentStep={setCurrentStep}
                      currentStep={currentStep}
                      eligibilityInfo={eligibilityInfo}
                    />
                  );
                case 2:
                  return (
                    <EligibilityTestStepTwo
                      setCurrentStep={setCurrentStep}
                      currentStep={currentStep}
                      eligibilityInfo={eligibilityInfo}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Fragment>
        </div>
      </div>
    </div>
  );
}
