export const onBoardingStatusCode = {
    INITIATED: 0,
    ELIGIBILITY_IN_PROGRESS: 1,
    ELIGIBILITY_SUBMITTED: 2,
    DOCUMENT_UPLOAD_IN_PROGRESS: 3,
    DOCUMENT_UPLOADED_COMPLETED: 4,
    LEGAL_IN_PROGRESS: 5,
    CREDIT_IN_PROGRESS: 23,
    ONBOARDING_APPROVED: 6,
    ONBOARDING_DECLINE: 7,
    SLA_OFFER_SUBMITTED: 8,
    AGGREGATOR_ACTIVATED: 9,
  };
  
  export const documentApprovalStatus = {
    DECLINED: 3,
    APPROVED: 2,
    PENDING: 1,
  };