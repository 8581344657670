import React from "react";

export default function AggregatorOnboardingReviewAdminComment({ basicInfo }) {
  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <div className="w-100">
          <div className="row">
            <div className="card">
              <div className="col-12">
                <p>
                  <b>Comment:</b> {basicInfo?.comment}
                </p>
                <div className="d-flex">
                  <small className="pr-3">
                    <b>Admin:</b> {basicInfo?.approved_declined_by}
                  </small>
                  |
                  <small className="pl-3">
                    <b>Rejection Date:</b>{" "}
                    {basicInfo?.approved_declined_date?.substring(0, 10)}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
