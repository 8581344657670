import React from 'react';

import './Buttons.css';

export const Button = (props) => {
    return (
        <button id={props.id || "generateBtn"} 
                className={"Button " + props.className || ""}
                onClick={ props.onClick}>{ props.children}</button>
    )
}
