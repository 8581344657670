import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// UI Components
import { InfoLoader, Loader } from "../../../UI/Loaders/Loaders";
import { useState } from "react";
import Paginator from "../../Pagination/Pagination.component";
import { currencyFormatter } from "../../../helpers/stringControllers";

const Partners = (props: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  let {
    partnersTableData,
    loading,
    aggregator_id,
    getAggregatorDrilldown,
    showModal,
  } = props;

  const modalHandler = (modalData: any) => {
    showModal({
      modalIdentifier: "Partner",
      dataRecord: modalData,
      action: "form",
    });
  };

  let RenderedComponent = null;

  if (partnersTableData === undefined) {
    RenderedComponent = (
      <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
        <InfoLoader
          text="There was issue fetching Available Partners."
          subText="Please reload the page after a few seconds"
        />
      </div>
    );
  } else if (loading) {
    RenderedComponent = (
      <Loader centered={true} text="Loading available Partners ..." />
    );
  } else {
    // Verify that a issues object exists
    if (partnersTableData === undefined || partnersTableData === null) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> Problem Fetching Exisiting Issues,
              Please Reload the Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (partnersTableData.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are Currently No Partners on
              your Platform
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="grid__padding animated fadeInRight text-center">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Phone</th>
                <th>Debt Balance</th>
                <th>Loan</th>
                <th>Repaid</th>
                <th>Trust Score</th>
                <th>Status</th>
                <th>Borrower Type</th>
                <th>Customer Type</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {partnersTableData
                .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                .map((partner: any, _id: number) => {
                  const {
                    first_name,
                    last_name,
                    borrower_phone,
                    debt_balance,
                    total_loan_collected,
                    total_loan_repaid,
                    trust_score,
                    is_active,
                    borrower_type,
                    customer_category,
                  } = partner;
                  return (
                    <tr key={_id}>
                      <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                      <td>{first_name}</td>
                      <td>{last_name}</td>
                      <td>{borrower_phone}</td>
                      <td>{currencyFormatter(parseFloat(debt_balance))}</td>
                      <td>
                        {currencyFormatter(parseFloat(total_loan_collected))}
                      </td>
                      <td>
                        {currencyFormatter(parseFloat(total_loan_repaid))}
                      </td>
                      <td>{trust_score}</td>
                      <td
                        className={`btn-display ${
                          is_active ? "bg-success" : "bg-warning"
                        }`}
                      >
                        {is_active ? "Active" : "Inactive"}
                      </td>
                      <td className="text-capitalize">
                        {borrower_type && Number(borrower_type) === 1
                          ? "Individual"
                          : Number(borrower_type) === 2
                          ? "Corporate"
                          : ""}
                      </td>
                      <td>{customer_category || "unavailable"}</td>

                      <td className="d-flex flex-row">
                        <span
                          className="Partner view btn-manage zoom-element btn-slim"
                          onClick={() => {
                            modalHandler(partner);
                          }}
                        >
                          View
                        </span>
                        <span
                          className="Partner Disable btn-manage zoom-element btn-slim"
                          onClick={() => {
                            modalHandler(partner);
                          }}
                        >
                          Disable
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={size}
            page={pageNumber}
            count={partnersTableData.length}
            changeCurrentPage={(pageNumber: number) =>
              setPageNumber(pageNumber)
            }
          />
        </div>
      );
    }
  }

  return (
    <div className="Transactions datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        <div className="m-l-1 m-r-1 m-b-2 d-flex justify-content-end">
          <button
            id="create"
            data-id="create-role"
            data-action="create"
            className="m-r-1 create btn advancly-btn"
            onClick={(e) => {
              showModal({
                modalIdentifier: "Partner",
                action: "advanceSearch",
                dataRecord: aggregator_id,
              });
            }}
          >
            <i className="fas fa-search" />
            &nbsp; Advance Search
          </button>
        </div>

        {RenderedComponent}
      </div>
    </div>
  );
};

export default Partners;
