import React, { useState, ChangeEvent, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import useNotificationUserCategories from "../../../custom-hooks/useAllUserCategories";
import useNotificationMessageClass from "../../../custom-hooks/useNotificationMessageTypes";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { postData } from "../../../newApis/dotNetApiMethods";
import { showModal } from "../../../redux/actions/modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MessageType, QueryParam } from "../types";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { maximumMessageCount, maximumMessageTitleCount } from "../constants";
import { getRegisteredUsers } from "../../../custom-hooks/useRegisteredUsers";
import { appInsights } from "../../../config/appInsights";

import Styles from "./sendMessage.module.scss";

interface FormData {
  title: string;
  messageClass: number;
  userCategory: number;
  user: string;
  message: string;
  userIds: number[];
}

interface Category {
  id: number;
  name: string;
}

interface UserInfo {
  address: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  username: string;
  id: number;
}

interface SelectedUser {
  label: string;
  value: number;
}

const availableCategories = {
  ALL: 1,
  INVESTORS: 2,
  AGGREGATORS: 3,
  SPECIFIC_INVESTOR: 4,
  SPECIFIC_AGGREGATOR: 5,
};

const schema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter message title")
    .max(maximumMessageTitleCount),
  messageClass: yup.string().required("Please select a message type"),
  userCategory: yup.string().required("Please select user category"),
  // user: yup.string().when("userCategory", {
  //   is: (value) => {
  //     return (
  //       Number(value) === availableCategories?.SPECIFIC_AGGREGATOR ||
  //       Number(value) === availableCategories?.SPECIFIC_INVESTOR
  //     );
  //   },
  //   then: yup.string().required("Please select user"),
  // }),
  message: yup
    .string()
    .required("Please enter message")
    .max(maximumMessageCount, "Message must not exceed 150 characters"),
});

const SendMessage = () => {
  const [isShowIndividuals, setIsShowIndividuals] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
  const [titleCount, setTitleCount] = useState<number>(0);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [queryParams, setQueryParams] = useState<QueryParam>({});
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const { data: userCategories } = useNotificationUserCategories();
  const { data: messageTypes } = useNotificationMessageClass();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Send Message - (sendMessage.tsx)",
      isLoggedIn: true,
    });
  }, []);

  function getOptions(name: string) {
    const searchParam = {
      Name: name,
    };

    return new Promise((resolve, reject) => {
      getRegisteredUsers(searchParam)
        .then((data) => {
          resolve(
            data?.data.map(({ firstName, lastName, id }: UserInfo) => ({
              value: id,
              label: `${firstName} ${lastName}`,
            }))
          );
        })
        .catch(reject);
    });
  }

  const handleDropdownChange = (selectedUsers: SelectedUser[]) => {
    setSelectedUsers(selectedUsers);
  };

  const onSubmit = async (formData: FormData) => {
    const userIds = selectedUsers.map((item) => item?.value);

    formData.userIds = userIds;
    formData.messageClass = Number(formData.messageClass);
    formData.userCategory = Number(formData.userCategory);

    setIsSubmitting(true);
    try {
      const response = await postData(ajaxEndpoints.POST_MESSAGE, formData);
      if (response?.data?.status === true) {
        reset();
        setIsShowIndividuals(false);
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
      } else {
        dispatch(
          showModal({
            modalIndentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "sendMessage.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Server Error",
          },
        })
      );
    }
    setIsSubmitting(false);
    setTitleCount(0);
    setMessageCount(0);
  };

  const handleChangeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value;

    if (Number(category) === availableCategories?.SPECIFIC_AGGREGATOR) {
      setQueryParams((prev) => {
        return {
          ...prev,
          UserType: "2", // user type 2 is for aggregator
        };
      });
      setIsShowIndividuals(true);
    } else if (Number(category) === availableCategories?.SPECIFIC_INVESTOR) {
      setQueryParams((prev) => {
        return {
          ...prev,
          UserType: "1", // user type 1 is for investors
        };
      });
      setIsShowIndividuals(true);
    } else {
      setIsShowIndividuals(false);
    }
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTitleCount(value.length);
  };

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessageCount(value.length);
  };

  return (
    <div className="animated fadeInRight">
      <h3 className={`${Styles.Header} page-title`}>Messaging</h3>
      <div className={Styles.FormContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={Styles.Form}>
            <div className={Styles.FormHeader}>
              <h3>Message</h3>
              <span
                className={Styles.FormCancelIcon}
                onClick={() => history.goBack()}
              />
            </div>

            <div className="row m-t-1">
              <div className="col-sm-6 col-xs-12">
                <div className={Styles.FormLabel}>
                  <label>Title</label>
                  <span>
                    ({titleCount} of {maximumMessageTitleCount})
                  </span>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className={Styles.MessagingInput}
                    placeholder="Message Title"
                    name="title"
                    maxLength={maximumMessageTitleCount}
                    onChange={handleTitleChange}
                    ref={register}
                  />
                  <span className={Styles.errorMessage}>
                    {errors?.title?.message}
                  </span>
                </div>
              </div>

              <div className="col-sm-6 col-xs-12">
                <label className={Styles.FormLabel}>Message Type</label>
                <div className="form-group">
                  <select
                    className={Styles.MessagingInput}
                    name="messageClass"
                    ref={register}
                  >
                    <option value="" disabled selected hidden>
                      Select Message Type
                    </option>
                    {messageTypes?.data.map(
                      (type: MessageType, index: number) => (
                        <option key={index} value={type?.id}>
                          {type?.name}
                        </option>
                      )
                    )}
                  </select>
                  <span className={Styles.errorMessage}>
                    {errors?.messageType?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row m-t-1">
              <div className="col-sm-6 col-xs-12">
                <label className={Styles.FormLabel}>User Category</label>
                <div className="form-group">
                  <select
                    className={Styles.MessagingInput}
                    name="userCategory"
                    ref={register}
                    onChange={handleChangeCategory}
                  >
                    <option value="" disabled selected hidden>
                      Select Category
                    </option>
                    {userCategories?.data.map(
                      (category: Category, index: number) => (
                        <option key={index} value={category?.id}>
                          {category?.name}
                        </option>
                      )
                    )}
                  </select>
                  <span className={Styles.errorMessage}>
                    {errors?.userCategory?.message}
                  </span>
                </div>
              </div>

              {isShowIndividuals && (
                <div className="col-sm-6 col-xs-12">
                  <label className={Styles.FormLabel}>User</label>
                  <div className="form-group">
                    <CustomSearchDropDown
                      label="Select a User"
                      loadOptions={getOptions}
                      onChange={handleDropdownChange}
                      name="user"
                      register={register}
                      isShowLabel={false}
                      isMulti={true}
                    />
                    <span className={Styles.errorMessage}>
                      {errors?.user?.message}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="row m-t-1">
              <div className="col-sm-12 col-xs-12">
                <div className={Styles.FormLabel}>
                  <label>Message</label>
                  <span>
                    ({messageCount} of {maximumMessageCount})
                  </span>
                </div>
                <div className="form-group">
                  <textarea
                    placeholder="Enter message here"
                    className={`p-t-1 ${Styles.MessagingInput} ${Styles.TextArea}`}
                    name="message"
                    onChange={handleMessageChange}
                    maxLength={maximumMessageCount}
                    ref={register}
                  ></textarea>
                  <span className={Styles.errorMessage}>
                    {errors?.message?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className={`m-t-1 ${Styles.FormButtonContainer}`}>
              <button type="submit" className="btn advancly-btn btn-md m-0">
                {isSubmitting ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : (
                  <React.Fragment>Send Notification </React.Fragment>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendMessage;
