import React from 'react';

// Custom UI Components
import { Button } from './../../UI/Buttons/Buttons';

import Logo from './../../assets/images/investor.svg';

// Component Styles
import './LandingPage.css';

const LandingPage = () => {
    return (
        <div className="LandingPage">
            <header className="header">
                <div className="cta">
                    <h1 className="cta__header">Unlock your financial freedom with investments oppurtunities, and oppurtunities to invest
                    </h1>
                    <Button className="cta__button">GET STARTED</Button>
                </div>
            </header>
            <section className="why-advancly">
                <h2 className="why-advancly__header">WHY ADVANCLY</h2>
                <p className="why-advancly__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vestibulum velit vel diam blandit aliquam. Vestibulum sed urna id orci feugiat venenatis. Donec in placerat mauris, a scelerisque sapien. Mauris viverra posuere mi vitae maximus. Pellentesque id ipsum hendrerit velit commodo finibus. Aenean a pharetra mi. Aenean ipsum ex, hendrerit nec velit nec, gravida egestas quam.</p>
            </section>
            <section className="about-advancly">
                <h2 className="about-advancly__header">WHAT WE DO</h2>
                <article className="funder">
                    
                    <img className="about-advancly__img" src={Logo} alt=""/>
                    <div className="about-advancly__info">
                        <h3 className="about-advancly__info__header">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                        <p className="about-advancly__info__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris vestibulum velit vel diam blandit aliquam. Donec in placerat mauris, a scelerisque sapien. Mauris viverra posuere mi vitae maximus. Pellentesque id ipsum hendrerit velit commodo finibus. Aenean a pharetra mi. Aenean ipsum ex, hendrerit nec velit nec, gravida egestas quam.</p>
                    </div>
                </article>
                <article className="aggregator">
                    <img className="about-advancly__img" src="" alt=""/>
                    <div className="about-advancly__info">
                        <h3 className="about-advancly__info__header">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                        <p className="about-advancly__info__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sed urna id orci feugiat venenatis. Donec in placerat mauris, a scelerisque sapien. Mauris viverra posuere mi vitae maximus. Pellentesque id ipsum hendrerit velit commodo finibus. Aenean a pharetra mi.</p>
                    </div>
                </article>
                <article className="borrower">
                    <img className="about-advancly__img" src="" alt=""/>
                    <div className="about-advancly__info">
                        <h3 className="about-advancly__info__header">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                        <p className="about-advancly__info__text">Mauris vestibulum velit vel diam blandit aliquam. Vestibulum sed urna id orci feugiat venenatis. Donec in placerat mauris, a scelerisque sapien. Mauris viverra posuere mi vitae maximus. Pellentesque id ipsum hendrerit velit commodo finibus.</p>
                    </div>
                </article>
            </section>
            <section className="identify-yourself">
                <h2 className="identify-yourself__header">IDENTIFY YOURSELF</h2>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <i className="card__icon fa fa-2x fa-building" />
                            <h3 className="card__header">Aggregator</h3>
                            <p className="card__text">Mauris vestibulum velit vel diam blandit aliquam. Vestibulum sed urna id orci feugiat venenatis.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <i className="card__icon fa fa-2x fa-wallet" />
                            <h3 className="card__header">Investor</h3>
                            <p className="card__text">Mauris vestibulum velit vel diam blandit aliquam. Vestibulum sed urna id orci feugiat venenatis.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <i className="card__icon fa fa-2x fa-money" />
                            <h3 className="card__header">Borrower</h3>
                            <p className="card__text">Mauris vestibulum velit vel diam blandit aliquam. Vestibulum sed urna id orci feugiat venenatis.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default LandingPage;