import { ROLE_TYPES } from "../../../helpers/roleTypes";
import { documentApprovalStatus, onBoardingStatusCode } from "../util";

export const isProceedDisabled = (uploadedDocuments) => {
  const testArray = [];

  for (let index = 0; index < uploadedDocuments.length; index++) {
    const doc = uploadedDocuments[index];
    const isAllFileInDocTrue = doc.files.every(
      (file) => file.approval_status === documentApprovalStatus?.APPROVED
    );

    if (isAllFileInDocTrue) {
      testArray.push(true);
    } else {
      testArray.push(false);
    }
  }

  const isProceedDisabled = testArray.every((item) => item === true);
  if (isProceedDisabled) {
    return false;
  } else {
    return true;
  }
};

export const handleDisableDocumentAction = (
  activeTab,
  loggedInUser,
  environmentData
) => {
  if (activeTab === "legal-compliance") {
    if (
      loggedInUser?.roles?.includes(ROLE_TYPES.LEGAL_ONBOARDING_APPROVER)
      // &&
      // (environmentData?.statusCode ===
      //   onBoardingStatusCode?.LEGAL_IN_PROGRESS ||
      //   environmentData?.subsequentStatusCode ===
      //     onBoardingStatusCode?.LEGAL_IN_PROGRESS)
    ) {
      return false;
    } else {
      return true;
    }
  } else if (activeTab === "credit-risk") {
    if (
      loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_ONBOARDING_APPROVER)
      //  &&
      // (environmentData?.statusCode ===
      //   onBoardingStatusCode?.CREDIT_IN_PROGRESS ||
      //   environmentData?.subsequentStatusCode ===
      //     onBoardingStatusCode?.CREDIT_IN_PROGRESS)
    ) {
      return false;
    } else {
      return true;
    }
  }
};
