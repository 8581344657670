import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import CustomInputField from "./../../../NewComponents/CustomHTMLElements/CustomInputField";
import { ReactComponent as Bin } from "../../../assets/svg/icons/bin.svg";
import useEligibilityDocument from "../../../custom-hooks/useEligibilityDocument";
import {
  deleteDataWithDotNet,
  postDataWithDotNet,
  putDataWithDotNet,
} from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { errorHandler } from "../../../helpers/errorHandler";
//redux
import AggregatorOnboardingDocumentModal from "./AggregatorOnboardingDocumentModal.component";
import { Loader } from "./../../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import Alert from "./../../../NewComponents/Alert/Alert.component";
import { useQueryCache } from "react-query";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import useAllProductType from "../../../custom-hooks/useAllProductType";
import styles from "./AggregatorOnboardingRequiredDocs.module.scss";
import { useFilter } from "../EligibilityQuestions/useFilter";
import { appInsights } from "../../../config/appInsights";
import { documentOwner } from "./util";

function AggregatorOnboardingRequiredDocuments() {
  const [editMode, setEditMode] = useState(false);
  const [modalActionType, setModalActionType] = useState("");
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [filterOptions, handleChange, filterParam] = useFilter();

  const dismissModalRef = useRef();
  const history = useHistory();
  const queryCache = useQueryCache();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Required Documents  - (AggregatorOnboardingRequiredDocuments.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const {
    data,
    status,
    error: documentError,
    refetch,
  } = useEligibilityDocument({ ProductType: filterParam });
  const { data: availableProductTypes } = useAllProductType();

  useEffect(() => {
    if (data) {
      setDocuments(
        data.map((obj) => {
          const productTypeIds = obj?.product_types.map((item) => item.id);
          return {
            ...obj,
            newDocument: false,
            isEditing: false,
            productTypeIds,
          };
        })
      );
    }
  }, [data]);

  const positionQuestion = (item, documentToUpdate, id) => {
    const index = documents.findIndex((item) => item === documentToUpdate);
    const modifiedDocuments = [
      ...documents.filter((document) => document.id !== id),
    ];

    modifiedDocuments.splice(index, 0, item);

    return modifiedDocuments;
  };

  const discardChanges = (e) => {
    e.preventDefault();
    if (dismissModalRef && dismissModalRef.current) {
      dismissModalRef.current.click();
    }
    setEditMode(false);
    return setDocuments(
      data.map((obj) => ({ ...obj, newDocument: false, isEditing: false }))
    );
  };

  const deleteDocument = async (id) => {
    const newDocuments = documents.filter((document) => document.id !== id);
    setLoading(true);
    setError(null);
    setSuccess(null);
    // delete document by id
    try {
      await deleteDataWithDotNet(`${ajaxEndpoints.DOCUMENT}/${id}`);
      setLoading(false);
      setDocuments(newDocuments);
      setSuccess("Successfully deleted");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingRequiredDocuments.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const getDocumentOwnerIds = (owners) => {
    if (owners && owners.length > 0) {
      const result = owners.map((item) => {
        let _document = Object.keys(documentOwner).find((doc) => doc === item);
        if (_document) {
          return documentOwner[_document];
        }
      });

      return result;
    }
  };
  const editDocument = async (id) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    const documentToEdit = documents.find((doc) => doc.id === id);
    let ownerTypes = documentToEdit.owner_types;

    if (typeof documentToEdit.owner_types[0] === "object") {
      let types = documentToEdit.owner_types.map((item) => item.label);
      ownerTypes = types;
    }

    let result = getDocumentOwnerIds(ownerTypes);
    const newDocumentToEdit = { ...documentToEdit };
    newDocumentToEdit.owner_types = result;

    const reqBody = {
      document_fields: [newDocumentToEdit],
    };
    try {
      await putDataWithDotNet(
        `${ajaxEndpoints.DOCUMENT}/${newDocumentToEdit.id}`,
        reqBody
      );
      setDocuments([
        ...documents.filter(
          (newDocument) => newDocument.id !== newDocumentToEdit.id
        ),
        {
          ...newDocumentToEdit,
          isEditing: false,
        },
      ]);
      setLoading(false);
      setSuccess("Successfully updated");
      queryCache.invalidateQueries("getAggregatorDocuments");
      refetch();
      setTimeout(() => {
        history.push("/aggregator-onboarding/documents/admin");
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingRequiredDocuments.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const addNewDocument = () => {
    const lastPosition = documents?.length
      ? Math.max(...documents.map((document) => Number(document.position)))
      : 0;
    const id = documents?.length
      ? Math.max(...documents.map((document) => Number(document.id)))
      : 1;
    setDocuments([
      ...documents,
      {
        id: id ? id + 1 : 1,
        title: "",
        provider_type: 1, // 1 is aggregator, 2 is for Admin
        is_mandatory: true,
        is_active: true,
        newDocument: true,
        position: lastPosition ? lastPosition + 1 : 1,
        isEditing: false,
      },
    ]);
  };

  const onChange = (e, id) => {
    const { name, value } = e.target;
    const documentToUpdate = documents.find((document) => document.id === id);
    let result = positionQuestion(
      {
        ...documentToUpdate,
        isEditing: true,
        [name]: value === "true" ? true : value === "false" ? false : value,
      },
      documentToUpdate,
      id
    );
    setDocuments(result);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setError(null);
    setSuccess(null);
    const reqBody = documents.filter(
      (document) => document.newDocument === true
    );

    if (!reqBody.length) {
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      return setError("You did not add any new question");
    }

    setLoading(true);
    try {
      const response = await postDataWithDotNet(ajaxEndpoints.DOCUMENT, {
        document_fields: reqBody,
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setSuccess(response.message);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingRequiredDocuments.jsx",
        },
      });
      setLoading(false);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setError(errorHandler(error));
    }
  };

  const createNewDocument = async (newDocument) => {
    const product_type_ids = newDocument.productTypeIds;
    newDocument.owner_types = newDocument.owner_types.map((item) => item.value);

    delete newDocument.productTypeIds;

    setError(null);
    setSuccess(null);
    setLoading(true);
    const payload = {
      ...newDocument,
      product_type_ids,
    };

    try {
      const response = await postDataWithDotNet(ajaxEndpoints.DOCUMENT, {
        document_fields: [
          {
            ...payload,
            provider_type: Number(newDocument.provider_type),
          },
        ],
      });
      setLoading(false);
      setSuccess(response.message);
      queryCache.invalidateQueries("getAggregatorDocuments");
      setTimeout(() => {
        history.push("/aggregator-onboarding/documents/admin");
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingRequiredDocuments.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  if (status === "loading")
    return <Loader centered={true} text="Loading Onboarding Documents..." />;

  if (status === "error" || documentError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while loading the
            onboarding documents, please try again.
          </h4>
        </div>
      </div>
    );
  }

  const isNewDocument = () => {
    const newlyAddedDocuments = documents.filter(
      (doc) => doc.newDocument === true
    );
    return newlyAddedDocuments.length ? true : false;
  };

  const onProductTypeChange = (listInQuestion, id) => {
    const documentToUpdate = documents.find((document) => document.id === id);
    const productTypeIds = listInQuestion.map((item) => item.value);

    let result = positionQuestion(
      {
        ...documentToUpdate,
        isEditing: true,
        productTypeIds: [...productTypeIds],
      },
      documentToUpdate,
      id
    );

    setDocuments(result);
  };

  const getQuestionProductTypes = (listInQuestion) => {
    if (listInQuestion && listInQuestion.length > 0) {
      const result = listInQuestion.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });

      return result;
    }

    return [];
  };

  const cleanProductTypes = (arrayInQuestion) => {
    if (arrayInQuestion && arrayInQuestion.length > 0) {
      const result = arrayInQuestion.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });

      return result;
    }

    return [];
  };

  const getDocumentOwner = (listInQuestion) => {
    if (listInQuestion && listInQuestion.length > 0) {
      const result = listInQuestion.map((item) => {
        let _document = Object.keys(documentOwner).find((doc) => doc === item);
        if (_document) {
          return {
            label: item,
            value: documentOwner[_document],
          };
        }
      });

      return result;
    }

    return [];
  };

  const getAvailableDocumentOwners = () => {
    return Object.keys(documentOwner).map((doc) => {
      return {
        label: doc,
        value: documentOwner[doc],
      };
    });
  };

  const onDocumentOwnerChange = (currentValue, id) => {
    const documentToUpdate = documents.find((document) => document.id === id);

    let result = positionQuestion(
      {
        ...documentToUpdate,
        isEditing: true,
        owner_types: [...currentValue],
      },
      documentToUpdate,
      id
    );

    setDocuments(result);
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />
          Required Documents
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          {editMode ? (
            <Fragment>
              <div className={styles.filterContainer}>
                <div className={`form-group ${styles.filterItem}`}>
                  <select
                    className={styles.FilterInput}
                    onChange={handleChange}
                    name="productType"
                  >
                    <option value="" disabled selected hidden>
                      Product Type
                    </option>
                    {filterOptions?.map((type, index) => (
                      <option key={index} value={type?.value}>
                        {type?.name}
                      </option>
                    ))}
                  </select>
                  <i
                    className={`fas fa-solid fa-angle-down ${styles.Icon}`}
                  ></i>
                </div>
              </div>
              <button
                className="btn advancly-white-btn btn-md mr-2"
                onClick={() => setEditMode(false)}
              >
                Discard Changes
              </button>
              <button
                className="btn advancly-btn btn-md"
                data-toggle="modal"
                data-target="#documentOnboardingModal"
                onClick={() => setModalActionType("update")}
                disabled={loading || !isNewDocument() || editMode === false}
              >
                Update Documents
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                className="btn advancly-btn btn-md"
                onClick={() => setEditMode(true)}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </Fragment>
          )}
        </div>
      </div>

      <div>
        <hr />
        <div className="onboardingOuterLayout">
          <div className="onboardingInnerLayout">
            <div className="w-100">
              <p className="text-left my-3">
                The following documents <b>will be required</b> by intending
                Aggregators to request onboarding.
              </p>
              <Fragment>
                {documents
                  // .sort((a, b) => a.position - b.position)
                  .map((document, _id) => {
                    const {
                      title,
                      id,
                      is_mandatory,
                      is_active,
                      position,
                      isEditing,
                      newDocument,
                      provider_type,
                      product_types,
                      code,
                      owner_types,
                    } = document;

                    return (
                      <div className="row mb-5" key={position}>
                        <div className="col-12">
                          <CustomInputField
                            type="text"
                            label={<b>Required Document {_id + 1}</b>}
                            value={title}
                            defaultValue={title}
                            readOnly={!editMode}
                            name="title"
                            onChange={(e) => onChange(e, id)}
                          />
                        </div>
                        <div className="col-12">
                          <CustomInputField
                            type="text"
                            label={<b>Code</b>}
                            value={code}
                            defaultValue={code}
                            readOnly={!editMode}
                            name="code"
                            onChange={(e) => onChange(e, id)}
                          />
                        </div>
                        <div className="col-12">
                          <CustomSearchDropDown
                            label="Select Product Type"
                            labelClass="d-block mt-0"
                            disabled={!editMode}
                            defaultValue={getQuestionProductTypes(
                              product_types
                            )}
                            defaultOptions={cleanProductTypes(
                              availableProductTypes
                            )}
                            onChange={(currentValue) =>
                              onProductTypeChange(currentValue, id)
                            }
                            isSearchable={false}
                            isMulti={true}
                          />
                        </div>

                        <div className="col-12">
                          <CustomSearchDropDown
                            label="Document Owner"
                            labelClass="d-block mt-0"
                            disabled={!editMode}
                            defaultValue={getDocumentOwner(owner_types)}
                            defaultOptions={getAvailableDocumentOwners()}
                            onChange={(currentValue) =>
                              onDocumentOwnerChange(currentValue, id)
                            }
                            isSearchable={false}
                            isMulti={true}
                          />
                        </div>

                        <div className="col-12 col-lg-4">
                          <CustomSelectDropdown
                            value={is_mandatory}
                            defaultValue={is_mandatory}
                            onChange={(e) => onChange(e, id)}
                            label="Required?"
                            disabled={!editMode}
                            name="is_mandatory"
                          >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </CustomSelectDropdown>
                        </div>
                        <div className="col-12 col-lg-4">
                          <CustomSelectDropdown
                            value={is_active}
                            defaultValue={is_active}
                            onChange={(e) => onChange(e, id)}
                            label="Active?"
                            disabled={!editMode}
                            name="is_active"
                          >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </CustomSelectDropdown>
                        </div>
                        <div className="col-12 col-lg-4">
                          <CustomSelectDropdown
                            value={provider_type}
                            defaultValue={provider_type}
                            onChange={(e) => onChange(e, id)}
                            label="Document Provider"
                            disabled={!editMode}
                            name="provider_type"
                          >
                            <option value={1}>Aggregator</option>
                            <option value={2}>Admin</option>
                          </CustomSelectDropdown>
                        </div>
                        {/* <div className="col-12 col-lg-4 mt-3">
                          <CustomSelectDropdown
                            defaultValue={"Credit"}
                            onChange={(e) => onChange(e, id)}
                            label="Document Owner?"
                            disabled={!editMode}
                            name="owner_types"
                          >
                            <option value=""></option>
                            {Object.keys(documentOwner).map((item) => (
                              <option key={documentOwner[item]} value={item}>
                                {item}
                              </option>
                            ))}
                          </CustomSelectDropdown>
                        </div> */}
                        {editMode && (
                          <div className="w-100 d-flex align-items-center justify-content-end mt-3">
                            <div>
                              {newDocument ? (
                                <button
                                  onClick={() => createNewDocument(document)}
                                  className="cursor-pointer btn advancly-white-btn btn-sm mr-3"
                                  disabled={loading || !isEditing}
                                >
                                  {isEditing && !loading
                                    ? "Add"
                                    : isEditing && loading
                                    ? "Adding..."
                                    : "Add"}
                                </button>
                              ) : (
                                <button
                                  onClick={() => editDocument(id)}
                                  className="cursor-pointer btn advancly-white-btn btn-sm mr-3"
                                  disabled={loading || !isEditing}
                                >
                                  {isEditing && !loading
                                    ? "Save"
                                    : isEditing && loading
                                    ? "Updating..."
                                    : "Edit"}
                                </button>
                              )}
                            </div>
                            <div>
                              <button
                                onClick={() => deleteDocument(id)}
                                className="cursor-pointer btn advancly-red-btn btn-sm"
                                disabled={loading}
                              >
                                <span className="mr-2">Delete</span> <Bin />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </Fragment>
              {editMode && (
                <div
                  className="cursor-pointer text-underline color-blue text-left"
                  onClick={addNewDocument}
                >
                  Add another document
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AggregatorOnboardingDocumentModal
        onSubmit={onSubmit}
        discardChanges={discardChanges}
        modalActionType={modalActionType}
        dismissModalRef={dismissModalRef}
        loading={loading}
      />
    </Fragment>
  );
}

export default AggregatorOnboardingRequiredDocuments;
