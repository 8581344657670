import React from "react";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomTextArea from "./../../../NewComponents/CustomHTMLElements/CustomTextArea";
import useCountryCodes from "./../../../custom-hooks/useCountryCodes";

export default function AggregatorOnboardingReviewBusinesInfo({
  businessInfo,
}) {
  const { data } = useCountryCodes();
  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <form>
          <div className="row">
            <div className="col-12">
              <CustomInputField
                type="text"
                placeholder="Business Name"
                label="Business Name"
                defaultValue={businessInfo?.biz_name}
                readOnly
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="email"
                placeholder="Business Email"
                label="Business Email Address"
                readOnly
                defaultValue={businessInfo?.biz_email}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="number"
                label="Business Phone Number"
                readOnly
                defaultValue={businessInfo?.biz_phone}
                placeholder="Business Phone Number"
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="text"
                label="Business Sector"
                readOnly
                defaultValue={businessInfo?.biz_category}
                placeholder="Business Sector"
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="text"
                label="Country"
                readOnly
                defaultValue={
                  businessInfo?.country_code && data && data.length
                    ? data.find(
                        (country) =>
                          country.country_code === businessInfo?.country_code
                      ).name
                    : businessInfo?.country_code
                }
                placeholder="Country"
              />
            </div>
            <div className="col-12">
              <CustomInputField
                type="text"
                placeholder="Business Address"
                label="Physical Business Address"
                readOnly
                defaultValue={businessInfo?.biz_Address}
              />
            </div>
            <div className="col-12">
              <CustomTextArea
                placeholder="Business Purpose"
                label="What does your business do?"
                readOnly
                defaultValue={businessInfo?.biz_description}
              />
            </div>
            {/* <div className="col-12 col-lg-6">
              <CustomInputField
                type="text"
                label="Registration Type"
                readOnly
                defaultValue={businessInfo?.biz_registration_type}
                placeholder="Registration Type"
              />
            </div> */}
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="text"
                placeholder="Registration Number"
                label="Registration Number"
                readOnly
                defaultValue={businessInfo?.biz_registration_number}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
