import React, { Component } from "react";

// Ajax Methods
import ajaxMethods from "../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

import {
  disbursementType,
  repaymentFrequencys,
  repayerType,
  scheduleType,
} from "../../../models/product-data-model";
import {
  getDataWithDotNet,
  postDataWithDotNet,
} from "../../../newApis/dotNetApiMethods";
import { errorHandler } from "../../../helpers/errorHandler";
import { formatMoney } from "../../../helpers/formatter";
import { appInsights } from "../../../config/appInsights";

export default class AggregatorProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scene: "form",
      loading: false,
      productCategoryOptions: [],
      aggregatorOptions: [],
      selectedAggregatorId: props?.properties?.dataRecord?.aggregator_id || "",
      selectedProductId:
        props?.properties?.dataRecord?.product_category_id || "",
      maxTenure: props?.properties?.dataRecord?.maximum_tenor || "",
      maxAmount: props?.properties?.dataRecord?.maximum_amount || "",
      productCode: props?.properties?.dataRecord?.product_code || "",
      productName: props?.properties?.dataRecord?.product_name || "",
      InterestRate: props?.properties?.dataRecord?.interest_rate || "",
      selectedrepaymentFrequency:
        props?.properties?.dataRecord?.repayment_type || "",
      allRepayerType: [],
      selectedRepayerType: props?.properties?.dataRecord?.repayer_type_id || "",
      repayerType: [],
      alldisbursementTypes: [],
      selectedDisbursementType:
        props?.properties?.dataRecord?.disbursement_type_id || "",
      selectedScheduleType:
        props?.properties?.dataRecord?.loan_calculation_method_value || "",
      allScheduleType: [],
      showMessage: false,
      showErrorMessage: false,
      message: "",
      product_id: props?.properties?.dataRecord?.id || "",
      bankOptionsList: [],
      disbursementChannelOptions: [],
      selectedDisbursementChannel:
        props?.properties?.dataRecord?.disbursement_channel_id || "",
      repaymentChannelOptions: [],
      selectedRepaymentChannel:
        props?.properties?.dataRecord?.repayment_channel_id || "",
      moratoriumPeriod:
        props?.properties?.dataRecord?.moratorium_duration || "",
      bankName: props?.properties?.dataRecord?.bank_id || "",
      accountNumber: props?.properties?.dataRecord?.account_number || "",
      accountLoading: false,
      error: false,
      repaymentError: false,
      tenant: props?.properties?.dataRecord?.core_banking_tenant || "",
      bankCode: "",
      accountName: props?.properties?.dataRecord?.bank_account_name || "",
      paystackService: props?.properties?.dataRecord?.use_paystack || "",
      okraService: props?.properties?.dataRecord?.use_okra || "",
      channelAccountsDetails: false,
      disbursementAccountId:
        props?.properties?.dataRecord?.dis_channel_acct_id || "",
      coreBankingProductId:
        props?.properties?.dataRecord?.core_banking_product_id || "",
      coreBankingProductList: false,
      coreBankingDetails: false,
      disbursementChannelAccountsList: false,
      repayChannelAccountsDetails: false,
      repaymentAccountId:
        this.props?.properties?.dataRecord?.rep_channel_acct_id || "",
      repaymentChannelAccountsList: false,
      repaymentAccountNumber:
        this.props?.properties?.dataRecord?.repayment_account_number || "",
      repaymentAccountName:
        this.props?.properties?.dataRecord?.repayment_account_name || "",
      repaymentbankName:
        this.props?.properties?.dataRecord?.repayment_bank_id || "",
      repaymentbankOptionsList: [],
      repaymentBankCode: "",
      loanType: props?.properties?.dataRecord?.loan_type_id || "",
      allowBatchDisbursement:
        props?.properties?.dataRecord?.allow_batch_disbursement || "",
      maximumRunningLoan:
        props?.properties?.dataRecord?.maximum_running_loan || "",
      endOfTenorPrincipal:
        props?.properties?.dataRecord?.repay_principal_at_end_of_tenure.toString() ||
        "",
      currency: props?.properties?.dataRecord?.currency || "",
      moratorium_type: props?.properties?.dataRecord?.moratorium_type || "",
      require_aggregator_approval:
        props?.properties?.dataRecord?.require_aggregator_approval || "",
      require_bvn_verification:
        props?.properties?.dataRecord?.require_bvn_verification || "",
      require_deal_slip: props?.properties?.dataRecord?.require_deal_slip || "",
      amortization_type: props?.properties?.dataRecord?.amortization_type || "",
      moratoriumTypeList: [],
      tenantList: [],
    };
  }
  componentDidMount() {
    this.getAggregatorName();
    this.getProductCategories();
    this.getallDisbursementTypes();
    this.getAllRepaymentFrequency();
    this.getAllRepayerType();
    this.getAllScheduleType();
    this.getBankList();
    this.getDisbursementChannels();
    this.getRepaymentChannels();
    this.getLoanType();
    this.getMoratoriumTypes();
    this.getTenants();
    if (this.state.selectedDisbursementChannel) {
      const channelId = this.state.selectedDisbursementChannel;
      this.getDisbursementChannelAccounts(channelId);
    }

    if (this.state.selectedRepaymentChannel) {
      const repaymentChannelId = this.state.selectedRepaymentChannel;
      this.getRepaymentChannelAccounts(repaymentChannelId);
    }

    if (this.state.tenant) {
      this.getCoreBankingProductId(this.state.tenant);
    }
  }

  // fetch core banking tenants
  getTenants = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_CORE_BANKING_TENANT_INFO)
      .then((response) => {
        const corebankingTenants = response?.data;

        if (response?.status_code === 200) {
          const options = corebankingTenants.map((_tenant) => {
            return (
              <option value={_tenant?.tenant_id} key={_tenant?.id}>
                {_tenant?.full_name}
              </option>
            );
          });

          this.setState({ tenantList: options });
        }
      });
  };

  // fetch bank list
  getBankList = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let bankList = response.data;
          const bankOptionsList = bankList.map((data, index) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            );
          });
          this.setState({ bankOptionsList: bankOptionsList });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch product category list
  getProductCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.PRODUCT_CATEGORY_LIST)
      .then((response) => {
        let productCategoryList = [];

        if (response.data.length > 0) {
          productCategoryList = [...response.data];
        } else {
          productCategoryList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const productCategoryOptions = productCategoryList.map(
          (ecosystem, index) => {
            const { code, id, productCategoryName } = ecosystem;
            return (
              <option key={index} id={code} value={id}>
                {productCategoryName}
              </option>
            );
          }
        );

        this.setState({
          productCategoryOptions: productCategoryOptions,
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  //fetch aggregators
  getAggregatorName = () => {
    ajaxMethods
      .get(ajaxEndpoints.ECOSYSTEMS)
      .then((response) => {
        let categoryList = [];

        if (response.data.length > 0) {
          categoryList = [...response.data];
        } else {
          categoryList = [
            {
              aggregator_id: 0,
              category_name: "No Categories Yet",
              category_slug: "No Categories Yet",
            },
          ];
        }
        const aggregatorOptions = categoryList.map((ecosystem, index) => {
          const { aggregator_id, biz_name } = ecosystem;
          return (
            <option key={index} id={aggregator_id} value={aggregator_id}>
              {biz_name}
            </option>
          );
        });
        this.setState({
          aggregatorOptions: aggregatorOptions,
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // get all Repayment Frequencys
  getAllRepaymentFrequency = () => {
    const allrepaymentFrequencys = repaymentFrequencys.map(
      (ecosystem, index) => {
        const { repayment_Type, repaymentFrequencyName } = ecosystem;
        return (
          <option key={index} id={repayment_Type} value={repayment_Type}>
            {repaymentFrequencyName}
          </option>
        );
      }
    );
    this.setState({
      allrepaymentFrequencys: allrepaymentFrequencys,
    });
  };
  // get all Repayment Frequencys
  getAllRepaymentFrequency = () => {
    const allrepaymentFrequencys = repaymentFrequencys.map(
      (ecosystem, index) => {
        const { repayment_Type, repaymentFrequencyName } = ecosystem;
        return (
          <option key={index} id={repayment_Type} value={repayment_Type}>
            {repaymentFrequencyName}
          </option>
        );
      }
    );
    this.setState({
      allrepaymentFrequencys: allrepaymentFrequencys,
    });
  };
  // get all Schedule Type
  getAllScheduleType = () => {
    const allScheduleType = scheduleType.map((ecosystem, index) => {
      const { schedule_Type, scheduleTypeName } = ecosystem;

      return (
        <option key={index} id={schedule_Type} value={scheduleTypeName}>
          {scheduleTypeName}
        </option>
      );
    });
    this.setState({
      allScheduleType: allScheduleType,
    });
  };
  // get all disbursement types
  getallDisbursementTypes = () => {
    const alldisbursementTypes = disbursementType.map((ecosystem, index) => {
      const { disbursementId, disbursementType } = ecosystem;
      return (
        <option key={index} id={disbursementId} value={disbursementId}>
          {disbursementType}
        </option>
      );
    });

    this.setState({
      alldisbursementTypes: alldisbursementTypes,
    });
  };

  // get all Repayer Type
  getAllRepayerType = () => {
    const allRepayerType = repayerType.map((ecosystem, index) => {
      const { repayerTypeId, repayerTypeName } = ecosystem;
      return (
        <option key={index} id={repayerTypeId} value={repayerTypeId}>
          {repayerTypeName}
        </option>
      );
    });
    this.setState({
      allRepayerType: allRepayerType,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      selectedAggregatorId,
      selectedProductId,
      maxTenure,
      maxAmount,
      productCode,
      productName,
      InterestRate,
      selectedrepaymentFrequency,
      selectedRepayerType,
      selectedDisbursementType,
      selectedScheduleType,
      accountNumber,
      moratoriumPeriod,
      tenant,
      bankName,
      selectedDisbursementChannel,
      selectedRepaymentChannel,
      accountName,
      okraService,
      paystackService,
      disbursementAccountId,
      repaymentAccountId,
      repaymentbankName,
      repaymentAccountNumber,
      repaymentAccountName,
      loanType,
      coreBankingProductId,
      allowBatchDisbursement,
      maximumRunningLoan,
      moratorium_type,
      require_aggregator_approval,
      require_bvn_verification,
      require_deal_slip,
      amortization_type,
    } = this.state;
    const params = {
      product_category_id: Number(selectedProductId),
      aggregator_id: Number(selectedAggregatorId),
      product_code: productCode,
      product_name: productName,
      maximum_tenor: Number(maxTenure),
      interest_rate: Number(InterestRate),
      maximum_amount: Number(maxAmount),
      repayment_type: selectedrepaymentFrequency,
      repayer_type_id: Number(selectedRepayerType),
      disbursement_type_id: Number(selectedDisbursementType),
      loan_calculation_method: scheduleType.find(
        (item) => item.scheduleTypeName === selectedScheduleType
      )?.schedule_Type,
      account_number: accountNumber,
      moratorium: moratoriumPeriod,
      banking_tenant: tenant,
      bank_id: Number(bankName),
      disbursement_channel_id: Number(selectedDisbursementChannel),
      repayment_channel_id: Number(selectedRepaymentChannel),
      bank_account_name: accountName,
      disbursement_channel_account_id: Number(disbursementAccountId),
      repayment_channel_account_id: Number(repaymentAccountId),
      repayment_bank_id: Number(repaymentbankName),
      repayment_account_number: repaymentAccountNumber,
      repayment_account_name: repaymentAccountName,
      loan_type_id: Number(loanType),
      core_banking_product_id: Number(coreBankingProductId),
      use_okra: JSON.parse(okraService),
      use_paystack: JSON.parse(paystackService),
      allow_batch_disbursement: JSON.parse(allowBatchDisbursement),
      // repay_principal_at_end_of_tenure: JSON.parse(endOfTenorPrincipal),
      maximum_running_loan: Number(maximumRunningLoan),
      moratorium_type: Number(moratorium_type),
      require_aggregator_approval: JSON.parse(require_aggregator_approval),
      require_bvn_verification: JSON.parse(require_bvn_verification),
      require_deal_slip: JSON.parse(require_deal_slip),
      amortization_type: Number(amortization_type),
    };
    try {
      let response = await postDataWithDotNet(
        ajaxEndpoints.CREATE_AGGREGATORS_PRODUCT,
        params
      );
      const message = response.message;
      this.setState({
        message: message,
        showMessage: true,
        showErrorMessage: false,
        loading: false,
      });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorProductModal.js",
        },
      });
      this.setState({
        message: errorHandler(error),
        showErrorMessage: true,
        showMessage: false,
        loading: false,
      });
    }
  };
  handleEdit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const {
      selectedAggregatorId,
      selectedProductId,
      maxTenure,
      maxAmount,
      productCode,
      productName,
      InterestRate,
      selectedrepaymentFrequency,
      selectedRepayerType,
      selectedDisbursementType,
      selectedScheduleType,
      product_id,
      accountNumber,
      moratoriumPeriod,
      tenant,
      bankName,
      selectedDisbursementChannel,
      selectedRepaymentChannel,
      accountName,
      okraService,
      paystackService,
      disbursementAccountId,
      repaymentAccountId,
      repaymentbankName,
      repaymentAccountNumber,
      repaymentAccountName,
      loanType,
      coreBankingProductId,
      allowBatchDisbursement,
      maximumRunningLoan,
      moratorium_type,
      require_aggregator_approval,
      require_bvn_verification,
      require_deal_slip,
      amortization_type,
    } = this.state;
    const params = {
      product_id: Number(product_id),
      product_category_id: Number(selectedProductId),
      aggregator_id: Number(selectedAggregatorId),
      product_code: productCode,
      product_name: productName,
      maximum_tenor: Number(maxTenure),
      interest_rate: Number(InterestRate),
      maximum_amount: Number(maxAmount),
      repayment_type: selectedrepaymentFrequency,
      repayer_type_id: Number(selectedRepayerType),
      disbursement_type_id: Number(selectedDisbursementType),
      loan_calculation_method: scheduleType.find(
        (item) => item.scheduleTypeName === selectedScheduleType
      )?.schedule_Type,
      account_number: accountNumber,
      moratorium: moratoriumPeriod,
      banking_tenant: tenant,
      bank_id: Number(bankName),
      disbursement_channel_id: Number(selectedDisbursementChannel),
      repayment_channel_id: Number(selectedRepaymentChannel),
      bank_account_name: accountName,
      disbursement_channel_account_id: Number(disbursementAccountId),
      repayment_channel_account_id: Number(repaymentAccountId),
      repayment_bank_id: Number(repaymentbankName),
      repayment_account_number: repaymentAccountNumber,
      repayment_account_name: repaymentAccountName,
      loan_type_id: Number(loanType),
      core_banking_product_id: Number(coreBankingProductId),
      use_okra: JSON.parse(okraService),
      use_paystack: JSON.parse(paystackService),
      allow_batch_disbursement: JSON.parse(allowBatchDisbursement),
      // repay_principal_at_end_of_tenure: JSON.parse(endOfTenorPrincipal),
      maximum_running_loan: Number(maximumRunningLoan),
      moratorium_type: Number(moratorium_type),
      require_aggregator_approval: JSON.parse(require_aggregator_approval),
      require_bvn_verification: JSON.parse(require_bvn_verification),
      require_deal_slip: JSON.parse(require_deal_slip),
      amortization_type: Number(amortization_type),
    };

    try {
      let response = await postDataWithDotNet(
        ajaxEndpoints.UPDATE_AGGREGATORS_PRODUCT,
        params
      );
      const message = response.message;
      this.setState({
        message: message,
        showMessage: true,
        showErrorMessage: false,
        loading: false,
      });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorProductModal.js",
        },
      });
      this.setState({
        message: errorHandler(error),
        showErrorMessage: true,
        showMessage: false,
        loading: false,
      });
    }
  };

  handleOptionSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      showMessage: false,
      showErrorMessage: false,
      error: false,
      repaymentError: false,
    });
    if (e.target.name === "tenant") {
      this.getCoreBankingProductId(e.target.value);
    }

    if (e.target.name === "coreBankingProductId") {
      let corebankingCurrency = this.state.coreBankingDetails.find(
        (eachDetails) => eachDetails.id === Number(e.target.value)
      );

      this.setState({ currency: corebankingCurrency.currencyCode });
    }
  };

  handleRepayChannelOptionSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      showMessage: false,
      showErrorMessage: false,
      repaymentError: false,
      repayChannelAccountsDetails: false,
      // repaymentAccountId:
      //   this.props?.properties?.dataRecord?.dis_channel_acct_id || "",
      repaymentChannelAccountsList: false,
    });
    this.getRepaymentChannelAccounts(e.target.value);
  };

  handleChannelOptionSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      showMessage: false,
      showErrorMessage: false,
      error: false,
      repaymentError: false,
      channelAccountsDetails: false,
      disbursementAccountId:
        this.props?.properties?.dataRecord?.dis_channel_acct_id || "",
      disbursementChannelAccountsList: false,
    });
    this.getDisbursementChannelAccounts(e.target.value);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      showMessage: false,
      showErrorMessage: false,
    });
  };

  // fetch Disbursement channel list
  getDisbursementChannels = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_DISBURSEMENT_CHANNEL_LIST)
      .then((response) => {
        let disbursementChannelList = [];
        if (response.data.length > 0) {
          disbursementChannelList = [...response.data];
        } else {
          disbursementChannelList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const disbursementChannelListOptions = disbursementChannelList.map(
          (ecosystem, index) => {
            const { id, channel_name } = ecosystem;
            return (
              <option key={index} id={id} value={id}>
                {channel_name}
              </option>
            );
          }
        );

        this.setState({
          disbursementChannelOptions: disbursementChannelListOptions,
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch loan types
  getLoanType = async () => {
    const data = await getDataWithDotNet(ajaxEndpoints.GET_LOAN_TYPE);
    let loanType = data.data;
    const loanTypesListOptions = loanType.map((ecosystem, index) => {
      const { id, name } = ecosystem;
      return (
        <option key={index} id={id} value={id}>
          {name}
        </option>
      );
    });

    this.setState({
      loanTypeList: loanTypesListOptions,
    });
  };
  // fetch repayment channel list
  getRepaymentChannels = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_REPAYMENT_CHANNEL_LIST)
      .then((response) => {
        let repaymentChannelList = [];
        if (response.data.length > 0) {
          repaymentChannelList = [...response.data];
        } else {
          repaymentChannelList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const repaymentChannelListOptions = repaymentChannelList.map(
          (ecosystem, index) => {
            const { id, channel_name } = ecosystem;
            return (
              <option key={index} id={id} value={id}>
                {channel_name}
              </option>
            );
          }
        );

        this.setState({
          repaymentChannelOptions: repaymentChannelListOptions,
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // validate account number
  onRepayAccountNumberInput = (e) => {
    this.setState({ repaymentError: false, repaymentAccountNumber: "" });
    let number = e.target.value;

    if (this.state.repaymentbankName === "") {
      this.setState({ repaymentError: `Please select a bank` });
      return;
    }

    this.setState({ repaymentAccountNumber: number }, () => {
      if (number.length === 10 && !this.state.repaymentAccountLoading) {
        this.setState({ repaymentAccountLoading: true });
        //validate account number and bank name
        let data = {
          bank_code: this.state.repaymentBankCode,
          bank_account_num: number,
        };
        this.validateRepaymentAccount(data);
      }
    });
  };

  validateRepaymentAccount = (data) => {
    ajaxMethods
      .post(ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            repaymentAccountName: response.data.bank_account_name,
            repaymentAccountLoading: false,
          });
        } else if (response.status === 400) {
          this.setState({
            repaymentError: response.data.message,
            repaymentAccountLoading: false,
          });
        }
        if (response.status >= 500) {
          this.setState({
            repaymentError: "Unable to connect to Advancly services.",
            repaymentAccountLoading: false,
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // validate account number
  onAccountNumberInput = (e) => {
    this.setState({ error: false, accountName: "" });
    let number = e.target.value;

    if (this.state.bankName === "") {
      this.setState({ error: `Please select a bank` });
      return;
    }

    this.setState({ accountNumber: number }, () => {
      if (number.length === 10 && !this.state.accountLoading) {
        this.setState({ accountLoading: true });
        let data = {
          bank_code: this.state.bankCode,
          bank_account_num: number,
        };
        this.validateAccount(data);
      }
    });
  };

  validateAccount = (data) => {
    ajaxMethods
      .post(ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            accountName: response.data.bank_account_name,
            accountLoading: false,
          });
        } else if (response.status === 400) {
          this.setState({
            error: response.data.message,
            accountLoading: false,
          });
        }
        if (response.status >= 500) {
          this.setState({
            error: "Unable to connect to Advancly services.",
            accountLoading: false,
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch accounts for a specific disburse channel
  getDisbursementChannelAccounts = (number) => {
    this.setState({ loading: true });
    ajaxMethods
      .get(
        ajaxEndpoints.GET_DISBURSEMENT_ACCOUNTS +
          `?disbursement_channel_id=${number}`
      )
      .then((response) => {
        this.setState({ loading: false });
        if (response.status_code === 200 || response.status_code === 201) {
          if (response.data) {
            this.setState({ channelAccountsDetails: response.data });
          } else {
            this.setState({ channelAccountsDetails: false });
          }
          const disbursementChannelAccountsList =
            this.state.channelAccountsDetails.map((ecosystem, index) => {
              const { id, account_number, account_name, bank_name } = ecosystem;
              const accountData = `${account_number} / ${account_name} / ${bank_name}`;
              return (
                <option key={index} id={id} value={id}>
                  {accountData}
                </option>
              );
            });
          this.setState({
            disbursementChannelAccountsList: disbursementChannelAccountsList,
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch accounts for a specific disburse channel
  getRepaymentChannelAccounts = (number) => {
    this.setState({ loading: true });
    ajaxMethods
      .get(
        ajaxEndpoints.GET_REPAYMENT_ACCOUNTS + `?repayment_channel_id=${number}`
      )
      .then((response) => {
        this.setState({ loading: false });
        if (response.status_code === 200 || response.status_code === 201) {
          if (response.data) {
            this.setState({ repayChannelAccountsDetails: response.data });
          } else {
            this.setState({ repayChannelAccountsDetails: false });
          }
          const repaymentChannelAccountsList =
            this.state.repayChannelAccountsDetails.map((ecosystem, index) => {
              const { id, account_number, account_name, bank_name } = ecosystem;
              const accountData = `${account_number} / ${account_name} / ${bank_name}`;
              return (
                <option key={index} id={id} value={id}>
                  {accountData}
                </option>
              );
            });
          this.setState({
            repaymentChannelAccountsLists: repaymentChannelAccountsList,
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  getMoratoriumTypes = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_MORATORIUM_TYPES)
      .then((response) => {
        if (response.status_code === 200) {
          const moratoriumTypeList = response.data.map((types, index) => {
            const { id, name } = types;
            return (
              <option key={index} id={id} value={id}>
                {name}
              </option>
            );
          });
          this.setState({
            moratoriumTypeList: moratoriumTypeList,
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // fetch product category list
  getBankCode = (e) => {
    let number = e.target.value;
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST + `?bank_id=${number}`)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];

          this.setState({ bankCode: res.code });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch product category list
  getRepaymentBankCode = (e) => {
    let number = e.target.value;
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST + `?bank_id=${number}`)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];

          this.setState({ repaymentBankCode: res.code });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // fetch accounts for a product id by specific loan type
  getCoreBankingProductId = (tenantId) => {
    this.setState({ loading: true });
    ajaxMethods
      .get(ajaxEndpoints.GET_CBA_PRODUCT_ID + `?TenantId=${tenantId}`)
      .then((response) => {
        this.setState({ loading: false });

        if (response) {
          this.setState({ coreBankingDetails: response });
        }
        const coreBankingDetailsList = this.state.coreBankingDetails.map(
          (products, index) => {
            const { id, name } = products;
            return (
              <option key={index} id={id} value={id}>
                {name}
              </option>
            );
          }
        );
        this.setState({
          coreBankingProductList: coreBankingDetailsList,
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  render() {
    const {
      scene,
      productCategoryOptions,
      aggregatorOptions,
      selectedAggregatorId,
      selectedProductId,
      maxTenure,
      maxAmount,
      productCode,
      productName,
      InterestRate,
      selectedrepaymentFrequency,
      allrepaymentFrequencys,
      selectedRepayerType,
      allRepayerType,
      selectedDisbursementType,
      alldisbursementTypes,
      selectedScheduleType,
      allScheduleType,
      showMessage,
      showErrorMessage,
      message,
      bankOptionsList,
      disbursementChannelOptions,
      repaymentChannelOptions,
      selectedDisbursementChannel,
      selectedRepaymentChannel,
      moratoriumPeriod,
      bankName,
      accountNumber,
      error,
      accountLoading,
      tenant,
      paystackService,
      okraService,
      disbursementAccountId,
      disbursementChannelAccountsList,
      repaymentAccountId,
      repaymentChannelAccountsLists,
      repaymentError,
      repaymentAccountNumber,
      repaymentAccountLoading,
      repaymentAccountName,
      repaymentbankName,
      loanType,
      loanTypeList,
      coreBankingProductId,
      coreBankingProductList,
      loading,
      allowBatchDisbursement,
      maximumRunningLoan,
      moratorium_type,
      moratoriumTypeList,
      currency,
      require_aggregator_approval,
      require_bvn_verification,
      require_deal_slip,
      amortization_type,
      tenantList,
    } = this.state;

    return (
      <div
        className="Modal__dialog  lg-modal animated slideInDown"
        role="document"
      >
        <div
          className="IssueModal Modal__content"
          style={{ overflow: "hidden" }}
        >
          <div className="Modal__body">
            <h3
              className="Modal__title m-b-1 p-x-2 p-y-1"
              style={{ backgroundColor: "#713fff", color: "#fff" }}
            >
              {this.props.properties.dataRecord && <span>Edit</span>}{" "}
              Aggregator's Products
            </h3>
            {/* Product Form */}
            {(() => {
              switch (scene) {
                case "form":
                  return (
                    <form>
                      <div className="row">
                        <div className="col-xs-4">
                          <label>Aggregator</label>

                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="selectedAggregatorId"
                              placeholder="Select Aggregator"
                              id="selectedAggregatorId"
                              value={selectedAggregatorId}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value="">Select Aggregator</option>
                              {aggregatorOptions}
                            </select>
                            <i className="adv-aggregator-icon fas fa-building"></i>{" "}
                          </div>
                        </div>

                        <div className="col-xs-4">
                          <label>Product Category</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="selectedProductId"
                              placeholder="Select a Product"
                              id="selectedProductId"
                              value={selectedProductId}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value="">Select a Product</option>
                              {productCategoryOptions}
                            </select>
                            <i className="adv-aggregator-icon fas fa-university"></i>{" "}
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Product Code</label>
                          <div className="form-group inputWithIcon">
                            <input
                              type="text"
                              className="form-control"
                              name="productCode"
                              value={productCode}
                              onChange={this.handleChange}
                            />
                            <i className="adv-aggregator-icon fas fa-code"></i>{" "}
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-1">
                        <div className="col-xs-4">
                          <label>Product Name</label>
                          <div className="form-group inputWithIcon">
                            <input
                              type="text"
                              className="form-control"
                              name="productName"
                              value={productName}
                              onChange={this.handleChange}
                            />
                            <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Interest Rate</label>
                          <div className="form-group inputWithIcon">
                            <input
                              type="number"
                              className="form-control"
                              value={InterestRate}
                              name="InterestRate"
                              onChange={this.handleChange}
                            />
                            <i className="adv-aggregator-icon fas fa-percent"></i>{" "}
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Maximum Tenure</label>
                          <div className="form-group inputWithIcon">
                            <input
                              type="number"
                              className="form-control"
                              name="maxTenure"
                              value={maxTenure}
                              onChange={this.handleChange}
                            />
                            <i className="adv-aggregator-icon fas fa-clock"></i>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="row m-t-1">
                        <div className="col-xs-4">
                          <label>Schedule Type</label>
                          <div className="form-group ">
                            <select
                              className="round user__form form-control"
                              name="selectedScheduleType"
                              id="selectedScheduleType"
                              value={selectedScheduleType}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {allScheduleType}
                            </select>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Repayer Type</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="selectedRepayerType"
                              id="selectedRepayerType"
                              value={selectedRepayerType}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {allRepayerType}
                            </select>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Disbursement Type</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="selectedDisbursementType"
                              id="selectedDisbursementType"
                              value={selectedDisbursementType}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {alldisbursementTypes}
                            </select>
                            <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="row m-t-2">
                        <div className="col-xs-4">
                          <label>
                            Max Amount {formatMoney(maxAmount, currency)}
                          </label>
                          <div className="form-group inputWithIcon">
                            <input
                              type="number"
                              className="form-control"
                              data-form="maxAmount"
                              name="maxAmount"
                              value={maxAmount}
                              onChange={this.handleChange}
                            />
                            <i className="adv-aggregator-icon fas fa-money"></i>{" "}
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Repayment Frequency</label>
                          <div className="form-group ">
                            <select
                              className="round user__form form-control"
                              name="selectedrepaymentFrequency"
                              placeholder="Select a Product"
                              id="selectedrepaymentFrequency"
                              value={selectedrepaymentFrequency}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {allrepaymentFrequencys}
                            </select>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Disbursement Channel</label>
                          <div className="form-group">
                            <select
                              className="round user__form form-control"
                              name="selectedDisbursementChannel"
                              value={selectedDisbursementChannel}
                              onChange={(e) =>
                                this.handleChannelOptionSelect(e)
                              }
                            >
                              <option value=""></option>
                              {disbursementChannelOptions}
                            </select>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="row m-t-2">
                        <div className="col-xs-4">
                          <label>Disbursement Channel Account</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="disbursementAccountId"
                              value={disbursementAccountId}
                              disabled={!disbursementChannelAccountsList}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {disbursementChannelAccountsList}
                            </select>
                            <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Repayment Channel </label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="selectedRepaymentChannel"
                              value={selectedRepaymentChannel}
                              onChange={(e) =>
                                this.handleRepayChannelOptionSelect(e)
                              }
                            >
                              <option value=""></option>
                              {repaymentChannelOptions}
                            </select>
                            <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Repayment Channel Account</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="repaymentAccountId"
                              value={repaymentAccountId}
                              disabled={!repaymentChannelAccountsLists}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {repaymentChannelAccountsLists}
                            </select>
                            <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                          </div>
                        </div>
                      </div>
                      <div className="row m-t-2">
                        <div className="col-xs-4">
                          <label>Okra Service</label>
                          <div className="form-group">
                            <select
                              className="round user__form form-control form-load"
                              name="okraService"
                              value={okraService}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option></option>
                              <option value={true}>True</option>
                              <option value={false}>False</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xs-4">
                          <label>Paystack Service</label>
                          <div className="form-group">
                            <select
                              type="text"
                              className="round user__form form-control form-load"
                              name="paystackService"
                              value={paystackService}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option></option>
                              <option value={true}>True</option>
                              <option value={false}>False</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <label>Core Banking Tenant</label>

                          <div className="form-group">
                            <select
                              type="text"
                              className="round user__form form-control form-load"
                              name="tenant"
                              value={tenant}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {tenantList}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-2">
                        <div className="col-xs-4">
                          <label>Batch Disbursement</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="allowBatchDisbursement"
                              value={allowBatchDisbursement}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option></option>
                              <option value={true}>True</option>
                              <option value={false}>False</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xs-4">
                          <label>Loan Type </label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="loanType"
                              value={loanType}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {loanTypeList}
                            </select>
                            <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                          </div>
                        </div>

                        <div className="col-xs-4">
                          <label>Core Banking Product</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="coreBankingProductId"
                              value={coreBankingProductId}
                              disabled={!coreBankingProductList}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              {coreBankingProductList}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-2">
                        <div className="col-xs-4">
                          <label>Currency</label>

                          <div className="form-group inputWithIcon">
                            <input
                              type="text"
                              className="form-control"
                              name="currency"
                              value={currency}
                              readOnly
                            />

                            <i className="adv-aggregator-icon fas fa-money "></i>
                          </div>
                        </div>

                        <div className="col-xs-4">
                          <label>Moratorium Type</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="moratorium_type"
                              value={
                                selectedrepaymentFrequency ==
                                repaymentFrequencys[3]?.repayment_Type
                                  ? 1
                                  : moratorium_type
                              }
                              onChange={(e) => this.handleOptionSelect(e)}
                              disabled={
                                selectedrepaymentFrequency ===
                                repaymentFrequencys[3]?.repayment_Type
                              }
                            >
                              <option value=""></option>
                              {moratoriumTypeList}
                            </select>
                          </div>
                        </div>

                        {(moratorium_type === 3 || moratorium_type === "3") &&
                          selectedrepaymentFrequency !==
                            repaymentFrequencys[3]?.repayment_Type && (
                            <div className="col-xs-4">
                              <label>Moratorium Period</label>
                              <div className="form-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  data-form="moratoriumPeriod"
                                  name="moratoriumPeriod"
                                  value={moratoriumPeriod}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                          )}

                        <div className="col-xs-4">
                          <label>Max Running Loan(s)</label>
                          <div className="form-group inputWithIcon">
                            <input
                              type="number"
                              className="form-control"
                              name="maximumRunningLoan"
                              value={maximumRunningLoan}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-2">
                        <div className="col-xs-4">
                          <label>Require Aggr. Approval</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="require_aggregator_approval"
                              value={require_aggregator_approval}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              <option value={true}>TRUE</option>
                              <option value={false}>FALSE</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xs-4">
                          <label>Amortization Type</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="amortization_type"
                              value={amortization_type}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              <option value={0}>
                                Equal Principle Payments
                              </option>
                              <option value={1}>Equal Installments</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-xs-4">
                          <label>Allow Sending of Deal Slip</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="require_deal_slip"
                              value={require_deal_slip}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              <option value={true}>TRUE</option>
                              <option value={false}>FALSE</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row m-t-2">
                        <div className="col-xs-4">
                          <label>Require BVN Verification</label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="require_bvn_verification"
                              value={require_bvn_verification}
                              onChange={(e) => this.handleOptionSelect(e)}
                            >
                              <option value=""></option>
                              <option value={true}>TRUE</option>
                              <option value={false}>FALSE</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {repaymentError && (
                        <span className="text-danger p-b-2">
                          {repaymentError}
                        </span>
                      )}
                      <div className="row m-t-2">
                        <div className="col-xs-6">
                          <label>Repayment Bank Name </label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="repaymentbankName"
                              value={repaymentbankName}
                              onChange={(e) => this.handleOptionSelect(e)}
                              onInput={this.getRepaymentBankCode}
                            >
                              <option value=""></option>
                              {bankOptionsList}
                            </select>
                            <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <label>Repayment Account number</label>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              data-form="repaymentAccountNumber"
                              name="repaymentAccountNumber"
                              maxLength="10"
                              value={repaymentAccountNumber}
                              onChange={this.handleChange}
                              onInput={this.onRepayAccountNumberInput}
                            />{" "}
                          </div>
                        </div>
                      </div>
                      <div className="row m-t-1">
                        <div className="col-xs-6">
                          <div className="form-group">
                            <label>Repayment Account name</label>{" "}
                            {repaymentAccountLoading && (
                              <span className="text-primary">Loading</span>
                            )}
                            <input
                              type="text"
                              name="repaymentAccountName"
                              className="form-control"
                              value={repaymentAccountName}
                              defaultValue={repaymentAccountName}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <label>Disbursement Bank Name </label>
                          <div className="form-group inputWithIcon">
                            <select
                              className="round user__form form-control"
                              name="bankName"
                              value={bankName}
                              onChange={(e) => this.handleOptionSelect(e)}
                              onInput={this.getBankCode}
                            >
                              <option value=""></option>
                              {bankOptionsList}
                            </select>
                            <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                          </div>
                        </div>
                      </div>
                      {error && (
                        <span className="text-danger p-b-2">{error}</span>
                      )}
                      <div className="row m-t-1">
                        <div className="col-xs-6">
                          <label>Disbursement Account number</label>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              data-form="accountNumber"
                              name="accountNumber"
                              maxLength="10"
                              value={accountNumber}
                              onChange={this.handleChange}
                              onInput={this.onAccountNumberInput}
                            />{" "}
                          </div>
                        </div>
                        <div className="col-xs-6">
                          <label>Disbursement Account name</label>{" "}
                          {accountLoading && (
                            <span className="text-primary">Loading</span>
                          )}
                          <input
                            type="text"
                            name="accountName"
                            className="form-control"
                            value={this.state.accountName}
                            defaultValue={this.state.accountName}
                            disabled
                          />
                        </div>
                      </div>

                      {showMessage && (
                        <div className="row m-t-1 m-b-1">
                          <div className="col-xs-4"></div>
                          <div className="col-xs-4">
                            <span className="text-success p-b-2">
                              {message}
                            </span>
                          </div>
                          <div className="col-xs-4"></div>
                        </div>
                      )}
                      {showErrorMessage && (
                        <div className="row m-t-1 m-b-1">
                          <div className="col-xs-4"></div>
                          <div className="col-xs-4">
                            <span className="text-danger p-b-2">{message}</span>
                          </div>
                          <div className="col-xs-4"></div>
                        </div>
                      )}

                      <div className="row m-t-1 m-b-1">
                        <div className="col-xs-4"></div>
                        <div className="col-xs-4">
                          <div className="grid__full">
                            {!this.props.properties.dataRecord && (
                              <button
                                type="submit"
                                className="zoom-element btn login-btn"
                                onClick={this.handleSubmit}
                              >
                                {loading ? "Loading..." : "Submit"}
                              </button>
                            )}
                            {this.props.properties.dataRecord && (
                              <button
                                type="submit"
                                className="zoom-element btn login-btn"
                                onClick={this.handleEdit}
                              >
                                {loading ? "Loading..." : "Update"}
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="col-xs-4"></div>
                      </div>
                    </form>
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}
