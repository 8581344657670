import { Fragment, useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import useFunderDetails from "../../../../custom-hooks/useFunderDetails";
import { appInsights } from "../../../../config/appInsights";
import BackButtonComponent from "../../../../NewComponents/BackButton/BackButton.component";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import InvestmentProspectGeneralTab from "./component/InvestmentProspectGeneralTab";
import InvestmentProspectCommentsTab from "./component/InvestmentProspectCommentsTab";
import InvestmentProspectMessageTab from "./component/InvestmentProspectMessageTab";
import { IInvestmentProspect } from "../../../../interfaces/investmentProspect";
import {useInvestmentProspectsDetailsById} from "../../../../custom-hooks/useDebtFund";
import Loader from "../../../../NewComponents/Loader/Loader.component";

export default function InvestmentProspectTabs() {
  const [activeTab, setActiveTab] = useState("general");
  const location = useLocation<IInvestmentProspect>();
  const match = useRouteMatch();
  const { id } = match?.params as { id: string };


  useEffect(() => {
    appInsights.trackPageView({
      name: "InvestmentProspectTabs.tsx",
      isLoggedIn: true,
    });
  }, []);



  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />{" "}
        </h3>
      </div>
      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="General"
          id="general"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Comments"
          id="comments"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Send In-App Comment"
          id="send-inApp-message"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div>
          {(() => {
              switch (activeTab) {
                  case "general":
                      return (
                          <InvestmentProspectGeneralTab  />
                      );

                  case "comments":
                      return <InvestmentProspectCommentsTab />;

                  case "send-inApp-message":
                      return <InvestmentProspectMessageTab id={Number(id)} />;

                  default:
                      return null;
              }
          })()}
      </div>
    </Fragment>
  );
}
