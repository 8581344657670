import { useState } from "react";

import { useDispatch } from "react-redux";
import { showModal } from "../../redux/actions/modal";
import { getData } from "../../newApis/apiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { appInsights } from "../../config/appInsights";
import { getMimeType, splitAtLastOccurrenceOfItem } from "./helper";
import { updateDocumentViewModalRecord } from "../../redux/actions/documenViewer";

const useDocumentViewer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const prepareDownloadLink = (content: string, mimeType: string) => {
    const linkSource = `data:${mimeType};base64,${content}`;

    dispatch(
      updateDocumentViewModalRecord({
        base64Url: linkSource,
        mimeType: mimeType,
      })
    );
  };

  const renderWithFilePath = async (filePath: string, mimeType: string) => {
    try {
      dispatch(
        showModal({
          modalIdentifier: "documentviewer",
          dataRecord: { documentUrl: "", type: "", isLoading: true },
          action: "customrange",
          type: "alert",
        })
      );
      const data = await getData(
        `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${filePath}`
      );

      prepareDownloadLink(data.base64_string, mimeType);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CustomViewDocumentHandler.ts",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: `Unable to view document: ${error?.response?.data?.message}`,
          },
        })
      );
    }
    setIsLoading(false);
  };

  const handleViewDocument = (isFullUrl: boolean, url: string) => {
    const result = splitAtLastOccurrenceOfItem(url, ".");
    const mimeType = getMimeType(result);

    if (!mimeType) {
      return dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: `Unable to render documents ending with .${
              url.split(".")[1]
            }`,
          },
        })
      );
    }

    setIsLoading(true);
    if (isFullUrl) {
      setIsLoading(false);
      return dispatch(
        showModal({
          modalIdentifier: "documentviewer",
          dataRecord: { documentUrl: url, mimeType },
          action: "customrange",
          type: "alert",
        })
      );
    }

    return renderWithFilePath(url, mimeType);
  };

  return { handleViewDocument, isLoading };
};

export default useDocumentViewer;
