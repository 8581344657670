import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";

const getAggOferLetterAndSlaHistory = async ({ id }) => {
  const { data } = await getData(
    `${ajaxEndpoints.AGGREGATOR_OFFERLETER_SLA_HISTORY}?aggregatorId=${id}`
  );
  return data;
};

export default function useAggOferLetterAndSlaHistory({ id, viewHistory }) {
  return useQuery(
    [{ id }, "getAggOferLetterAndSlaHistory"],
    getAggOferLetterAndSlaHistory,
    {
      enabled: viewHistory ? true : false,
    }
  );
}
