import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

type TParams = {
  pageNumber: number;
  size: number;
  searchBy?: number;
  startDate?: string;
  endDate?: string;
  searchValue?: string;
  FunderId?: number;
  aggregatorId?: number;
};

const getFunderDetails = async ({
  pageNumber,
  size,
  searchBy,
  startDate,
  endDate,
  searchValue,
  FunderId,
  aggregatorId,
}: TParams) => {
  let queryParams = `?Page=${pageNumber}&PageSize=${size}`;
  if (searchBy) {
    queryParams = `${queryParams}&FunderType=${searchBy}`;
  }
  if (startDate) {
    queryParams = `${queryParams}&from_date=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&to_date=${endDate}`;
  }
  if (searchValue) {
    queryParams = `${queryParams}&FunderName=${searchValue}`;
  }
  if (FunderId) {
    queryParams = `${queryParams}&FunderId=${FunderId}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${aggregatorId}`;
  }
  const data = await getDataWithDotNet(
    `${ajaxEndpoints.ALL_FUNDER}${queryParams}`
  );

  return data;
};

export default function useFunderDetails({
  pageNumber,
  size,
  searchBy,
  startDate,
  endDate,
  searchValue,
  FunderId,
  aggregatorId,
}: TParams) {
  return useQuery(
    [
      {
        pageNumber,
        size,
        searchBy,
        startDate,
        endDate,
        searchValue,
        FunderId,
        aggregatorId,
      },
      "getFunderDetails",
    ],
    getFunderDetails
  );
}
