import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAnAggregatorOfferLettersAndSlas = async (aggregator_id) => {
  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.AN_AGGREGATOR_OFFER_LETTERS_AND_SLAS}/${aggregator_id}`
  );
  return data;
};

export default function useAnAggregatorOfferLettersAndSlas(aggregator_id) {
  return useQuery(
    [aggregator_id, "getAnAggregatorOfferLettersAndSlas"],
    getAnAggregatorOfferLettersAndSlas,
    {
      refetchOnWindowFocus: false,
    }
  );
}
