import { Fragment, useState } from "react";
import Paginator from "../../Pagination/Pagination.component";
import ButtonLoader, { Loader } from "../../../UI/Loaders/Loaders";
import useAllApprovableBatchRepayment from "../../../custom-hooks/useAllApprovableBatchRepayment";
import { useDispatch } from "react-redux";
import { showModal, hideModal } from "../../../redux/actions/modal";
import { setToast } from "../../../redux/actions/toast";
import { postDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { formatMoney } from "../../../helpers/formatter";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { appInsights } from "../../../config/appInsights";
import { getTokenFromLocalStorage } from "../../../helpers/localStorage";
import { BASE_URL } from "../../../appConstants";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import { repaymentSource } from "../util";
import { useHistory } from "react-router-dom";

import styles from "./ApprovableBatchRepayment.module.scss";

const ApprovableBatchRepayment = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size] = useState(20);
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  // Component State
  const [state, setState] = useState<{
    checkStatus: string;
    loansForRepayment: any[];
    totalAmount: number;
  }>({
    // loading: false,
    checkStatus: "off",
    loansForRepayment: [],
    totalAmount: 0,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { data, status, error, refetch } = useAllApprovableBatchRepayment({
    Page: pageNumber,
    PageSize: size,
  });

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  const resetLoansForRepayment = () => {
    setState({
      ...state,
      loansForRepayment: [],
      totalAmount: 0,
      checkStatus: "off",
    });
  };

  // repay Selected Loans
  const repaySelectedLoans = (e: any) => {
    e.preventDefault();
    const loansForRepayment = state.loansForRepayment;
    if (loansForRepayment.length === 0)
      return dispatch(setToast("warning", "You have not selected any loans"));
    showModal({
      modalIdentifier: "batchloanrepayment",
      dataId: e.target.dataset["ref"],
      action: "initiatebatchrepayment",
      subModal: "multiple",
      loans: state.loansForRepayment,
      totalAmount: state.totalAmount,
      handleApproveRepayment,
    });
  };

  // Multiple Checkbox
  const checkAllLoansForRepayment = (e: any) => {
    e.preventDefault();
    if (e.target.dataset["state"] === "checked") {
      const allLoans = document.querySelectorAll(".loans_for_repayment") as any;
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }

      e.target.dataset["state"] = "unchecked";

      setState({
        ...state,
        checkStatus: "off",
        totalAmount: 0,
        loansForRepayment: [],
      });

      return;
    }

    let totalAmount = 0;
    let loans = [];
    const allLoans = document.querySelectorAll(".loans_for_repayment") as any;
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loanId = Number(allLoans[i].dataset["ref"]);

      loans.push(loanId);
      totalAmount += parseFloat(
        JSON.parse(allLoans[i].dataset["record"]).repayment_amount
      );
    }

    e.target.dataset["state"] = "checked";

    setState({
      ...state,
      checkStatus: "on",
      totalAmount,
      loansForRepayment: [...state.loansForRepayment, ...loans],
    });
  };

  // Single Checkbox
  const checkboxHandler = ({
    dataRecord: { repayment_amount },
    eventTarget,
  }: any) => {
    const loanId = Number(eventTarget.dataset["ref"]);
    if (eventTarget.checked === false) {
      const loansForRepayment = state.loansForRepayment;
      const newLoansForRepayment = loansForRepayment.filter((loan: any) => {
        return loan !== loanId;
      });

      return setState({
        ...state,
        loansForRepayment: [...newLoansForRepayment],
        totalAmount: state.totalAmount - parseFloat(repayment_amount),
      });
    }

    setState({
      ...state,
      loansForRepayment: [...state.loansForRepayment, Number(loanId)],
      totalAmount: state.totalAmount + parseFloat(repayment_amount),
    });
  };

  const handleApproveRepayment = async (loans: any) => {
    hideModal();
    setLoading(true);
    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.INITIATE_BATCH_REPAYMENT,
        loans
      );

      setLoading(false);
      setTimeout(() => {
        showModal({
          action: "loansuccess",
          modalIdentifier: "batchloanrepayment",
          success: response?.message ? response?.message : "",
          fileUploadError: response?.data?.failure
            ? response?.data?.failure
            : "",
        });
        setTimeout(() => {
          hideModal();
        }, 9000);
      });
      resetLoansForRepayment();
      refetch();
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ApprovableBatchRepayment.jsx",
        },
      });
      setLoading(false);
      if (error.response && error?.response?.data?.data?.failure) {
        showModal({
          action: "loansuccess",
          modalIdentifier: "batchloanrepayment",
          resetLoansForRepayment,
          fileUploadError: error?.response?.data?.data?.failure,
          loading,
        });
        setTimeout(() => {
          hideModal();
        }, 9000);
      } else {
        setSubmitError(errorHandler(error));
      }
      resetLoansForRepayment();
    }
  };

  const handleDownload = (e: any) => {
    e.preventDefault();
    setDownloadLoading(true);

    let token = getTokenFromLocalStorage();

    let queryParams = `?Page=${pageNumber}&PageSize=${size}`;

    fetch(
      BASE_URL +
        `${ajaxEndpoints.DOWNLOAD_APPROVABLE_BATCH_LOAN_REPAYMENT}${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${`ApprovableBatchRepayment.csv`}`);
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error: any) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ApprovableBatchRepayment.jsx (Download)",
          },
        });

        setDownloadLoading(false);
      });
  };

  const handleView = (item: any) => {
    showModal({
      modalIdentifier: "viewrepaymentdocuments",
      dataRecord: item,
    });
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Approvable Repayment
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Approvable Repayment..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              approvable repayment, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th></th>
                  <th>Loan ID</th>
                  <th>Aggregator Name</th>
                  <th>Loan Ref</th>
                  <th>Source</th>
                  <th>Document</th>
                  <th>Repayment Amount</th>
                  <th>Repayment Date</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request: any, _id: number) => {
                    const {
                      aggregator_name,
                      loan_ref,
                      repayment_amount,
                      repayment_date,
                      repayment_source,
                      repayment_id,
                      loan_id,
                      currency,
                      uploaded_document_path,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>
                          <input
                            type="checkbox"
                            data-record={JSON.stringify(request)}
                            data-id={repayment_id}
                            data-ref={repayment_id}
                            defaultChecked={state.loansForRepayment.includes(
                              repayment_id
                            )}
                            onClick={(e) =>
                              checkboxHandler({
                                dataRecord: request,
                                eventTarget: e.target,
                              })
                            }
                            className={`Loan loans_for_repayment`}
                          />
                        </td>
                        <td>{loan_id}</td>
                        <td>{aggregator_name}</td>
                        <td>{loan_ref}</td>
                        <td>
                          {!repayment_source ? (
                            "NIL"
                          ) : (
                            <span
                              className={attachClassNameToTag(repayment_source)}
                            >
                              {attachClassNameToTag(repayment_source)}
                            </span>
                          )}
                        </td>
                        <td>
                          {repayment_source ===
                          repaymentSource.CREDIT_OFFICER ? (
                            "NIL"
                          ) : (
                            <span
                              className={styles.ViewDetails}
                              onClick={() => handleView(uploaded_document_path)}
                            >
                              View
                            </span>
                          )}
                        </td>
                        <td>
                          {formatMoney(Number(repayment_amount), currency)}
                        </td>
                        <td>{repayment_date?.substring(0, 10)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="dataTables_wrapper">
        {submitError && <Alert message={submitError} />}
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          <div className="block-header">
            <div className="flex-row m-b-2 m-t-1">
              <h3 className="m-l-1 m-b-1 flex-row">
                {" "}
                Initiate Repayment Request
              </h3>

              <div className="flex-row m-b-1">
                <div className="m-r-1">
                  <div className="rounded-border flex-row">
                    <div
                      data-state="unchecked"
                      onClick={checkAllLoansForRepayment}
                    >
                      <i
                        className={`adv-icon fas fa-toggle-${state.checkStatus} fa-2x`}
                      />
                    </div>
                    &nbsp; <span>Select all</span>
                  </div>
                </div>
                <div className="m-r-1 rounded-border">
                  Selected: {state?.loansForRepayment?.length}
                </div>

                <div className="rounded-border">
                  Total Amount: {state.totalAmount}
                </div>
              </div>

              <div className="m-b-1">
                <button
                  id="create"
                  data-id="create-role"
                  data-action="create"
                  className="create zoom-element btn login-btn"
                  onClick={repaySelectedLoans}
                >
                  <i className="far fa-credit-card" /> Proceed
                  {loading && <ButtonLoader />}
                </button>
                <button
                  id="create"
                  className="create zoom-element btn login-btn ml-2"
                  onClick={handleDownload}
                >
                  <i className="fas fa-download m-r-1" /> Download
                  {downloadLoading && <ButtonLoader />}
                </button>
              </div>
            </div>
          </div>

          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          <Paginator
            size={size}
            page={pageNumber}
            count={count}
            changeCurrentPage={(pageNumber: number) =>
              setPageNumber(pageNumber)
            }
          />
        </div>
      </div>
    </Fragment>
  );
};
export default ApprovableBatchRepayment;
