import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getDataWithDotNet } from "../newApis/dotNetApiMethods";

const getAggregatorOnboardingStatus = async ({
  Page,
  PageSize,
  onboardingStatus,
  SubsequentOnboardingStatus,
  AggregatorId,
  StartDate,
  EndDate,
}) => {
  let queryParams = `?Page=${Page}&PageSize=${PageSize}`;

  if (onboardingStatus) {
    queryParams = `${queryParams}&${onboardingStatus}`;
  }

  if (SubsequentOnboardingStatus) {
    queryParams = `${queryParams}&${SubsequentOnboardingStatus}`;
  }
  if (AggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${AggregatorId}`;
  }

  if (StartDate && EndDate) {
    queryParams = `${queryParams}&StartDate=${StartDate}&EndDate=${EndDate}`;
  }

  const { data } = await getDataWithDotNet(
    `${ajaxEndpoints.GET_AGGREGATOR_ONBOARDING_STATUS}${queryParams}`
  );

  return data;
};

export default function useAggregatorOnboardingStatus({
  Page,
  PageSize,
  onboardingStatus,
  SubsequentOnboardingStatus,
  AggregatorId,
  StartDate,
  EndDate,
}) {
  return useQuery(
    [
      {
        Page,
        PageSize,
        onboardingStatus,
        SubsequentOnboardingStatus,
        AggregatorId,
        StartDate,
        EndDate,
      },
      "getAggregatorOnboardingStatus",
    ],
    getAggregatorOnboardingStatus
  );
}
