import { useEffect, useState } from "react";

import { NavLink, useHistory } from "react-router-dom";
import { appInsights } from "../../config/appInsights";
import useAllCompanyWallets from "../../custom-hooks/useAllCompanyWallets";
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import { TCompanyWalletParams } from "./types";

interface ICompanyWallets {
  id: number;
  accountName: string;
  accountNumber: string;
  bankName: string;
  consumerType: string;
  currency: string;
  walletId: string;
  walletProvider: string;
  walletProviderId: number;
}

const CompanyWallets = () => {
  const [searchQuery, setSearchQuery] = useState<TCompanyWalletParams>({
    ConsumerType: "COMPANY",
    Page: 1,
    PageSize: 1,
  });

  const history = useHistory();

  const {
    data: companyWallets,
    status,
    error,
  } = useAllCompanyWallets(searchQuery);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Company Wallets - (CompanyWallets.jsx)",
      isLoggedIn: true,
    });
  }, []);

  let RenderedComponent = null;
  if (!companyWallets && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Company Wallets
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading wallet providers..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Company Wallets, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (companyWallets &&
        companyWallets?.data.data &&
        companyWallets?.data.data.length === 0) ||
      (companyWallets?.data?.data && !companyWallets?.data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Company
              Wallets
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Provider</th>
                  <th>Currency</th>
                  <th>Consumer Type</th>
                  <th>Bank Name</th>
                  <th>Account Name</th>
                  <th>Account Number</th>
                  <th>Wallet Id</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {companyWallets?.data?.data &&
                  companyWallets?.data?.data?.map(
                    (request: ICompanyWallets, _id: number) => {
                      return (
                        <tr key={_id}>
                          <td>
                            {(searchQuery?.Page - 1) * searchQuery?.PageSize +
                              (_id + 1)}
                          </td>
                          <td>{request.walletProvider}</td>
                          <td>{request.currency}</td>
                          <td>{request.consumerType}</td>
                          <td>{request.bankName}</td>
                          <td>{request.accountName}</td>
                          <td>{request.accountNumber}</td>
                          <td>{request.walletId}</td>
                          <td>
                            <span
                              className="details btn-manage zoom-element btn-slim"
                              onClick={() =>
                                history.push(
                                  `/company-wallets/transactions?accountNumber=${request?.accountNumber}&provider=${request.walletProvider}`
                                )
                              }
                            >
                              View Details
                            </span>
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view animated speed-1x fadeInRight">
        <div className="row m-x-1">
          <div className="table-view speed-1x animated fadeInRight">
            <div className="d-flex flex-row flex-wrap mb-4">
              <h3 className="page-title">Company Wallets </h3>
            </div>
            {RenderedComponent}
            <Paginator
              size={searchQuery.PageSize}
              page={searchQuery.Page}
              count={companyWallets?.data.totalCount}
              changeCurrentPage={(pageNumber: number) =>
                setSearchQuery((prev) => {
                  return {
                    ...prev,
                    Page: pageNumber,
                  };
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyWallets;
