import { decrypt, encrypt } from "./encryptor";

export const getTokenFromLocalStorage = () => {
  const sso_auth_token = getFromLocalStorage("sso_auth_token");
  if (!sso_auth_token) {
    return false;
  }

  return sso_auth_token;
};

export const getFromLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  if (!value) {
    return false;
  }
  if (typeof value === "boolean") {
    return value;
  } else {
    return decrypt(value);
  }
};

export const postToLocalStorage = (key, value) => {
  if (typeof value === "boolean") {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, encrypt(value));
  }
};

export const getObjectFromLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  if (!value) {
    return false;
  }

  return JSON.parse(decrypt(value));
};

export const postObjectToLocalStorage = (key, value) => {
  localStorage.setItem(key, encrypt(JSON.stringify(value)));
};

export const deleteFromLocalStorage = () => {
  localStorage.clear();
};
