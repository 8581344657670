import React, { useEffect } from "react";
import { base_URL_SSO } from "../../appConstants";
import VerificationLoader from "../Recovery/RecoveryComponents/VerificationLoader.component";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { getFromLocalStorage } from "../../helpers/localStorage";

export default function Enable2FA() {
  useEffect(() => {
    document.getElementById("googleAuthForm").submit();
  }, []);
  const accessToken = getFromLocalStorage("sso_auth_token");
  const actionUrl = `${base_URL_SSO}${ajaxEndpoints.SSOUI}`;
  const advanclyAdminUrl = window.location.origin;

  return (
    <div>
      <div className="animated slideInRight mt-50">
        <div className="datatable-wrapper">
          <VerificationLoader
            text="Redirecting to SSO page. Please wait..."
            iconType="fas fa-compass"
          />
        </div>
      </div>

      <div>
        <form id="googleAuthForm" action={actionUrl} method="post">
          <input
            name="access_token"
            value={accessToken}
            type="hidden"
            className="form-control"
          />
          <input
            name="clientUrl"
            value={advanclyAdminUrl}
            type="hidden"
            className="form-control"
          />
        </form>
      </div>
    </div>
  );
}
